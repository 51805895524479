import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { deletePetQuote, getPetQuotePlans, updatePetQuote } from 'src/store/pet';
import { getAccountProposalSelector, proposalManagement } from 'src/store/proposals';

import { PetPlansTable } from '../petPlansTable/PetPlansTable';
import { Button, Loader, LoaderWrapper, Modal, Ribbon, Status } from 'src/components';
import { MODAL_NAMES } from 'src/constants/modalNames';

import proposalClasses from 'src/sass/styles/Proposal/proposal.module.scss';
import classes from 'src/sass/styles/ConfigurationQuoteCard/configurationQuoteCard.module.scss';

export const PetQuoteCard = (props) => {
  const { quote, onClickEdit } = props;

  const dispatch = useDispatch();

  const { name, status, group_id, id } = quote;

  const { pathname, search } = useLocation();
  const params = useParams();

  const accountProposalData = useSelector(getAccountProposalSelector);
  const accountId = params?.id ? params?.id : accountProposalData?.account_id;
  const proposalId = params?.proposalId ? params?.proposalId : accountProposalData?.id;
  const readOnlyToken = params?.token ? params?.token : '';
  const validToken = `${readOnlyToken}${search}`;

  const isReadOnlyMode = pathname.includes('/read-only');

  const [plansList, setPlansList] = useState([]);

  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isVisiblePlanContent, setIsVisiblePlanContent] = useState(false);
  const [isLoadingPlansList, setIsLoadingPlansList] = useState(false);

  useEffect(() => {
    const fetchPlansList = async () => {
      try {
        setIsLoadingPlansList(true);
        await dispatch(
          getPetQuotePlans({
            accountId,
            proposalId,
            groupId: group_id,
            quoteId: id,
            setPlansList,
            read_token: readOnlyToken,
          }),
        );
      } catch (error) {
        setIsLoadingPlansList(false);
      } finally {
        setIsLoadingPlansList(false);
      }
    };

    fetchPlansList();
  }, [dispatch, accountId, proposalId, group_id, id, readOnlyToken]);

  const onOpenQuote = useCallback(() => {
    setIsVisiblePlanContent((prev) => !prev);
  }, []);

  const handleShowDeleteModal = useCallback(() => {
    setIsDeleteModal((prev) => !prev);
  }, []);

  const onClickDelete = useCallback(() => {
    dispatch(deletePetQuote({ accountId, proposalId, groupId: group_id, quoteId: id }));
  }, [accountId, dispatch, group_id, id, proposalId]);

  const onSubmitQuote = useCallback(() => {
    if (isReadOnlyMode) {
      const payload = {
        coverage_type: 'pet',
        proposal_id: proposalId,
        quote_id: id,
        status: 'approved',
      };

      dispatch(
        proposalManagement({ accountId, proposalId, validToken, token: readOnlyToken, payload }),
      );
    }

    if (!isReadOnlyMode) {
      const payload = {
        coverage_type: 'pet',
        effective_date: quote.effective_date,
        name: quote.name,
        plans: quote.plans.map((item) => item.id),
        quote_type: 'standard',
        status: 'approved',
      };

      dispatch(updatePetQuote({ accountId, proposalId, quoteId: quote.id, payload }));
    }
  }, [dispatch, isReadOnlyMode, proposalId, id, accountId, validToken, readOnlyToken, quote]);

  const onDeselectQuote = useCallback(() => {
    if (isReadOnlyMode) {
      const payload = {
        coverage_type: 'pet',
        proposal_id: proposalId,
        quote_id: id,
        status: 'active',
      };

      dispatch(
        proposalManagement({ accountId, proposalId, validToken, token: readOnlyToken, payload }),
      );
    }

    if (!isReadOnlyMode) {
      const payload = {
        coverage_type: 'pet',
        effective_date: quote.effective_date,
        name: quote.name,
        plans: quote.plans.map((item) => item.id),
        quote_type: 'standard',
        status: 'active',
      };

      dispatch(updatePetQuote({ accountId, proposalId, quoteId: quote.id, payload }));
    }
  }, [dispatch, isReadOnlyMode, proposalId, id, accountId, validToken, readOnlyToken, quote]);

  const renderHeaderItem = useCallback((title, value) => {
    return (
      <div className={classes.ConfigurationQuoteCardHeaderItem}>
        <div className={classes.ConfigurationQuoteCardHeaderItemHeading}>{title}</div>
        <div className={classes.ConfigurationQuoteCardHeaderIndividualTitle}>{value}</div>
      </div>
    );
  }, []);

  const normalizeButtonText = (quote) => {
    const { status } = quote;

    if (status !== 'approved') {
      return 'Select';
    }

    if (status === 'approved') {
      return 'Deselect';
    }
  };

  const isApprovedStatus = status === 'approved';

  return (
    <>
      {isDeleteModal && (
        <Modal type="small" closeButtonType="inside" onClose={handleShowDeleteModal}>
          <div className={classes.ModalContent}>
            <div className={classes.ModalContentTitle}>{MODAL_NAMES.EXCLUDE_QUOTE}</div>
            <div className={classes.ModalContentFooter}>
              <Button type="secondary" title="Cancel" onClick={handleShowDeleteModal} />
              <Button type="danger" title="Yes" onClick={onClickDelete} />
            </div>
          </div>
        </Modal>
      )}

      <div
        className={
          isApprovedStatus ? classes.ConfigurationQuoteCardApproved : classes.ConfigurationQuoteCard
        }
      >
        {isApprovedStatus && <Ribbon title="Sold" />}
        <div className={classes.ConfigurationQuoteCardHeader} onClick={onOpenQuote}>
          <div className={proposalClasses.HeaderIcon}>
            <div className={proposalClasses.HeaderIconWrapper}>
              <i
                className={`fas fa-chevron-down ${
                  isVisiblePlanContent
                    ? proposalClasses.ProposalHeaderIconActive
                    : proposalClasses.ProposalHeaderIcon
                }`}
              />
            </div>
          </div>
          <div className={classes.ConfigurationQuoteCardHeaderWrapper}>
            <div className={classes.ConfigurationQuoteCardHeaderIndividual}>
              {renderHeaderItem('Quote Name', name)}
            </div>
            {renderHeaderItem(
              'Eligible Pets',
              plansList?.map((item) => item.eligible_pets).join(','),
            )}
            {renderHeaderItem(
              'Waiting Period',
              plansList?.map((item) => item.coverage_waiting_period).join(','),
            )}
          </div>
          <div className={classes.ConfigurationQuoteCardHeaderButtons}>
            {!isReadOnlyMode && (
              <>
                {!isApprovedStatus && (
                  <div className={classes.ConfigurationQuoteCardHeaderItemStatus}>
                    <Status status={status} />
                  </div>
                )}
              </>
            )}
            {!isReadOnlyMode && !isApprovedStatus && (
              <>
                <div
                  className={classes.ConfigurationQuoteCardDeleteButton}
                  onClick={handleShowDeleteModal}
                >
                  <i className="fa fa-trash-o" />
                </div>
              </>
            )}
          </div>
        </div>

        {isVisiblePlanContent &&
          (isLoadingPlansList ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : (
            <>
              <PetPlansTable selectedPlans={plansList} mode="plan" />
              <div className={classes.ConfigurationQuoteCardFooter}>
                <div className={classes.ConfigurationQuoteCardFooterReadOnly}>
                  <Button
                    type="primary"
                    title={normalizeButtonText(quote)}
                    onClick={quote.status !== 'approved' ? onSubmitQuote : onDeselectQuote}
                  />

                  {!isReadOnlyMode && (
                    <Button title="Edit" type="secondary" onClick={onClickEdit(quote, plansList)} />
                  )}
                </div>
                {quote?.status !== 'approved' && !isReadOnlyMode && (
                  <Button type="danger" title="Delete Quote" onClick={handleShowDeleteModal} />
                )}
              </div>
            </>
          ))}
      </div>
    </>
  );
};
