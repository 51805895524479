import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Chip, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import _ from 'lodash';

import { getUserClaimsSelector } from 'src/store/app';
import { getBrokerData } from 'src/store/broker';
import {
  getAccountProposalSelector,
  getIsSendingMessage,
  getReadOnly,
  getReadOnlyUser,
} from 'src/store/proposals';
import {
  chatRoomMessagesSelector,
  chatRoomsSelector,
  getChatRoomMessages,
  getChatRooms,
  isLoadingStartChatSelector,
  sendChatRoomMessage,
} from 'src/store/chat';
import { getIssuerDataSelector } from 'src/store/issuer';
import { getAllAgencyContacts } from 'src/store/contacts';
import { getSharedContacts } from 'src/store/planConfiguration';

import { Rooms } from './Rooms/Rooms';
import { MemberModal } from './MemberModal/MemberModal';
import { Loader, LoaderWrapper } from 'src/components';

import classes from './proposalChat.module.scss';

export const ProposalChat = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const { pathname, search } = useLocation();
  const isReadOnlyMode = pathname.includes('/read-only');

  const brokerData = useSelector(getBrokerData);
  const issuerData = useSelector(getIssuerDataSelector);
  const proposalData = useSelector(getAccountProposalSelector);
  const readOnlyUser = useSelector(getReadOnlyUser);
  const chatMessages = useSelector(chatRoomMessagesSelector);
  const userClaims = useSelector(getUserClaimsSelector);
  const isSendingMessage = useSelector(getIsSendingMessage);
  const isLoadingStartChat = useSelector(isLoadingStartChatSelector);

  const chatRooms = useSelector(chatRoomsSelector);

  const [message, setChatMessage] = useState('');

  const [user, setUser] = useState({});
  const [selectedRoom, setSelectedRoom] = useState({});
  const [isLoadingRoom, setIsLoadingRoom] = useState(false);

  const messageRef = useRef(null);
  const listMessageRef = useRef(null);

  const accountId = params?.id ? params?.id : proposalData?.account_id;
  const proposalId = params?.proposalId ? params?.proposalId : proposalData?.id;
  const teamId = params?.teamId;
  const senderId = isReadOnlyMode ? readOnlyUser?.id : user?.id;

  const token = params?.token;

  const validToken = `${token}${search}`;

  useEffect(() => {
    if (teamId && accountId && proposalId) {
      dispatch(getAllAgencyContacts({ 'f[org_id]': teamId }));
      dispatch(getSharedContacts({ accountId, proposalId }));
    }
  }, [dispatch, teamId, accountId, proposalId]);

  useEffect(() => {
    if (isReadOnlyMode) {
      dispatch(getReadOnly({ accountId, proposalId, token, validToken, navigate }));

      const payload = {
        'f[proposal_id]': proposalId,
        'f[room_type]': 'employer',
      };

      if (accountId && proposalId) {
        dispatch(getChatRooms(payload));
      }
    }
  }, [dispatch, navigate, accountId, proposalId, token, validToken, isReadOnlyMode]);

  useEffect(() => {
    if (!_.isEmpty(brokerData) && !_.isEmpty(userClaims)) {
      setUser({ ...brokerData, user_type: userClaims.user_type });
    }

    if (!_.isEmpty(issuerData) && !_.isEmpty(userClaims)) {
      setUser({ ...issuerData, user_type: userClaims.user_type });
    }
  }, [brokerData, issuerData, userClaims]);

  useEffect(() => {
    let isMounted = true;

    if (accountId && proposalId && !_.isEmpty(selectedRoom)) {
      const fetchChatRooms = async () => {
        if (!isMounted) return;

        setIsLoadingRoom(true);
        try {
          await dispatch(getChatRoomMessages({ room_id: selectedRoom?.id }));
        } catch (error) {
          if (isMounted) setIsLoadingRoom(false);
        } finally {
          if (isMounted) setIsLoadingRoom(false);
        }
      };

      fetchChatRooms();
    }

    return () => {
      isMounted = false;
    };
  }, [dispatch, accountId, proposalId, selectedRoom]);

  useEffect(() => {
    if (isReadOnlyMode && chatRooms?.length) {
      const employerRoom = chatRooms.find((item) => item.room_type === 'employer');

      if (employerRoom && _.isEmpty(selectedRoom)) {
        setSelectedRoom(employerRoom);
      }
    }
  }, [isReadOnlyMode, chatRooms, selectedRoom]);

  useEffect(() => {
    const period = setInterval(() => {
      if (accountId && proposalId && !_.isEmpty(selectedRoom)) {
        dispatch(getChatRoomMessages({ room_id: selectedRoom.id }));
      }
    }, 10000);

    return () => {
      clearInterval(period);
    };
  }, [dispatch, accountId, proposalId, selectedRoom]);

  const inputEventListener = useCallback(() => {
    const textArea = document.getElementById('textArea');

    textArea.style.height = '40px';
    textArea.style.height = `${textArea.scrollHeight}px`;
  }, []);

  const onChangeChatMessage = useCallback(
    (event) => {
      const textArea = document.getElementById('textArea');
      textArea.setAttribute('style', `height: ${textArea.scrollHeight}px`);
      textArea.addEventListener('input', inputEventListener, false);

      setChatMessage(event.target.value);
    },
    [inputEventListener],
  );

  const onSendMessage = useCallback(async () => {
    if (!message) {
      toast('Message cannot be empty', { type: 'warning' });
      return;
    }

    const userId = isReadOnlyMode ? readOnlyUser?.id : user?.id;

    const sender = selectedRoom?.members?.find((item) => item.user_id === userId);

    const payload = {
      user_id: isReadOnlyMode ? readOnlyUser?.id : user?.id,
      user_type: sender?.user_type,
      proposal_id: selectedRoom.proposal_id,
      message,
    };

    await dispatch(sendChatRoomMessage({ room_id: selectedRoom.id, payload }));

    const textArea = document.getElementById('textArea');

    textArea.style.height = '40px';

    if (listMessageRef.current) {
      listMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }

    setChatMessage('');
  }, [dispatch, isReadOnlyMode, message, readOnlyUser, selectedRoom, user]);

  const onEnterSend = useCallback(
    (event) => {
      if (event.keyCode === 13) {
        onSendMessage();
      }
    },
    [onSendMessage],
  );

  const [selectedUser, setSelectedUser] = useState({});
  const [isUserDetailsModal, setIsUserDetailsModal] = useState(false);

  const handleClickOpen = useCallback(
    (user) => () => {
      setSelectedUser(user);
      setIsUserDetailsModal(true);
    },
    [],
  );
  const handleClose = useCallback(() => {
    setSelectedUser();
    setIsUserDetailsModal(false);
  }, []);

  return (
    <div className={classes.ProposalChat}>
      <MemberModal
        handleClose={handleClose}
        selectedUser={selectedUser}
        isUserDetailsModal={isUserDetailsModal}
      />

      {!isReadOnlyMode && <Rooms selectedRoom={selectedRoom} setSelectedRoom={setSelectedRoom} />}

      <div className={classes.ProposalChatContent}>
        {isLoadingStartChat && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}

        {!isLoadingStartChat && (
          <>
            {!_.isEmpty(selectedRoom) ? (
              <>
                {isLoadingRoom ? (
                  <LoaderWrapper>
                    <Loader />
                  </LoaderWrapper>
                ) : (
                  <>
                    <div className={classes.ProposalChatInfo}>
                      <div className={classes.ProposalChatInfoContact}>
                        {selectedRoom?.members?.map((item) => (
                          <Tooltip title={`${item.email}(${item.user_type})`}>
                            <Chip
                              label={item.name}
                              color="primary"
                              size="small"
                              sx={{ marginRight: 1 }}
                            />
                          </Tooltip>
                        ))}
                      </div>
                      <Button disabled>Details</Button>
                    </div>
                    <div className={classes.ProposalChatSelected}>
                      <ol className={classes.list} ref={listMessageRef}>
                        {chatMessages?.length ? (
                          chatMessages.map((item, i) => {
                            const member = selectedRoom?.members.find(
                              (member) => member.user_id === item.user_id,
                            );

                            let firstName = '';
                            let lastName = '';

                            if (member) {
                              const [fn, ln] = member?.name?.split(' ');
                              firstName = fn.slice(0, 1);
                              lastName = ln.slice(0, 1);
                            }

                            return (
                              <li
                                key={item.id}
                                className={classNames(
                                  classes.shared,
                                  item.user_id === senderId ? classes.sent : classes.received,
                                )}
                              >
                                {item.user_id !== senderId && (
                                  <div
                                    className={classes.info}
                                    style={{ left: -45, zIndex: 1 }}
                                    onClick={handleClickOpen(member)}
                                  >
                                    {`${firstName}${lastName}`}
                                  </div>
                                )}
                                {item.message}
                              </li>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </ol>
                    </div>
                    <div className={classes.ProposalChatFooter}>
                      <textarea
                        id="textArea"
                        className={classes.ProposalChatFooterInput}
                        placeholder="Type your message..."
                        value={message}
                        onChange={onChangeChatMessage}
                        onKeyDown={onEnterSend}
                        disabled={isSendingMessage}
                        ref={messageRef}
                      />
                      <button
                        className={classes.ProposalChatFooterButton}
                        onClick={onSendMessage}
                        disabled={isSendingMessage}
                      >
                        <i className="fas fa-paper-plane" />
                      </button>
                    </div>
                  </>
                )}
              </>
            ) : (
              <div className={classes.EmptyField}>
                <div className={classes.EmptyFieldTitle}>Select a chat to start messaging</div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
