import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { auth } from 'src/app/database';
import api from 'src/app/apiSingleton';
import _ from 'lodash';

import {
  clearAppState,
  setIsVerifiedEmail,
  setLoginError,
  setPasswordResetError,
  setUserClaims,
} from './app.slice';

import { acceptOrganizationInvite } from '../agency';
import { clearAccidentState } from '../accidentCnfiguration';
import { clearHospitalState } from '../hospitalConfiguration';
import { clearCriticalState } from '../criticalConfiguration';
import { clearMedicalState } from '../medicalConfiguration';
import { clearDentalState } from '../dentalConfiguration';
import { clearVisionState } from '../visionConfiguration';
import { clearLifeState } from '../lifeConfiguration';
import { clearDisabilityState } from '../disabilityConfiguration';
import { clearBrokerState } from '../broker';
import { clearBrokerLicensesState } from '../brokerLicenses';
import { clearBrowseBrokersState } from '../browseBrokers';
import { clearContactsState } from '../contacts';
import { clearEmployeeState } from '../employee';
import { clearGroupMembersState } from '../groupMembers';
import { clearIssuerState } from '../issuer';
import { clearPlanConfigurationState } from '../planConfiguration';
import { clearProposalState } from '../proposals';
import { clearAccountContactsState } from '../accountContacts';
import { clearAdminState } from '../admin';

import { ROUTE } from 'src/constants/routes';
import { ERROR } from 'src/constants/errorNames';

export const signInWithEmailAndPassword = createAsyncThunk('app/login', async (data, thunkApi) => {
  try {
    const { email, password, navigation, continueUrl } = data;

    await auth.signInWithEmailAndPassword(email, password);

    const tokenResult = await auth.currentUser.getIdTokenResult();

    const isEmailVerified = tokenResult?.claims?.email_verified;
    const userType = tokenResult?.claims?.user_type;

    const token = await auth.currentUser.getIdToken();

    api.apiClient.setToken(token);

    thunkApi.dispatch(setLoginError(''));

    if (!isEmailVerified) {
      const domain = new URL(window.location).origin;

      const payload = {
        email,
        continue_url: `${domain}${continueUrl}`,
      };

      await api.app.sendEmailVereficationLink(payload);
    }

    if (userType === 'broker') {
      return navigation(continueUrl);
    }
  } catch (err) {
    thunkApi.dispatch(setLoginError(err?.code));
    return err;
  }
});

export const registerWithEmailAndPassword = createAsyncThunk(
  'app/register',
  async (data, thunkApi) => {
    try {
      const {
        email,
        password,
        displayName,
        continueUrl,
        address_street,
        address_city,
        address_state,
        phone_number,
        address_zip_code,
        issuerId,
        isIssuerSignup,
        navigation,
        carrierName,
        isNewIssuer,
        invite_id,
      } = data;

      const domain = new URL(window.location).origin;

      const brokerUser = {
        email: email,
        display_name: displayName,
        continue_url: `${domain}${continueUrl}`,
        password,
        address_street,
        address_city,
        address_state,
        address_zip_code,
        phone_number,
      };

      const repUser = {
        email: email,
        display_name: displayName,
        issuer_id: issuerId,
        min_group_size: 1,
        max_group_size: 100,
        continue_url: `${domain}${continueUrl ? continueUrl : '/rep/my-rfps'}`,
        password,
        phone_number,
      };

      if (!isIssuerSignup) {
        await api.app.createBrokerAccount(brokerUser);

        await thunkApi.dispatch(
          signInWithEmailAndPassword({ email: brokerUser.email, password: brokerUser.password }),
        );

        if (invite_id) {
          await thunkApi.dispatch(acceptOrganizationInvite({ invite_id }));
        }

        toast(`Email verification link sent to your email ${email}`, { type: 'success' });
      }

      if (isIssuerSignup) {
        let issuerResponse = {};

        if (isNewIssuer) {
          const emailDomain = email?.split('@')[1];
          const issuerPayload = {
            name: carrierName,
            email_domains: [emailDomain],
          };

          issuerResponse = await api.issuer.createIssuerAccount(issuerPayload);
        }

        const updatedPayload = {
          ...repUser,
          issuer_name: carrierName,
          issuer_id: issuerResponse?.id ? issuerResponse?.id : repUser?.issuer_id,
        };

        await api.issuer.createRepAccount(updatedPayload);

        toast(`Email verification link sent to your email ${email}`, { type: 'success' });
        navigation(ROUTE.LOGIN);
      }
    } catch (err) {
      toast(err?.error, { type: 'error' });
    }
  },
);

export const getCityByZipCode = createAsyncThunk('app/get-city-by-zip-code', async (data) => {
  try {
    const response = await api.app.getCityByZipCode(data);

    return response;
  } catch (err) {
    console.error(err);

    return {};
  }
});

export const sendPasswordResetEmail = createAsyncThunk(
  'app/reset-password',
  async (data, thunkApi) => {
    try {
      const { email, replacedUrl, isIssuer, navigation } = data;

      const domain = new URL(window.location).origin;

      if (!isIssuer) {
        const payload = {
          email,
          continue_url: `${domain}${replacedUrl}`,
        };

        await api.app.sendPasswordResetLink(payload);

        if (navigation) {
          navigation(ROUTE.LOGIN);
        }
      }

      if (isIssuer) {
        const payload = {
          email,
          continue_url: `${domain}${replacedUrl}`,
        };

        await api.app.sendPasswordResetLink(payload);

        if (navigation) {
          navigation(ROUTE.LOGIN);
        }
      }

      toast('Password reset link sent to your email!', { type: 'success' });
    } catch (err) {
      console.error(err?.code);
      toast(ERROR.SYSTEM_ERROR, { type: 'error' });
      thunkApi.dispatch(setPasswordResetError(err?.code));
    }
  },
);

export const validateOobCode = createAsyncThunk('app/validate-oob-code', async (data, thunkApi) => {
  try {
    const { oobCode } = data;

    await auth.verifyPasswordResetCode(oobCode);
  } catch (err) {
    const { navigate } = data;
    console.error(err?.code);
    navigate(ROUTE.DEFAULT);
    toast(ERROR.INACCESSABLE, { type: 'error' });
  }
});

export const confirmPasswordReset = createAsyncThunk(
  'app/confirm-password-reset',
  async (data, thunkApi) => {
    try {
      const { oobCode, password, navigate, replacedUrl } = data;

      await auth.confirmPasswordReset(oobCode, password);

      navigate(replacedUrl ? replacedUrl : ROUTE.LOGIN);

      toast('Your password was successfully changed', { type: 'success' });
    } catch (err) {
      console.error(err?.code);
      toast(ERROR.SYSTEM_ERROR, { type: 'error' });
    }
  },
);
export const reSendEmailVerification = createAsyncThunk(
  'app/re-send-email-verification',
  async (data) => {
    try {
      const { email, continueUrl } = data;

      const domain = new URL(window.location).origin;

      const payload = {
        email,
        continue_url: `${domain}${continueUrl}`,
      };

      await api.app.sendEmailVereficationLink(payload);

      toast('Email verification link sent again to your email', { type: 'success' });
    } catch (err) {
      toast(ERROR.SYSTEM_ERROR, { type: 'error' });
    }
  },
);

export const verifyEmailAddress = createAsyncThunk(
  'app/verify-email-address',
  async (data, thunkApi) => {
    try {
      const { oobCode } = data;

      await auth.applyActionCode(oobCode);

      if (auth.currentUser) {
        auth.currentUser.reload();
      }

      toast('Your email has successfully verified', { type: 'success' });

      thunkApi.dispatch(setIsVerifiedEmail(true));
    } catch (err) {
      const { navigate } = data;
      console.error(err);
      thunkApi.dispatch(setIsVerifiedEmail(false));
      navigate(ROUTE.EMAIL_VERIFICATION);
      toast(ERROR.SYSTEM_ERROR, { type: 'error' });
    }
  },
);

export const getEmailDomains = createAsyncThunk('app/get-email-domains', async (data, thunkApi) => {
  try {
    const response = await api.app.getEmailDomains(data);

    let emailDomains = [];

    if (data) {
      for (const domain of response?.email_domains) {
        const payload = {
          key: `@${domain}`,
          name: `@${domain}`,
        };

        emailDomains.push(payload);
      }
    }

    if (!data) {
      for (const issuer of response) {
        issuer?.email_domains?.map((item) => {
          const payload = {
            key: `@${item}`,
            name: `@${item}`,
          };

          return emailDomains.push(payload);
        });
      }
    }

    if (!data) {
      return _.orderBy(
        emailDomains.filter(
          (value, index, self) => index === self.findIndex((item) => item.key === value.key),
        ),
        ['name'],
        ['asc'],
      );
    }

    return emailDomains;
  } catch (err) {
    console.error(err);
  }
});

export const getIssuerEmailDomain = createAsyncThunk(
  'app/get-issuer-email-domain',
  async (data) => {
    try {
      const { email_domain } = data;

      const payload = {
        email_domain,
      };

      const response = await api.app.getIssuerDomain(payload);

      return response[0];
    } catch (err) {
      console.error(err);
    }
  },
);

export const getUserType = createAsyncThunk('app/get-user-type', async (data, thunkApi) => {
  try {
    const user = auth.currentUser;
    const tokenResult = await user.getIdTokenResult();

    const userType = tokenResult?.claims?.user_type;

    thunkApi.dispatch(setUserClaims(tokenResult.claims));
    if (userType) {
      return userType;
    }
    if (!userType) {
      return 'broker';
    }
  } catch (err) {
    console.error(err);
  }
});

export const logout = createAsyncThunk('app/logout', (data, thunkApi) => {
  try {
    const { navigate, route } = data;

    localStorage.clear();
    auth.signOut();

    thunkApi.dispatch(clearAppState());
    thunkApi.dispatch(clearBrokerState());
    thunkApi.dispatch(clearBrokerLicensesState());
    thunkApi.dispatch(clearBrowseBrokersState());
    thunkApi.dispatch(clearAccountContactsState());
    thunkApi.dispatch(clearContactsState());
    thunkApi.dispatch(clearEmployeeState());
    thunkApi.dispatch(clearGroupMembersState());
    thunkApi.dispatch(clearIssuerState());
    thunkApi.dispatch(clearPlanConfigurationState());
    thunkApi.dispatch(clearProposalState());
    thunkApi.dispatch(clearAdminState());

    thunkApi.dispatch(clearMedicalState());
    thunkApi.dispatch(clearDentalState());
    thunkApi.dispatch(clearVisionState());
    thunkApi.dispatch(clearLifeState());
    thunkApi.dispatch(clearDisabilityState());
    thunkApi.dispatch(clearAccidentState());
    thunkApi.dispatch(clearHospitalState());
    thunkApi.dispatch(clearCriticalState());

    navigate(route ? route : ROUTE.DEFAULT);
  } catch (err) {
    console.error(err);
  }
});
