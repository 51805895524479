import moment from 'moment';
import { normalizeRateValue } from './lifeRates';

const computeHousehold3TierType = (roles, selectedOffer) => {
  if (roles.length === 1 && roles[0] === 'employee') {
    return { rate: selectedOffer?.rate_ee, type: 'EE' };
  }

  let spCount = 0;
  let chCount = 0;

  for (const role of roles) {
    if (role === 'spouse' || role === 'life_partner') {
      spCount++;
    } else if (role === 'child') {
      chCount++;
    }
  }

  if (spCount > 0 && chCount > 0) {
    return { rate: selectedOffer?.rate_fam, type: 'FAM' };
  } else if (spCount === 1 || chCount === 1) {
    return { rate: selectedOffer?.rate_ee_1, type: 'EE + 1' };
  } else {
    return { rate: selectedOffer?.rate_fam, type: 'FAM' };
  }
};

const computeHousehold4TierType = (roles, selectedOffer) => {
  if (roles.length === 1 && roles[0] === 'employee') {
    return { rate: selectedOffer?.rate_ee, type: 'EE' };
  }

  let spCount = 0;
  let chCount = 0;

  for (const role of roles) {
    if (role === 'spouse' || role === 'life_partner') {
      spCount++;
    } else if (role === 'child') {
      chCount++;
    }
  }

  if (spCount > 0 && chCount > 0) {
    return { rate: selectedOffer?.rate_fam, type: 'FAM' };
  } else if (spCount > 0) {
    return { rate: selectedOffer?.rate_ee_sp, type: 'EE + SP' };
  } else if (chCount > 0) {
    return { rate: selectedOffer?.rate_ee_ch, type: 'EE + CH' };
  } else {
    return { rate: selectedOffer?.rate_fam, type: 'FAM' };
  }
};

export const calculateConfigurationRates = (accountMembers, selectedOffer, householdRoles) => {
  // collect household roles
  for (const member of accountMembers) {
    if (householdRoles[member.family_id]) {
      householdRoles[member.family_id].push(member.relationship);
    } else {
      householdRoles[member.family_id] = [member.relationship];
    }
  }

  const household3TierTypes = {};
  const household4TierTypes = {};

  // compute household rate tier type
  for (const eid in householdRoles) {
    const roles = householdRoles[eid];
    household4TierTypes[eid] = computeHousehold4TierType(roles, selectedOffer);
    household3TierTypes[eid] = computeHousehold3TierType(roles, selectedOffer);
  }

  if (selectedOffer?.rate_tier_type === '3tier') {
    household3TierTypes.total = 0;
    for (const eid in householdRoles) {
      household3TierTypes.total += household3TierTypes[eid]?.rate;
    }

    return household3TierTypes;
  }

  if (selectedOffer?.rate_tier_type === '4tier') {
    household4TierTypes.total = 0;
    for (const eid in householdRoles) {
      household4TierTypes.total += household4TierTypes[eid]?.rate;
    }

    return household4TierTypes;
  }
};

export const calculateConfigurationAgeRates = (accountMembers, selectedOffer) => {
  let membersCost = {};
  let total = 0;

  for (const member of accountMembers) {
    let memberAge = moment().diff(moment(member.date_of_birth), 'years');

    if (memberAge > 65) memberAge = 65;
    const memberRate = selectedOffer[`age_${memberAge}`];

    if (selectedOffer?.selected_plan_name) {
      if (selectedOffer?.selected_plan_name === member?.current_medical_plan_name) {
        membersCost = {
          ...membersCost,
          [member.id]: memberRate,
        };
        total += memberRate;
      }
    }

    if (!selectedOffer?.selected_plan_name) {
      membersCost = {
        ...membersCost,
        [member.id]: memberRate,
      };
      total += memberRate;
    }
  }

  membersCost = {
    ...membersCost,
    total,
  };

  return membersCost;
};

const calculateHouseholdAgeRateFamilies = (householdFamilies, selectedOffer) => {
  let payload = {};

  if (selectedOffer?.rate_tier_type !== '4tier') {
    const getAges = (member) => {
      const originalAge = moment().diff(moment(member.date_of_birth), 'years');
      const cappedAge = originalAge > 65 ? 65 : originalAge;
      return { originalAge, cappedAge };
    };

    const computeUniTobaccoRate = (member, cappedAge) => {
      let rateValue = 0;
      if (!selectedOffer?.death_benefit?.includes('salary')) {
        if (selectedOffer?.contribution_type === 'employer') {
          if (member?.relationship === 'employee') {
            rateValue =
              (selectedOffer.death_benefit / selectedOffer.rate_per_unit) *
              (selectedOffer[`age_${cappedAge}_uni_tobacco`] || 0);
          }
        } else if (selectedOffer?.contribution_type === 'voluntary') {
          rateValue =
            (member?.life_benefit_amount / selectedOffer.rate_per_unit) *
            (selectedOffer[`age_${cappedAge}_uni_tobacco`] || 0);
        }
      } else {
        if (selectedOffer.death_benefit === '1xsalary') {
          rateValue =
            (member?.salary_amount / selectedOffer.rate_per_unit) *
            (selectedOffer[`age_${cappedAge}_uni_tobacco`] || 0);
        } else if (selectedOffer.death_benefit === '2xsalary') {
          rateValue =
            ((member?.salary_amount * 2) / selectedOffer.rate_per_unit) *
            (selectedOffer[`age_${cappedAge}_uni_tobacco`] || 0);
        }
      }
      return normalizeRateValue(rateValue);
    };

    const computeNonUniTobaccoRate = (member, cappedAge) => {
      let tobaccoValue = 0;
      let nonTobaccoValue = 0;
      if (!selectedOffer?.death_benefit?.includes('salary')) {
        if (selectedOffer?.contribution_type === 'employer') {
          if (member?.relationship === 'employee') {
            tobaccoValue =
              (selectedOffer.death_benefit / selectedOffer.rate_per_unit) *
              (selectedOffer[`age_${cappedAge}_tobacco`] || 0);
            nonTobaccoValue =
              (selectedOffer.death_benefit / selectedOffer.rate_per_unit) *
              (selectedOffer[`age_${cappedAge}_non_tobacco`] || 0);
          }
        } else if (selectedOffer?.contribution_type === 'voluntary') {
          tobaccoValue =
            (member?.life_benefit_amount / selectedOffer.rate_per_unit) *
            (selectedOffer[`age_${cappedAge}_tobacco`] || 0);
          nonTobaccoValue =
            (member?.life_benefit_amount / selectedOffer.rate_per_unit) *
            (selectedOffer[`age_${cappedAge}_non_tobacco`] || 0);
        }
      } else {
        if (selectedOffer.death_benefit === '1xsalary') {
          tobaccoValue =
            (member?.salary_amount / selectedOffer.rate_per_unit) *
            (selectedOffer[`age_${cappedAge}_tobacco`] || 0);
          nonTobaccoValue =
            (member?.salary_amount / selectedOffer.rate_per_unit) *
            (selectedOffer[`age_${cappedAge}_non_tobacco`] || 0);
        } else if (selectedOffer.death_benefit === '2xsalary') {
          tobaccoValue =
            ((member?.salary_amount * 2) / selectedOffer.rate_per_unit) *
            (selectedOffer[`age_${cappedAge}_tobacco`] || 0);
          nonTobaccoValue =
            ((member?.salary_amount * 2) / selectedOffer.rate_per_unit) *
            (selectedOffer[`age_${cappedAge}_non_tobacco`] || 0);
        }
      }
      return {
        tobacco: normalizeRateValue(tobaccoValue),
        nonTobacco: normalizeRateValue(nonTobaccoValue),
      };
    };

    if (selectedOffer?.is_uni_tobacco) {
      for (const family_id in householdFamilies) {
        const household = householdFamilies[family_id];
        let householdPayload = [];
        for (const member of household) {
          const { originalAge, cappedAge } = getAges(member);
          const memberPayload = { ...member, type: `${originalAge} Y/O` };
          memberPayload.tobacco = computeUniTobaccoRate(member, cappedAge);
          householdPayload.push(memberPayload);
        }
        payload[family_id] = householdPayload;
      }
    }

    if (!selectedOffer?.is_uni_tobacco) {
      for (const family_id in householdFamilies) {
        const household = householdFamilies[family_id];
        // Determine if any member in the household is a tobacco user.
        const isTobaccoUserPresent = household?.find((item) => item?.is_tobacco_user);
        let householdPayload = [];
        for (const member of household) {
          const { originalAge, cappedAge } = getAges(member);
          let memberPayload = { ...member, type: `${originalAge} Y/O` };
          const { tobacco, nonTobacco } = computeNonUniTobaccoRate(member, cappedAge);
          if (!selectedOffer?.death_benefit?.includes('salary')) {
            if (selectedOffer?.contribution_type === 'employer') {
              if (member?.relationship === 'employee') {
                if (isTobaccoUserPresent) {
                  memberPayload.tobacco = tobacco;
                  memberPayload.tobaccoType = 'Tobacco';
                } else {
                  memberPayload.nonTobacco = nonTobacco;
                  memberPayload.tobaccoType = 'Non-Tobacco';
                }
              }
            } else if (selectedOffer?.contribution_type === 'voluntary') {
              if (isTobaccoUserPresent) {
                memberPayload.tobacco = tobacco;
                memberPayload.tobaccoType = 'Tobacco';
              } else {
                memberPayload.nonTobacco = nonTobacco;
                memberPayload.tobaccoType = 'Non-Tobacco';
              }
            }
          } else {
            if (
              selectedOffer.death_benefit === '1xsalary' ||
              selectedOffer.death_benefit === '2xsalary'
            ) {
              if (isTobaccoUserPresent) {
                memberPayload.tobacco = tobacco;
                memberPayload.tobaccoType = 'Tobacco';
              } else {
                memberPayload.nonTobacco = nonTobacco;
                memberPayload.tobaccoType = 'Non-Tobacco';
              }
            }
          }
          householdPayload.push(memberPayload);
        }
        payload[family_id] = householdPayload;
      }
    }
  }
  return payload;
};

export const calculateLifeConfigurationAgeRates = (accountMembers, selectedOffer) => {
  let household4TierTypes = {};

  // compute household rate tier type
  const householdFamilies = accountMembers.reduce((previousValue, currentValue) => {
    if (typeof previousValue[currentValue.family_id] === 'undefined') {
      previousValue[currentValue.family_id] = [];
    }

    previousValue[currentValue.family_id].push(currentValue);

    return previousValue;
  }, {});

  household4TierTypes = calculateHouseholdAgeRateFamilies(householdFamilies, selectedOffer);

  household4TierTypes.totalTobacco = 0;
  for (const family_id in household4TierTypes) {
    if (family_id !== 'totalTobacco') {
      for (const member of household4TierTypes[family_id]) {
        household4TierTypes.totalTobacco += member?.tobacco || 0;
        household4TierTypes.totalTobacco += member?.nonTobacco || 0;
      }
    }
  }

  household4TierTypes.totalTobacco = normalizeRateValue(household4TierTypes.totalTobacco);

  return household4TierTypes;
};
