import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Loader } from '../Loader/Loader';

import classes from './button.module.scss';

export const Button = (props) => {
  const {
    type,
    title,
    icon,
    iconColor,
    onClick,
    isLoading,
    isDisabled,
    onFocus,
    buttonStyle = {},
    buttonType = 'button',
    size = 's',
  } = props;

  const buttonStyles = classnames(classes.Button, {
    [classes.ButtonPrimary]: type === 'primary',
    [classes.ButtonPrimaryPurple]: type === 'primaryPurple',
    [classes.ButtonPrimaryBlack]: type === 'primaryBlack',
    [classes.ButtonSecondary]: type === 'secondary',
    [classes.ButtonDanger]: type === 'danger',
    [classes.ButtonInverse]: type === 'inverse',

    [classes.ButtonSmall]: size === 's',
    [classes.ButtonMedium]: size === 'm',
    [classes.ButtonLarge]: size === 'l',
  });

  return (
    <button
      className={buttonStyles}
      onClick={isDisabled ? () => {} : onClick}
      onFocus={onFocus}
      style={{
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        opacity: isDisabled ? 0.8 : 1,
        ...buttonStyle,
      }}
      type={buttonType}
    >
      {isLoading ? (
        <div className={classes.ButtonLoader}>
          <Loader size="small" color={`${type === 'secondary' ? '#209cee' : 'white'}`} />
        </div>
      ) : (
        <>
          {icon && <i className={icon} color={iconColor} />}
          {title && title}
        </>
      )}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.any,
  onFocus: PropTypes.func,
  buttonType: PropTypes.string,
  size: PropTypes.oneOf(['s', 'm', 'l']),
};
