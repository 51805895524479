import Base from './base';

class BrokerLicenses extends Base {
  createBrokerLicense = (brokerId, payload) => {
    return this.apiClient.post(`brokers/${brokerId}/licenses`, payload);
  };

  getBrokerLicenses = (brokerId) => {
    return this.apiClient.get(`brokers/${brokerId}/licenses`);
  };

  updateBrokerLicense = (brokerId, payload) => {
    return this.apiClient.put(`brokers/${brokerId}/licenses/${payload.id}`, payload);
  };

  deleteBrokerLicense = (brokerId, licenseId) => {
    return this.apiClient.delete(`brokers/${brokerId}/licenses/${licenseId}`);
  };
}

export default BrokerLicenses;
