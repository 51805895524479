import { calculateExecutiveSummary } from 'src/constants/calculation/calculateExecutiveSummary';

import { currencyFormatter } from 'src/constants/currency';

import { capitalize } from '../other';

export const normalizeExecutiveExport = (
  ExcelJSWorkbook,
  executiveSummary,
  groupMembers,
  summaryQuoteNames,
  name,
  status,
) => {
  const executiveSummaryWorksheet = ExcelJSWorkbook.addWorksheet(name, {
    properties: { defaultColWidth: 40 },
  });

  let approvedExecutiveSummary = {};

  for (const item in executiveSummary) {
    if (item) {
      approvedExecutiveSummary = {
        ...approvedExecutiveSummary,
        [item]: executiveSummary[item]?.filter((quote) => quote.status !== status),
      };
    }
  }

  const calculatedExecutiveSummary = calculateExecutiveSummary(
    approvedExecutiveSummary,
    groupMembers,
  );

  const executiveTable = [
    ['Line of Coverage', 'Quote Name', 'EE Cost Total', 'ER Cost Total', 'Total Cost'],
  ];

  for (const coverage in calculatedExecutiveSummary) {
    if (coverage) {
      executiveTable.push([
        capitalize(coverage)?.replace('_', ' '),
        '',
        currencyFormatter(calculatedExecutiveSummary[coverage]?.totalEmployee),
        currencyFormatter(calculatedExecutiveSummary[coverage]?.totalEmployer),
        currencyFormatter(calculatedExecutiveSummary[coverage]?.totalRate),
      ]);

      if (calculatedExecutiveSummary[coverage]?.quotes?.length) {
        for (const quote of calculatedExecutiveSummary[coverage]?.quotes) {
          executiveTable.push([
            '',
            `${summaryQuoteNames[quote?.id]} ${quote?.name ? `(${quote?.name})` : ''}`,
            currencyFormatter(quote?.ee_rate),
            currencyFormatter(quote?.er_rate),
            currencyFormatter(quote?.total_rate),
          ]);
        }
      }

      executiveTable.push([]);
    }
  }

  const rowNames = [
    'Medical',
    'Dental',
    'Vision',
    'Life',
    'Disability',
    'Accident',
    'Hospital',
    'Critical illness',
    'Total',
  ];

  for (const row of executiveTable) {
    if (rowNames.includes(row[0])) {
      const totalRowStyle = executiveSummaryWorksheet.addRow(row);
      totalRowStyle.eachCell(
        (cell) =>
          (cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'ebecf0',
            },
          }),
      );
      totalRowStyle.font = { bold: true };
    } else if (row[0] === 'Line of Coverage') {
      executiveSummaryWorksheet.addRow(row).font = { bold: true };
    } else {
      executiveSummaryWorksheet.addRow(row);
    }
  }
};
