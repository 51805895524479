import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getUserTypeSelector, setIsSubscription } from 'src/store/app';

import { ROUTE } from 'src/constants/routes';

import { Button } from '../Button/Button';

import CheckedPurpleCircle from 'src/assets/app/checkedPurpleCircle.svg';
import classes from './brokerPricing.module.scss';

const tableData = [
  { title: 'Unlimited Quoting and RFPs', isIndividual: true, isTeams: true },
  { title: 'Send RFPs to Any Carrier', isIndividual: true, isTeams: true },
  { title: 'Use your own Carrier Contacts', isIndividual: true, isTeams: true },
  { title: 'Medical', isIndividual: true, isTeams: true },
  { title: 'Dental', isIndividual: true, isTeams: true },
  { title: 'Vision', isIndividual: true, isTeams: true },
  { title: 'Life', isIndividual: true, isTeams: true },
  { title: 'Disability', isIndividual: true, isTeams: true },
  { title: 'Accident', isIndividual: true, isTeams: true },
  { title: 'Hospital', isIndividual: true, isTeams: true },
  { title: 'Critical Illness', isIndividual: true, isTeams: true },
  { title: 'Proposal Exports', isIndividual: true, isTeams: true },
  { title: 'Instant web based proposals', isIndividual: true, isTeams: true },
  { title: 'Client Sign offs', isIndividual: true, isTeams: true },
  { title: 'Client and Broker Branding', isIndividual: true, isTeams: true },
  { title: 'RFP Custom Fields', isIndividual: false, isTeams: true },
  { title: 'Reporting', isIndividual: false, isTeams: true },
  { title: 'Unlimited Team Users', isIndividual: false, isTeams: true },
  { title: 'Team Workflows', isIndividual: false, isTeams: true },
  { title: 'Quoting and RFP dedicated support', isIndividual: false, isTeams: true },
  { title: 'Shared Carrier Contacts', isIndividual: false, isTeams: true },
  { title: 'Commission Disclosures', isIndividual: false, isTeams: true },
  { title: 'Chat Live with Underwriters', isIndividual: false, isTeams: true },
  { title: 'Broker Branded Emails *coming soon', isIndividual: false, isTeams: true },
];

export const BrokerPricing = (props) => {
  const { selectedTab } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userType = useSelector(getUserTypeSelector);

  const onClickFree = useCallback(() => {
    if (userType) {
      navigate(ROUTE.BROKER);
    }

    if (!userType) {
      navigate(ROUTE.SIGNUP);
    }
  }, [navigate, userType]);

  const onClickSubscription = useCallback(() => {
    if (userType) {
      navigate(ROUTE.BROKER);
      dispatch(setIsSubscription(true));
    }

    if (!userType) {
      navigate(ROUTE.SIGNUP);
    }
  }, [navigate, dispatch, userType]);

  const renderTable = useCallback((data) => {
    return (
      <table>
        <tbody>
          {data?.map((item) => (
            <tr key={item.title}>
              <th>{item.title}</th>
              <td>
                {item.isIndividual ? (
                  <img src={CheckedPurpleCircle} alt="CheckedPurpleCircle" />
                ) : (
                  ''
                )}
              </td>
              <td>
                {item.isTeams ? <img src={CheckedPurpleCircle} alt="CheckedPurpleCircle" /> : ''}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }, []);

  return (
    <div className={classes.BrokerPricing}>
      <div className={classes.BrokerPricingPlans}>
        <div className={classes.BrokerPricingPlansCard}>
          <div className={classes.BrokerPricingPlansCardHeader}>Features</div>
          <div className={classes.BrokerPricingPlansCardContent}>
            <div className={classes.BrokerPricingPlansCardTitle}></div>
            <div className={classes.BrokerPricingPlansCardSubTitle}></div>
          </div>
          <div className={classes.BrokerPricingPlansCardFooter}></div>
        </div>
        <div className={classes.BrokerPricingPlansCard}>
          <div className={classes.BrokerPricingPlansCardHeader}>Individual</div>
          <div className={classes.BrokerPricingPlansCardContent}>
            <div className={classes.BrokerPricingPlansCardTitle}>
              <span>Free</span>
            </div>
            <div className={classes.BrokerPricingPlansCardSubTitle}></div>
          </div>
          <div className={classes.BrokerPricingPlansCardFooter}>
            <Button title="Get Started" type="primary" onClick={onClickFree} />
          </div>
        </div>
        <div className={classes.BrokerPricingPlansCard}>
          <div className={classes.BrokerPricingPlansCardHeader}>Pro</div>
          <div className={classes.BrokerPricingPlansCardContent}>
            <div className={classes.BrokerPricingPlansCardTitle}>
              {selectedTab === 'monthly' && (
                <>
                  <span>$50</span>/Month/User
                </>
              )}
              {selectedTab === 'annual' && (
                <>
                  <span>$500</span>/Year/User <br />
                  (2 months Free)
                </>
              )}
            </div>
            <div className={classes.BrokerPricingPlansCardSubTitle}></div>
          </div>
          <div className={classes.BrokerPricingPlansCardFooter}>
            <Button title="Get Started" type="primary" onClick={onClickSubscription} />
          </div>
        </div>
      </div>
      {renderTable(tableData)}
    </div>
  );
};
