import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { auth } from 'src/app/database';
import api from 'src/app/apiSingleton';

import { getMedicalPlans } from '../medicalConfiguration';
import { getDentalPlans } from '../dentalConfiguration';
import { getVisionPlans } from '../visionConfiguration';
import { getLifePlans } from '../lifeConfiguration';
import { getDisabilityPlans } from '../disabilityConfiguration';
import { getAccidentPlans } from '../accidentCnfiguration';
import { getHospitalPlans } from '../hospitalConfiguration';
import { getCriticalPlans } from '../criticalConfiguration';

export const getDentalIssuers = createAsyncThunk(
  'plan-configuration/get-dental-issuers',
  async (data, thunkApi) => {
    try {
      const { read_token } = data;
      if (!read_token) {
        const token = await auth.currentUser.getIdToken();

        api.apiClient.setToken(token);
      }

      const response = await api.planConfiguration.getDentalIssuers();

      // TODO REMOVE FILTER IN FUTURE

      return response.filter((item) =>
        item.lines.includes('dental' || 'vision' || 'life' || 'disability'),
      );
    } catch (err) {
      console.warn('ERR', err);
    }
  },
);

export const getSharedCarriers = createAsyncThunk('proposal/get-shared-carriers', async (data) => {
  try {
    const { accountId, proposalId } = data;

    const token = await auth.currentUser.getIdToken();

    api.apiClient.setToken(token);

    const response = await api.planConfiguration.getSharedCarriers(accountId, proposalId);

    return response;
  } catch (err) {
    console.warn(err);
  }
});

export const getSharedContacts = createAsyncThunk(
  'proposal/get-shared-contacts',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.planConfiguration.getSharedContacts(accountId, proposalId);

      const state = thunkApi.getState();

      let updatedContacts = {};

      for (const contact of state.contacts.brokerContacts) {
        updatedContacts = {
          ...updatedContacts,
          [contact.rep_id]: response[contact.rep_id] ? response[contact.rep_id] : [],
        };
      }

      return updatedContacts;
    } catch (err) {
      console.error(err);
    }
  },
);

export const sendProposalRequest = createAsyncThunk(
  'proposal/send-proposal-request',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, payload } = data;

      await api.planConfiguration.sendProposalRequest(accountId, proposalId, payload);

      thunkApi.dispatch(getSharedCarriers({ accountId, proposalId }));

      toast('The request was successfully sent to the selected carrier(s)!', { type: 'success' });
    } catch (err) {
      console.warn(err);
    }
  },
);

export const sendContactsProposalRequest = createAsyncThunk(
  'proposal/send-contacts-proposal-request',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, payload } = data;

      await api.planConfiguration.sendContactsProposalRequest(accountId, proposalId, payload);

      thunkApi.dispatch(getSharedContacts({ accountId, proposalId }));

      toast('The request was successfully sent to the selected contact(s)!', { type: 'success' });
    } catch (err) {
      console.warn(err);
    }
  },
);

export const resendContactsProposalRequest = createAsyncThunk(
  'proposal/resend-contacts-proposal-request',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, payload, setIsLoading } = data;
      setIsLoading(true);

      const state = thunkApi.getState();

      await api.planConfiguration.resendContactsProposalRequest(accountId, proposalId, payload);

      const updatedPayload = {
        ...state.planConfiguration.sharedContacts,
        ...payload,
      };

      let updatedContacts = {};

      for (const contact of state.contacts.brokerContacts) {
        updatedContacts = {
          ...updatedContacts,
          [contact.rep_id]: updatedPayload[contact.rep_id] ? updatedPayload[contact.rep_id] : [],
        };
      }

      toast('The request was successfully sent to the selected contact!', { type: 'success' });
      setIsLoading(false);

      return updatedContacts;
    } catch (err) {
      const { setIsLoading } = data;

      setIsLoading(false);

      console.warn(err);
    }
  },
);

export const resendQuoteReminder = createAsyncThunk(
  'proposal/resend-quote-reminder',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, contactId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.planConfiguration.getSharedContacts(accountId, proposalId);

      const payload = {
        [contactId]: response[contactId],
      };

      await api.planConfiguration.resendContactsProposalRequest(accountId, proposalId, payload);

      toast('Reminder sent successfully', { type: 'success' });
    } catch (err) {
      console.error(err);
    }
  },
);

export const getSharedPlans = createAsyncThunk(
  'proposal/get-shared-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await thunkApi.dispatch(getMedicalPlans({ accountId, proposalId }));
      await thunkApi.dispatch(getDentalPlans({ accountId, proposalId }));
      await thunkApi.dispatch(getVisionPlans({ accountId, proposalId }));
      await thunkApi.dispatch(getLifePlans({ accountId, proposalId }));
      await thunkApi.dispatch(getDisabilityPlans({ accountId, proposalId }));
      await thunkApi.dispatch(getAccidentPlans({ accountId, proposalId }));
      await thunkApi.dispatch(getHospitalPlans({ accountId, proposalId }));
      await thunkApi.dispatch(getCriticalPlans({ accountId, proposalId }));
      await thunkApi.dispatch(getSharedContacts({ accountId, proposalId }));
    } catch (err) {
      console.error(err);
    }
  },
);

export const getRequestedProposalsStatus = createAsyncThunk(
  'plan-configuration/get-requsted-proposals-status',
  async (data) => {
    try {
      const { accountId, proposalId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.planConfiguration.getRequestedProposalsStatus(
        accountId,
        proposalId,
      );

      return response;
    } catch (err) {
      console.error('err', err);
    }
  },
);
