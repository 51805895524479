import { Page, Text, View } from '@react-pdf/renderer';

import { renderTableRow, renderTableValue } from './renderRow';

import { styles } from '../styles';

export const renderDecisionPage = (groupQuotes, fullname, totalEmployees) => {
  return groupQuotes?.map((quote) => {
    const updatedQuotes = quote?.plans;

    const updatedData = updatedQuotes.reduce((acc, value, index, array) => {
      if (index % 2 === 0) {
        acc.push(array.slice(index, index + 2));
      }
      return acc;
    }, []);

    return updatedData?.map((section, index) => {
      console.log('section', section);

      return (
        <Page size="A4" style={styles.page} key={index}>
          <Text style={styles.title}>
            {quote.name} {fullname ? `(${fullname})` : ''}
          </Text>
          <View style={styles.table}>
            {renderTableRow('Corporate dashboard', section, 'corporate_dashboard')}
            {renderTableRow('Personal finance scorecard', section, 'personal_finance_scorecard')}
            {renderTableRow('Embedded or standalone', section, 'embedded_or_standalone')}
            {renderTableRow(
              'Portal for financial health journey',
              section,
              'portal_for_financial_health_journey',
            )}
            {renderTableRow(
              'Admin portal for customization',
              section,
              'admin_portal_for_customization',
            )}
            {renderTableRow('Plan types', section, 'plan_types')}
            {renderTableRow('Contribution type', section, 'contribution_type')}
            {renderTableRow('Total out of pocket', section, 'total_out_of_pocket')}
            {renderTableRow('Coverage details', section, 'coverage_details')}
            {renderTableRow('Preventative care', section, 'preventative_care')}
            {renderTableRow('Out of pocket coverage', section, 'out_of_pocket_coverage')}
            {renderTableRow('Dependent coverage', section, 'dependent_coverage')}
            {renderTableRow('Data insights', section, 'data_insights')}
            {renderTableRow('Behavioral data insights', section, 'behavioral_data_insights')}
            {renderTableRow('Predictive analytics', section, 'predictive_analytics')}
            {renderTableRow(
              'Personalized financial scoring',
              section,
              'personalized_financial_scoring',
            )}
            {renderTableRow(
              'Aggregate financial stability scoring',
              section,
              'aggregate_financial_stability_scoring',
            )}
            {renderTableRow('HIPAA compliance', section, 'hipaa_compliance')}
            {renderTableRow('Data security', section, 'data_security')}
            {renderTableRow('GDPR compliance', section, 'gdpr_compliance')}
            {renderTableRow('Onboarding process', section, 'onboarding_process')}
            {renderTableRow('Customer service', section, 'customer_service')}
            {renderTableRow('Compliance and regulatory', section, 'compliance_and_regulatory')}
            {renderTableRow('Third party integration', section, 'third_party_integration')}
            {renderTableRow(
              'Estimated enrollment life in participation',
              section,
              'estimated_enrollment_life_in_participation',
            )}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Pricing</Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(
                  item,
                  index,
                  section,
                  item.cost_unit === 'dollar'
                    ? `$ ${item.cost} ${item.cost_type}`
                    : `${item.cost}% of new comission sold`,
                );
              })}
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Total</Text>
              </View>
              {section?.map((item, index) => {
                console.log('item', item);

                return renderTableValue(
                  item,
                  index,
                  section,
                  item.cost_unit === 'dollar'
                    ? `$ ${item.cost * totalEmployees}`
                    : `${item.cost}% of new comission sold`,
                );
              })}
            </View>
          </View>
        </Page>
      );
    });
  });
};
