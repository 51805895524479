import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Tooltip } from '@mui/material';

import { getIssuerCoverageOffers } from 'src/store/issuerCoverageBuilder';

import { Loader, LoaderWrapper, Status } from 'src/components';
import { IssuerOfferForm } from '../IssuerOfferForm/IssuerOfferForm';

import classes from 'src/sass/styles/Proposal/proposal.module.scss';

export const IssuerPlanCard = (props) => {
  const { config, build, planId } = props;

  const { card_options, coverage } = build;

  const dispatch = useDispatch();

  const params = useParams();
  const accountId = params?.id;
  const proposalId = params?.proposalId;

  const [isVisibleCardContent, setIsVisibleCardContent] = useState(false);

  const [offers, setOffers] = useState([]);
  const [isLoadingOffers, setIsLoadingOffers] = useState(true);

  const getOffers = useCallback(() => {
    dispatch(
      getIssuerCoverageOffers({
        accountId,
        proposalId,
        coverage,
        configId: config.id,
        setOffers,
        setIsLoading: setIsLoadingOffers,
      }),
    );
  }, [dispatch, config, accountId, proposalId, coverage]);

  useEffect(() => {
    if (isVisibleCardContent) {
      getOffers();
    }

    if (!isVisibleCardContent) {
      setOffers([]);
    }
  }, [getOffers, isVisibleCardContent]);

  const onCardClick = useCallback(() => {
    setIsVisibleCardContent((prev) => !prev);
  }, []);

  return (
    <>
      <div className={classes.Proposal}>
        <div className={classes.ProposalHeader} onClick={onCardClick}>
          <div className={classes.HeaderIcon}>
            <div className={classes.HeaderIconWrapper}>
              <i
                className={`fas fa-chevron-down ${
                  isVisibleCardContent
                    ? classes.ProposalHeaderIconActive
                    : classes.ProposalHeaderIcon
                }`}
              />
            </div>
          </div>
          <div className={classes.ProposalHeaderWrapper}>
            {card_options?.plan?.map((option) => (
              <>
                <div className={classes.ProposalHeaderItem} key={option.key}>
                  <div className={classes.ProposalHeaderItemHeading}>
                    <Tooltip title={option.title}>{option.title}</Tooltip>
                  </div>

                  <div className={classes.ProposalHeaderItemTitle}>
                    <Tooltip
                      title={
                        option?.format ? option?.format(config[option.key]) : config[option.key]
                      }
                    >
                      {option?.format ? option?.format(config[option.key]) : config[option.key]}
                    </Tooltip>
                  </div>
                </div>
                {option?.render &&
                  option.render(config[option.key])?.map((renderItem) => (
                    <div className={classes.ProposalHeaderItem} key={renderItem.key}>
                      <div className={classes.ProposalHeaderItemHeading}>
                        <Tooltip title={renderItem.title}>{renderItem.title}</Tooltip>
                      </div>

                      <div className={classes.ProposalHeaderItemTitle}>
                        <Tooltip
                          title={
                            renderItem?.format
                              ? renderItem?.format(config[renderItem.key])
                              : config[renderItem.key]
                          }
                        >
                          {renderItem?.format
                            ? renderItem?.format(config[renderItem.key])
                            : config[renderItem.key]}
                        </Tooltip>
                      </div>
                    </div>
                  ))}
              </>
            ))}
            <div className={classes.ProposalHeaderButtons}>
              <div className={classes.ProposalHeaderStatus} style={{ marginRight: 0 }}>
                <Status status={config.status} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {isVisibleCardContent && (
        <>
          {isLoadingOffers ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : (
            <IssuerOfferForm
              build={build}
              offers={[{ ...config, issuer_name: 'broker' }, ...offers]}
              setOffers={setOffers}
              planId={planId}
            />
          )}
        </>
      )}
    </>
  );
};
