import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';

import {
  deleteProposal,
  getAccountProposalSelector,
  getIsLoadingShareProposal,
  getIsShareProposalModal,
  setIsShareProposalModal,
  shareProposal,
} from 'src/store/proposals';
import { getAccountData } from 'src/store/broker';
import { getAccountContacts } from 'src/store/accountContacts';
import { petInsuranceDataSelector } from 'src/store/pet';
import { coverageSelector } from 'src/store/coverageBuilder';

import { Button, Modal, Loader, AccountContacts } from 'src/components';
import { ExportModal, ProposalHeaderInfo } from './components';

import EditIcon from 'src/assets/proposal/editIcon.svg';

import classes from './proposalHeader.module.scss';

export const ProposalHeader = (props) => {
  const { isReadOnlyMode, accountId, proposalId, isEditable = true } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const teamId = params?.teamId;

  const accountData = useSelector(getAccountData);
  const accountProposalData = useSelector(getAccountProposalSelector);
  const petInsuranceData = useSelector(petInsuranceDataSelector);

  const coverageData = useSelector(coverageSelector);

  const isShareProposalModal = useSelector(getIsShareProposalModal);
  const isLoadingShareProposal = useSelector(getIsLoadingShareProposal);

  const [isEdit, setIsEdit] = useState(false);

  const [isDeleteProposalModal, setIsDeleteProposalModal] = useState(false);

  useEffect(() => {
    if (accountProposalData?.name && accountData?.company_name) {
      document.title = `${accountProposalData?.name} | ${accountData?.company_name} | Monark`;
    }
  }, [accountProposalData, accountData]);

  useEffect(() => {
    return () => {
      dispatch(setIsShareProposalModal(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!_.isEmpty(accountData)) {
      dispatch(getAccountContacts({ accountId: accountData.id }));
    }
  }, [dispatch, accountData]);

  const onClickEditMode = useCallback(() => {
    setIsEdit(true);
  }, []);

  const onOpenSubmitModal = useCallback(() => {
    dispatch(setIsShareProposalModal(true));
  }, [dispatch]);

  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const handleOpenDeleteProposalModal = useCallback(() => {
    setIsDeleteProposalModal((prev) => !prev);
  }, []);

  const onSubmitDeleteProposal = useCallback(() => {
    dispatch(deleteProposal({ accountId, proposalId, teamId, navigate }));
  }, [dispatch, navigate, proposalId, accountId, teamId]);

  const onCloseSubmitModal = useCallback(() => {
    dispatch(setIsShareProposalModal(false));
  }, [dispatch]);

  const onSubmitProposal = useCallback(async () => {
    await dispatch(shareProposal({ accountId, proposalId }));
  }, [dispatch, accountId, proposalId]);

  const proposalQuotes =
    accountProposalData?.groups?.map((group) => group?.quotes)[0]?.length > 0 ||
    coverageData['medical_quotes']?.length > 0 ||
    coverageData['dental_quotes']?.length > 0 ||
    coverageData['vision_quotes']?.length > 0 ||
    coverageData['life_quotes']?.length > 0 ||
    coverageData['disability_quotes']?.length > 0 ||
    coverageData['accident_quotes']?.length > 0 ||
    coverageData['hospital_quotes']?.length > 0 ||
    coverageData['critical_illness_quotes']?.length > 0 ||
    petInsuranceData?.quotes?.length > 0;

  return (
    <>
      {isLoadingExport && (
        <div className={classes.LoaderWrapper}>
          <Loader />
        </div>
      )}

      {isDeleteProposalModal && (
        <Modal type="small" closeButtonType="inside" onClose={handleOpenDeleteProposalModal}>
          <div className={classes.ModalContent}>
            <div className={classes.ModalContentTitle}>
              Are you sure you want to delete this proposal?
            </div>
            <div className={classes.ModalContentFooter}>
              <Button type="secondary" title="Cancel" onClick={handleOpenDeleteProposalModal} />
              <Button type="danger" title="Yes" onClick={onSubmitDeleteProposal} />
            </div>
          </div>
        </Modal>
      )}

      {isShareProposalModal && (
        <Modal type="xlarge" closeButtonType="inside" onClose={onCloseSubmitModal}>
          <div className={classes.ModalWrapper}>
            <div className={classes.ModalTitle}>Submit Proposal: {accountProposalData?.name}</div>
            <div>Are you sure you want to submit proposal to the following contacts?</div>
            <AccountContacts accountId={accountId} />
            <div className={classes.ModalFooter}>
              <Button
                title="Confirm & Submit"
                type="primary"
                onClick={onSubmitProposal}
                isLoading={isLoadingShareProposal}
                isDisabled={isLoadingShareProposal}
              />
              <Button title="Cancel" type="secondary" onClick={onCloseSubmitModal} />
            </div>
          </div>
        </Modal>
      )}

      <div className={classes.ProposalHeader}>
        <ProposalHeaderInfo isEdit={isEdit} setIsEdit={setIsEdit} />

        <div className={!isEdit ? classes.ProposalHeaderButtonWrapper : ''}>
          {!isReadOnlyMode && (
            <>
              {!isEdit && proposalQuotes && (
                <div className={classes.ProposalHeaderSubmit}>
                  <Button title="Submit" type="primary" onClick={onOpenSubmitModal} />
                </div>
              )}
              <ExportModal isEdit={isEdit} setIsLoadingExport={setIsLoadingExport} />

              {isEditable && (
                <div className={classes.ProposalHeaderDeleteButton}>
                  {!isEdit && (
                    <>
                      <img
                        src={EditIcon}
                        className={classes.ProposalHeaderEdit}
                        alt="EditIcon"
                        onClick={onClickEditMode}
                      />
                    </>
                  )}
                  {isEdit && (
                    <i className="fa fa-trash-o" onClick={handleOpenDeleteProposalModal} />
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
