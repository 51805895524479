import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { auth } from 'src/app/database';
import {
  signInWithEmailAndPassword,
  getLoginError,
  getIsLoadingLoginSelector,
  getUserTypeSelector,
  setLoginError,
} from 'src/store/app';
import { getIssuerDataSelector } from 'src/store/issuer';

import { Button, ErrorBox, InputV2 } from 'src/components';

import { ROUTE } from 'src/constants/routes';

import MonarkLogo from 'src/assets/header/monarkLogo.svg';

import classes from './login.module.scss';

export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const [user] = useAuthState(auth);
  const [searchParams] = useSearchParams();

  const continueUrl = searchParams.get('continueUrl');
  const invite = searchParams.get('invite');

  const isIssuerLogin = [ROUTE.ISSUER_LOGIN].includes(pathname);

  const isLoading = useSelector(getIsLoadingLoginSelector);
  const loginError = useSelector(getLoginError);

  const userType = useSelector(getUserTypeSelector);
  const issuerAccountData = useSelector(getIssuerDataSelector);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    dispatch(setLoginError(''));
  }, [dispatch]);

  useEffect(() => {
    if (!_.isEmpty(user)) {
      if (userType === 'broker' && user.emailVerified) {
        navigate(`${ROUTE.BROKER}${invite ? `?invite=${invite}` : ''}`);
      }
    }
  }, [issuerAccountData, navigate, user, userType, invite]);

  useEffect(() => {
    if (!_.isEmpty(issuerAccountData) && userType === 'rep') {
      if (continueUrl) {
        navigate(continueUrl);
      }

      if (!continueUrl) {
        navigate(`/rep/my-rfps`);
      }
    }
  }, [navigate, issuerAccountData, userType, continueUrl]);

  const onChangeEmail = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  const onChangePassword = useCallback((event) => {
    setPassword(event.target.value);
  }, []);

  const onClickCreateAccount = useCallback(() => {
    if (isIssuerLogin) {
      navigate(`${ROUTE.ISSUER_SIGNUP}?continueUrl=${continueUrl}`);
    }

    if (!isIssuerLogin) {
      navigate(`${ROUTE.SIGNUP}${invite ? `?invite=${invite}` : ''}`);
    }
  }, [navigate, isIssuerLogin, continueUrl, invite]);

  const onClickForgotPassword = useCallback(() => {
    navigate(ROUTE.FORGOT_PASSWORD);
  }, [navigate]);

  const onSubmitLogin = useCallback(
    async (event) => {
      event.preventDefault();

      if (!isIssuerLogin) {
        const payload = {
          email,
          password,
          navigation: navigate,
          continueUrl: `/broker/accounts${invite ? `?invite=${invite}` : ''}`,
        };

        dispatch(signInWithEmailAndPassword(payload));
      }

      if (isIssuerLogin) {
        const payload = {
          email: `${email}`,
          password,
          navigation: navigate,
          continueUrl: continueUrl,
        };

        dispatch(signInWithEmailAndPassword(payload));
      }
    },
    [isIssuerLogin, email, password, navigate, invite, dispatch, continueUrl],
  );

  const onClickLogo = useCallback(() => {
    navigate(ROUTE.DEFAULT);
  }, [navigate]);

  return (
    <div className={classes.Layout}>
      <div className={classes.LayoutEllipseLeft} />
      <div className={classes.LayoutEllipseRight} />
      <div className={classes.LayoutLightLine} />
      <div className={classes.Login}>
        <div className={classes.LoginCard}>
          <div className={classes.LoginLogo}>
            <img src={MonarkLogo} alt="loginMonarkLogo" onClick={onClickLogo} />
          </div>
          {loginError && <ErrorBox message={loginError} />}
          <form onSubmit={onSubmitLogin}>
            <InputV2
              label="Email"
              type="email"
              value={email}
              placeholder="youremail@example.com"
              onChange={onChangeEmail}
              autoFocus
              required
            />
            <div className={classes.LoginInputWrapper}>
              <InputV2
                label="Password"
                type="password"
                value={password}
                placeholder="Input password"
                onChange={onChangePassword}
                required
              />
            </div>
            <div className={classes.LoginForgotPassword} onClick={onClickForgotPassword}>
              Reset Password?
            </div>
            <Button
              type="primaryPurple"
              size="m"
              title="Sign In"
              buttonType="submit"
              isLoading={isLoading}
            />
          </form>
          <div className={classes.LoginFooter}>
            Don't have an account? <span onClick={onClickCreateAccount}>Create Account</span>
          </div>
        </div>
      </div>
    </div>
  );
};
