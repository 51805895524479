export const decisionHeaderRows = [
  { id: '', name: '' },
  { id: 'contribution_type', name: 'Contribution Type' },
  { id: 'embedded_or_standalone', name: 'Embeded or standalone' },
  { id: 'preventative_care', name: 'Preventative care' },
  { id: 'aggregate_financial_stability_scoring', name: 'Stability scoring' },
  { id: 'estimated_enrollment_life_in_participation', name: 'Estimated enrollment' },
  { id: 'cost_type', name: 'Employee cost' },
];

export const selectedDecisionTableRows = [
  { title: '', key: 'logo_url', type: 'image' }, // image, default, currency, boolean

  { key: 'corporate_dashboard', title: 'Corporate dashboard' },
  { key: 'personal_finance_scorecard', title: 'Personal finance scorecard' },
  { key: 'embedded_or_standalone', title: 'Embedded or standalone' },
  { key: 'portal_for_financial_health_journey', title: 'Portal for financial health journey' },
  { key: 'admin_portal_for_customization', title: 'Admin portal for customization' },
  { key: 'plan_types', title: 'Plan types' },
  { key: 'contribution_type', title: 'Contribution type' },
  { key: 'total_out_of_pocket', title: 'Total out of pocket' },
  { key: 'coverage_details', title: 'Coverage details' },
  { key: 'preventative_care', title: 'Preventative care' },
  { key: 'out_of_pocket_coverage', title: 'Out of pocket coverage' },
  { key: 'dependent_coverage', title: 'Dependent coverage' },
  { key: 'data_insights', title: 'Data insights' },
  { key: 'behavioral_data_insights', title: 'Behavioral data insights' },
  { key: 'predictive_analytics', title: 'Predictive analytics' },
  { key: 'personalized_financial_scoring', title: 'Personalized financial scoring' },
  { key: 'aggregate_financial_stability_scoring', title: 'Aggregate financial stability scoring' },
  { key: 'hipaa_compliance', title: 'HIPAA compliance' },
  { key: 'data_security', title: 'Data security' },
  { key: 'gdpr_compliance', title: 'GDPR compliance' },
  { key: 'onboarding_process', title: 'Onboarding process' },
  { key: 'customer_service', title: 'Customer service' },
  { key: 'compliance_and_regulatory', title: 'Compliance and regulatory' },
  { key: 'third_party_integration', title: 'Third party integration' },
  {
    key: 'estimated_enrollment_life_in_participation',
    title: 'Estimated enrollment life in participation',
  },
  {
    key: 'pricing',
    title: 'Pricing',
    type: 'custom',
    value: (item) => {
      return item.cost_unit === 'dollar'
        ? `$ ${item.cost} ${item.cost_type}`
        : `${item.cost}% of new comission sold`;
    },
  },
  {
    key: 'total',
    title: 'Total',
    type: 'custom',
    value: (item) => {
      return item.cost_unit === 'dollar'
        ? `$ ${item.cost * item.employees}`
        : `${item.cost}% of new comission sold`;
    },
  },
];
