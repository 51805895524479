import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import {
  agencyUserRoleSelector,
  brokerAgencySelector,
  isLoadingBrokerAgencySelector,
  updateBrokerAgency,
} from 'src/store/agency';

import { Button, Input, Loader } from 'src/components';

import classes from './agencyProfile.module.scss';
import { UploadImage } from 'src/features/uploadImage/UploadImage';

export const AgencyProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const agencyData = useSelector(brokerAgencySelector);
  const isLoadingAgencyData = useSelector(isLoadingBrokerAgencySelector);
  const agencyUserRole = useSelector(agencyUserRoleSelector);

  const [isEdit, setIsEdit] = useState(false);
  const [agencyName, setAgencyName] = useState('');
  const [agencyAddress, setAgencyAddress] = useState('');
  const [agencyCity, setAgencyCity] = useState('');
  const [agencyState, setAgencyState] = useState('');
  const [agencyZipCode, setAgencyZipCode] = useState('');

  const [agencyLogo, setAgencyLogo] = useState(agencyData?.logo_url);

  useEffect(() => {
    if (!_.isEmpty(agencyData)) {
      setAgencyLogo(agencyData?.logo_url);
      setAgencyName(agencyData?.name);
      setAgencyAddress(agencyData?.address_street);
      setAgencyCity(agencyData?.address_city);
      setAgencyState(agencyData?.address_state);
      setAgencyZipCode(agencyData?.address_zip_code);
    }
  }, [agencyData]);

  useEffect(() => {
    if (!_.isEmpty(agencyData) && agencyData.status === 'inactive') {
      navigate(`/teams/${agencyData.id}/settings?activeTab=billing`);
    }
  }, [agencyData, navigate]);

  const onSetAgencyLogo = useCallback((image) => {
    setAgencyLogo(image);
  }, []);

  const onChangeInput = useCallback((event, setValue) => {
    setValue(event.target.value);
  }, []);

  const onClickEdit = useCallback(() => {
    setIsEdit(true);
  }, []);

  const onSaveEdit = useCallback(() => {
    const payload = {
      name: agencyName,
      address_street: agencyAddress,
      address_city: agencyCity,
      address_state: agencyState,
      address_zip_code: agencyZipCode,
      logo_url: agencyLogo,
    };

    dispatch(updateBrokerAgency({ payload, id: agencyData?.id }));

    setIsEdit(false);
  }, [
    dispatch,
    agencyData,
    agencyAddress,
    agencyCity,
    agencyName,
    agencyState,
    agencyZipCode,
    agencyLogo,
  ]);

  const onClickCancel = useCallback(() => {
    setAgencyLogo(agencyData?.logo_url);
    setAgencyName(agencyData?.name);
    setAgencyAddress(agencyData?.address_street);
    setAgencyCity(agencyData?.address_city);
    setAgencyState(agencyData?.address_state);
    setAgencyZipCode(agencyData?.address_zip_code);

    setIsEdit(false);
  }, [agencyData]);

  return (
    <>
      {isLoadingAgencyData ? (
        <div className={classes.LoaderWrapper}>
          <Loader />
        </div>
      ) : (
        <div className={classes.AgencyProfile}>
          <div className={classes.AgencyProfileUploadImage}>
            <UploadImage
              path={`users/${agencyData?.id}/avatar`}
              isOnlyImage={!isEdit}
              previewImage={agencyLogo}
              setPreviewImage={onSetAgencyLogo}
              type="profile"
            />
          </div>
          <div>
            <div className={classes.AgencyProfileName}>
              {!isEdit ? (
                agencyName
              ) : (
                <Input
                  value={agencyName}
                  onChange={(event) => onChangeInput(event, setAgencyName)}
                />
              )}
            </div>
            <div className={classes.AgencyProfileDescription}>
              <div className={classes.AgencyProfileDescriptionWrapper}>
                <div className={classes.AgencyProfileDescriptionItemName}>Address</div>
                <div className={classes.AgencyProfileAddress}>
                  <div className={classes.AgencyProfileDescriptionItemValue}>
                    {!isEdit ? (
                      agencyAddress
                    ) : (
                      <Input
                        label="Address"
                        value={agencyAddress}
                        onChange={(event) => onChangeInput(event, setAgencyAddress)}
                      />
                    )}
                  </div>
                  <div className={classes.AgencyProfileDescriptionItemValue}>
                    {!isEdit &&
                      `${agencyCity ? agencyCity : ''} ${agencyState ? `, ${agencyState}` : ''} ${
                        agencyZipCode ? agencyZipCode : ''
                      }`}
                    {isEdit && (
                      <div>
                        <Input
                          label="City"
                          value={agencyCity}
                          onChange={(event) => onChangeInput(event, setAgencyCity)}
                        />
                        <Input
                          label="State"
                          value={agencyState}
                          onChange={(event) => onChangeInput(event, setAgencyState)}
                        />
                        <Input
                          label="Zip code"
                          value={agencyZipCode}
                          onChange={(event) => onChangeInput(event, setAgencyZipCode)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.AgencyProfileEditButton}>
              {isEdit && (
                <>
                  <Button title="Save" onClick={onSaveEdit} type="primary" />
                  <Button title="Cancel" onClick={onClickCancel} type="secondary" />
                </>
              )}
              {!isEdit && agencyUserRole === 'owner' && (
                <Button title="Edit" onClick={onClickEdit} type="secondary" />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
