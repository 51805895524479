import { createAsyncThunk } from '@reduxjs/toolkit';

import { v4 as uuid } from 'uuid';
import { auth } from 'src/app/database';

import {
  setIsDeleteAccountModal,
  setIsSubscriptionModal,
  setIsEditProfile,
  setBrokerAccountsMeta,
  setProposalsMeta,
} from './broker.slice';

import { STRIPE_PUBLISHABLE_KEY } from 'src/constants/env';
import { ROUTE } from 'src/constants/routes';
import api from 'src/app/apiSingleton';
import { toast } from 'react-toastify';
import { createAccountContact } from '../accountContacts';
import {
  calculateMedicalAgeRates,
  calculateMedicalRates,
} from 'src/constants/calculation/medicalRates';
import { calculateDentalVisionRates } from 'src/constants/calculation/dentalVisionRates';
import { calculateLifeRates, calculateUniLifeRates } from 'src/constants/calculation/lifeRates';
import {
  calculateConfigurationAgeRates,
  calculateConfigurationRates,
  calculateLifeConfigurationAgeRates,
} from 'src/constants/calculation/calculateConfigurationRates';
import {
  calculateDisabilityAgeRates,
  calculateDisabilityRates,
} from 'src/constants/calculation/disabilityRates';
import { getDecisionSupportQuotes } from '../decisionSupport';

// eslint-disable-next-line no-undef
const stripe = Stripe(STRIPE_PUBLISHABLE_KEY);

export const fetchBrokerData = createAsyncThunk('broker/user', async (data, thunkApi) => {
  try {
    const token = await auth.currentUser.getIdToken();

    const userId = data ? data : auth.currentUser.uid;

    api.apiClient.setToken(token);

    const response = await api.broker.getBrokerData(userId);

    return response;
  } catch (err) {
    console.warn('[ERROR]', err);
  }
});

export const createBrokerAccount = createAsyncThunk('broker/create', async (data, thunkApi) => {
  try {
    const { payload, navigate, contacts } = data;
    const token = await auth.currentUser.getIdToken();

    api.apiClient.setToken(token);

    const response = await api.broker.createBrokerAccount(payload);

    if (!payload?.org_id) {
      navigate(`${ROUTE.BROKER}/${response.id}`);
    }

    if (payload?.org_id) {
      navigate(`/teams/${payload?.org_id}/accounts/${response.id}`);
    }

    const contactsResponse = [];

    for (const item of contacts) {
      const contact = await thunkApi.dispatch(
        createAccountContact({ accountId: item?.account_id, payload: item, create_account: true }),
      );

      contactsResponse?.push(contact);
    }

    if (contactsResponse?.length) {
      toast('Contact was created successfully', { type: 'success' });
    }

    return response;
  } catch (err) {
    console.warn('[ERROR]', err);
  }
});

export const getBrokerAccounts = createAsyncThunk(
  'broker/accounts-list',
  async (data, thunkApi) => {
    try {
      const { searchValue, sort, page, per_page } = data;

      const token = await auth.currentUser.getIdToken();

      const userId = auth.currentUser.uid;

      const payload = {
        page: page ? page : 0,
        per_page,
        q: searchValue ? searchValue : '',
        'f[broker_id]': userId,
        sort,
      };

      for (const item in payload) {
        if (item === 'page') continue;

        if (!payload[item]) {
          delete payload[item];
        }
      }

      api.apiClient.setToken(token);

      const response = await api.broker.getBrokerAccounts(payload);

      const updatedAccounts = [];

      for (const account of response.items) {
        const primaryContact = account?.contacts?.find((item) => item?.contact_type === 'primary');

        const payload = {
          ...account,
          contact_name: primaryContact?.name,
          contact_email: primaryContact?.email,
        };

        updatedAccounts.push(payload);
      }

      thunkApi.dispatch(setBrokerAccountsMeta(response.meta));

      return updatedAccounts;
    } catch (err) {
      console.warn('[ERROR]', err);
    }
  },
);

export const getBrokerAccountData = createAsyncThunk('broker/account', async (data, thunkApi) => {
  try {
    const { paramsId } = data;
    const token = await auth.currentUser.getIdToken();
    api.apiClient.setToken(token);

    const response = await api.broker.getBrokerAccountData(paramsId);
    const broker = await api.broker.getBrokerData(response?.broker_id);

    return { ...response, broker };
  } catch (err) {
    const { navigate } = data;

    navigate(ROUTE.BROKER);

    console.warn('[ERROR]', err);
  }
});

export const updateProfileData = createAsyncThunk(
  'broker/update-profile',
  async (data, thunkApi) => {
    try {
      const {
        email,
        fullName,
        zipCode,
        photo_url,
        address_street,
        address_city,
        address_state,
        phone_number,
      } = data;
      const token = await auth.currentUser.getIdToken();
      const currentUser = auth.currentUser;
      const userId = currentUser?.uid;

      await currentUser.updateProfile({
        email,
        photo_url,
        displayName: fullName,
      });

      api.apiClient.setToken(token);

      const payload = {
        email,
        photo_url,
        display_name: fullName,
        address_zip_code: zipCode,
        address_street,
        address_city,
        address_state,
        phone_number,
      };

      const response = await api.broker.updateProfileData(userId, payload);

      thunkApi.dispatch(fetchBrokerData());
      thunkApi.dispatch(setIsEditProfile(false));

      return response;
    } catch (err) {
      console.warn('[ERROR]', err);
    }
  },
);

export const sendPasswordResetEmail = createAsyncThunk(
  'broker/reset-password',
  async (email, thunkApi) => {
    try {
      await auth.sendPasswordResetEmail(email);
    } catch (err) {
      console.warn('[ERROR]', err);
    }
  },
);

export const cancelBillingSubscription = createAsyncThunk(
  'broker/cancel-subscription',
  async (data, thunkApi) => {
    try {
      const userId = auth.currentUser.uid;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await api.broker.deleteBillingSubscription(userId);

      thunkApi.dispatch(fetchBrokerData());

      thunkApi.dispatch(setIsSubscriptionModal(false));
    } catch (err) {
      console.warn('[ERROR]', err);
    }
  },
);

export const updateBrokerAccount = createAsyncThunk(
  'broker/update-account',
  async (data, thunkApi) => {
    try {
      const { id, navigate, payload } = data;
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await api.broker.updateAccount({ id, payload });

      if (!payload?.org_id) {
        navigate(`${ROUTE.BROKER}/${id}`);
      }

      if (payload?.org_id) {
        navigate(`/teams/${payload?.org_id}/accounts/${id}`);
      }
    } catch (err) {
      console.warn('[ERROR]', err);
    }
  },
);

export const deleteAccountContact = createAsyncThunk(
  'broker/delete-account-contact',
  async (data, thunkApi) => {
    try {
      const { id, contactId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.broker.deleteAccountContact(id, contactId);

      thunkApi.dispatch(getBrokerAccountData({ paramsId: id }));

      return response;
    } catch (err) {
      console.warn('[ERROR]', err);
    }
  },
);

export const deleteBrokerAccount = createAsyncThunk(
  'broker/delete-account',
  async (data, thunkApi) => {
    try {
      const { accountId, navigate } = data;
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await api.broker.deleteBrokerAccount(accountId);

      thunkApi.dispatch(setIsDeleteAccountModal(false));

      navigate(ROUTE.BROKER);
    } catch (err) {
      console.warn('[ERROR]', err);
    }
  },
);

export const getPaymentMethods = createAsyncThunk(
  'broker/payment-methods',
  async (data, thunkApi) => {
    try {
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.broker.getPaymentMethods();

      return response.data;
    } catch (err) {
      console.warn(err);
    }
  },
);

export const createCheckoutSession = createAsyncThunk(
  'broker/checkout-session',
  async (data, thunkApi) => {
    try {
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.broker.createCheckoutSession(data);

      await stripe.redirectToCheckout({ sessionId: response.sessionId });
    } catch (err) {
      console.warn('err', err);
    }
  },
);

export const changePaymentMethod = createAsyncThunk(
  'broker/checkout-session',
  async (data, thunkApi) => {
    try {
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.broker.changePaymentMethod();

      await stripe.redirectToCheckout({ sessionId: response.sessionId });
    } catch (err) {
      console.warn('err', err);
    }
  },
);

export const getProposalsList = createAsyncThunk(
  'broker/get-proposals-list',
  async (data, thunkApi) => {
    try {
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const payload = { ...data };

      for (const item in payload) {
        if (!payload[item]) {
          delete payload[item];
        }
      }

      const response = await api.broker.getProposalsList(payload);

      const brokerAccounts = (await thunkApi.dispatch(getBrokerAccounts({}))).payload;

      thunkApi.dispatch(setProposalsMeta(response.meta));

      return response?.items?.map((item) => {
        const company_name = brokerAccounts?.find(
          (account) => account.id === item.account_id,
        )?.company_name;

        return {
          ...item,
          company_name,
        };
      });
    } catch (err) {
      console.warn('err', err);
    }
  },
);

export const getRfpQuotes = createAsyncThunk('broker/get-rfp-quotes', async (data, thunkApi) => {
  try {
    const { accountId, proposalId, type = 'all' } = data;

    const token = await auth.currentUser.getIdToken();

    api.apiClient.setToken(token);

    let selectedMedicalQuotes = {};
    let selectedDentalQuotes = {};
    let selectedVisionQuotes = {};
    let selectedLifeQuotes = {};
    let selectedDisabilityQuotes = {};
    let selectedAccidentQuotes = {};
    let selectedHospitalQuotes = {};
    let selectedCriticalQuotes = {};

    const groupMembers = thunkApi.getState().groupMembers.groupMembers;

    const medicalQuotes = await api.planConfiguration.getMedicalQuotePlans(accountId, proposalId);
    const dentalQuotes = await api.planConfiguration.getDentalQuotePlans(accountId, proposalId);
    const visionQuotes = await api.planConfiguration.getVisionQuotePlans(accountId, proposalId);
    const lifeQuotes = await api.planConfiguration.getLifeQuotePlans(accountId, proposalId);
    const disabilityQuotes = await api.planConfiguration.getDisabilityQuotePlans(
      accountId,
      proposalId,
    );
    const accidentQuotes = await api.accidentConfiguration.getAccidentQuotePlans(
      accountId,
      proposalId,
    );
    const hospitalQuotes = await api.hospitalConfiguration.getHospitalQuotePlans(
      accountId,
      proposalId,
    );
    const criticalQuotes = await api.criticalConfiguration.getCriticalQuotePlans(
      accountId,
      proposalId,
    );
    const decisionResponse = await thunkApi.dispatch(
      getDecisionSupportQuotes({ accountId, proposalId }),
    );

    console.log('decisionResponse', decisionResponse?.payload?.quotes);

    const issuersResponse = await api.planConfiguration.getDentalIssuers();

    // TODO REMOVE FILTER IN FUTURE

    const filteredIssuers = issuersResponse.filter((item) =>
      item.lines.includes(
        'medical' ||
          'dental' ||
          'vision' ||
          'life' ||
          'disability' ||
          'accident' ||
          'hospital' ||
          'critical_illness',
      ),
    );

    for (const quote of medicalQuotes) {
      const response = await api.planConfiguration.getMedicalQuotes(
        accountId,
        proposalId,
        'medical',
        quote.id,
      );

      let updatedQuotes = [];

      for (const item of [{ ...quote, issuer_name: 'broker' }, ...response]) {
        let calculatedRates = {};

        if (item?.rate_tier_type?.includes('tier')) {
          calculatedRates = calculateMedicalRates(groupMembers, item, {});
        }

        if (!item?.rate_tier_type?.includes('tier')) {
          calculatedRates = calculateMedicalAgeRates(groupMembers, item);
        }

        const payload = {
          ...item,
          carrier_name: filteredIssuers?.find((issuer) => issuer?.ids[0] === item?.issuer_id)?.name,
          calculation: calculatedRates,
        };

        updatedQuotes.push(payload);
      }

      const quotes =
        type === 'all' ? updatedQuotes : updatedQuotes.filter((item) => item.status !== 'selected');

      if (quotes.length) {
        selectedMedicalQuotes = {
          ...selectedMedicalQuotes,
          [quote.id]:
            type === 'all'
              ? updatedQuotes
              : updatedQuotes.filter((item) => item.status !== 'selected'),
        };
      }
    }

    for (const quote of dentalQuotes) {
      const response = await api.planConfiguration.getMedicalQuotes(
        accountId,
        proposalId,
        'dental',
        quote.id,
      );

      let updatedQuotes = [];

      for (const item of [{ ...quote, issuer_name: 'broker' }, ...response]) {
        const calculatedRates = calculateDentalVisionRates(
          groupMembers,
          item,
          {},
          item?.selected_plan_name,
          'dental',
        );

        const payload = {
          ...item,
          carrier_name: filteredIssuers?.find((issuer) => issuer?.ids[0] === item?.issuer_id)?.name,
          calculation: calculatedRates,
        };

        updatedQuotes.push(payload);
      }

      const quotes =
        type === 'all' ? updatedQuotes : updatedQuotes.filter((item) => item.status !== 'selected');

      if (quotes.length) {
        selectedDentalQuotes = {
          ...selectedDentalQuotes,
          [quote.id]:
            type === 'all'
              ? updatedQuotes
              : updatedQuotes.filter((item) => item.status !== 'selected'),
        };
      }
    }

    for (const quote of visionQuotes) {
      const response = await api.planConfiguration.getMedicalQuotes(
        accountId,
        proposalId,
        'vision',
        quote.id,
      );

      let updatedQuotes = [];

      for (const item of [{ ...quote, issuer_name: 'broker' }, ...response]) {
        const calculatedRates = calculateDentalVisionRates(
          groupMembers,
          item,
          {},
          item?.selected_plan_name,
          'vision',
        );

        const payload = {
          ...item,
          carrier_name: filteredIssuers?.find((issuer) => issuer?.ids[0] === item?.issuer_id)?.name,
          calculation: calculatedRates,
        };

        updatedQuotes.push(payload);
      }

      const quotes =
        type === 'all' ? updatedQuotes : updatedQuotes.filter((item) => item.status !== 'selected');

      if (quotes.length) {
        selectedVisionQuotes = {
          ...selectedVisionQuotes,
          [quote.id]:
            type === 'all'
              ? updatedQuotes
              : updatedQuotes.filter((item) => item.status !== 'selected'),
        };
      }
    }

    for (const quote of lifeQuotes) {
      const response = await api.planConfiguration.getMedicalQuotes(
        accountId,
        proposalId,
        'life',
        quote.id,
      );

      let updatedQuotes = [];

      for (const item of [{ ...quote, issuer_name: 'broker' }, ...response]) {
        let calculatedRates = {};

        if (item?.rate_tier_type === '4tier') {
          if (item?.is_uni_tobacco) {
            calculatedRates = calculateUniLifeRates(groupMembers, item);
          }

          if (!item?.is_uni_tobacco) {
            calculatedRates = calculateLifeRates(groupMembers, item);
          }
        }

        if (item?.rate_tier_type !== '4tier') {
          calculatedRates = calculateLifeConfigurationAgeRates(groupMembers, item);
        }

        const payload = {
          ...item,
          carrier_name: filteredIssuers?.find((issuer) => issuer?.ids[0] === item?.issuer_id)?.name,
          calculation: calculatedRates,
        };

        updatedQuotes.push(payload);
      }

      const quotes =
        type === 'all' ? updatedQuotes : updatedQuotes.filter((item) => item.status !== 'selected');

      if (quotes.length) {
        selectedLifeQuotes = {
          ...selectedLifeQuotes,
          [quote.id]:
            type === 'all'
              ? updatedQuotes
              : updatedQuotes.filter((item) => item.status !== 'selected'),
        };
      }
    }

    for (const quote of disabilityQuotes) {
      const response = await api.planConfiguration.getMedicalQuotes(
        accountId,
        proposalId,
        'disability',
        quote.id,
      );

      let updatedQuotes = [];

      for (const item of [{ ...quote, issuer_name: 'broker' }, ...response]) {
        let calculatedRates = {};

        if (item?.rate_tier_type === 'employee') {
          calculatedRates = calculateDisabilityRates(groupMembers, item);
        }

        if (item?.rate_tier_type !== 'employee') {
          calculatedRates = calculateDisabilityAgeRates(groupMembers, item);
        }

        const payload = {
          ...item,
          carrier_name: filteredIssuers?.find((issuer) => issuer?.ids[0] === item?.issuer_id)?.name,
          calculation: calculatedRates,
        };

        updatedQuotes.push(payload);
      }

      const quotes =
        type === 'all' ? updatedQuotes : updatedQuotes.filter((item) => item.status !== 'selected');

      if (quotes.length) {
        selectedDisabilityQuotes = {
          ...selectedDisabilityQuotes,
          [quote.id]:
            type === 'all'
              ? updatedQuotes
              : updatedQuotes.filter((item) => item.status !== 'selected'),
        };
      }
    }

    for (const quote of accidentQuotes) {
      const response = await api.planConfiguration.getMedicalQuotes(
        accountId,
        proposalId,
        'accident',
        quote.id,
      );

      let updatedQuotes = [];

      for (const item of [{ ...quote, issuer_name: 'broker' }, ...response]) {
        let calculatedRates = {};

        if (item?.rate_tier_type?.includes('tier')) {
          calculatedRates = calculateConfigurationRates(groupMembers, item, {});
        }

        if (!item?.rate_tier_type?.includes('tier')) {
          calculatedRates = calculateConfigurationAgeRates(groupMembers, item);
        }

        const payload = {
          ...item,
          carrier_name: filteredIssuers?.find((issuer) => issuer?.ids[0] === item?.issuer_id)?.name,
          calculation: calculatedRates,
        };

        updatedQuotes.push(payload);
      }

      const quotes =
        type === 'all' ? updatedQuotes : updatedQuotes.filter((item) => item.status !== 'selected');

      if (quotes.length) {
        selectedAccidentQuotes = {
          ...selectedAccidentQuotes,
          [quote.id]:
            type === 'all'
              ? updatedQuotes
              : updatedQuotes.filter((item) => item.status !== 'selected'),
        };
      }
    }

    for (const quote of hospitalQuotes) {
      const response = await api.planConfiguration.getMedicalQuotes(
        accountId,
        proposalId,
        'hospital',
        quote.id,
      );

      let updatedQuotes = [];

      for (const item of [{ ...quote, issuer_name: 'broker' }, ...response]) {
        let calculatedRates = {};

        if (item?.rate_tier_type?.includes('tier')) {
          calculatedRates = calculateConfigurationRates(groupMembers, item, {});
        }

        if (!item?.rate_tier_type?.includes('tier')) {
          calculatedRates = calculateConfigurationAgeRates(groupMembers, item);
        }

        const payload = {
          ...item,
          carrier_name: filteredIssuers?.find((issuer) => issuer?.ids[0] === item?.issuer_id)?.name,
          calculation: calculatedRates,
        };

        updatedQuotes.push(payload);
      }

      const quotes =
        type === 'all' ? updatedQuotes : updatedQuotes.filter((item) => item.status !== 'selected');

      if (quotes.length) {
        selectedHospitalQuotes = {
          ...selectedHospitalQuotes,
          [quote.id]:
            type === 'all'
              ? updatedQuotes
              : updatedQuotes.filter((item) => item.status !== 'selected'),
        };
      }
    }

    for (const quote of criticalQuotes) {
      const response = await api.planConfiguration.getMedicalQuotes(
        accountId,
        proposalId,
        'critical_illness',
        quote.id,
      );

      let updatedQuotes = [];

      for (const item of [{ ...quote, issuer_name: 'broker' }, ...response]) {
        let calculatedRates = {};

        if (item?.rate_tier_type?.includes('tier')) {
          calculatedRates = calculateConfigurationRates(groupMembers, item, {});
        }

        if (!item?.rate_tier_type?.includes('tier')) {
          calculatedRates = calculateConfigurationAgeRates(groupMembers, item);
        }

        const payload = {
          ...item,
          carrier_name: filteredIssuers?.find((issuer) => issuer?.ids[0] === item?.issuer_id)?.name,
          calculation: calculatedRates,
        };

        updatedQuotes.push(payload);
      }

      const quotes =
        type === 'all' ? updatedQuotes : updatedQuotes.filter((item) => item.status !== 'selected');

      if (quotes.length) {
        selectedCriticalQuotes = {
          ...selectedCriticalQuotes,
          [quote.id]:
            type === 'all'
              ? updatedQuotes
              : updatedQuotes.filter((item) => item.status !== 'selected'),
        };
      }
    }

    let payload = {
      medical: selectedMedicalQuotes,
      dental: selectedDentalQuotes,
      vision: selectedVisionQuotes,
      life: selectedLifeQuotes,
      disability: selectedDisabilityQuotes,
      accident: selectedAccidentQuotes,
      hospital: selectedHospitalQuotes,
      critical: selectedCriticalQuotes,
      decision: decisionResponse?.payload?.quotes,
    };

    return payload;
  } catch (err) {
    console.error(err);
  }
});

export const getBrokerRequestedProposals = createAsyncThunk(
  'broker/get-broker-requested-proposals',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId } = data;

      let selectedMedicalPlans = {};
      let selectedDentalPlans = {};
      let selectedVisionPlans = {};
      let selectedLifePlans = {};
      let selectedDisabilityPlans = {};
      let selectedAccidentPlans = {};
      let selectedHospitalPlans = {};
      let selectedCriticalPlans = {};

      const groupMembers = thunkApi.getState().groupMembers.groupMembers;

      const medicalPlans = await api.planConfiguration.getMedicalPlans(accountId, proposalId);
      const dentalPlans = await api.planConfiguration.getDentalPlans(accountId, proposalId);
      const visionPlans = await api.planConfiguration.getVisionPlans(accountId, proposalId);
      const lifePlans = await api.planConfiguration.getLifePlans(accountId, proposalId);
      const disabilityPlans = await api.planConfiguration.getDisabilityPlans(accountId, proposalId);
      const accidentPlans = await api.accidentConfiguration.getAccidentPlans(accountId, proposalId);
      const hospitalPlans = await api.hospitalConfiguration.getHospitalPlans(accountId, proposalId);
      const criticalPlans = await api.criticalConfiguration.getCriticalPlans(accountId, proposalId);

      for (const item of medicalPlans) {
        let calculatedRates = {};

        if (item?.rate_tier_type?.includes('tier')) {
          calculatedRates = calculateMedicalRates(groupMembers, item, {});
        }

        if (!item?.rate_tier_type?.includes('tier')) {
          calculatedRates = calculateMedicalAgeRates(groupMembers, item);
        }

        selectedMedicalPlans = {
          ...selectedMedicalPlans,
          [uuid()]: [
            {
              ...item,
              issuer_name: 'Broker',
              calculation: calculatedRates,
            },
          ],
        };
      }

      for (const item of dentalPlans) {
        const calculatedRates = calculateDentalVisionRates(
          groupMembers,
          item,
          {},
          item?.selected_plan_name,
          'dental',
        );

        selectedDentalPlans = {
          ...selectedDentalPlans,
          [uuid()]: [
            {
              ...item,
              issuer_name: 'Broker',
              calculation: calculatedRates,
            },
          ],
        };
      }

      for (const item of visionPlans) {
        const calculatedRates = calculateDentalVisionRates(
          groupMembers,
          item,
          {},
          item?.selected_plan_name,
          'vision',
        );

        selectedVisionPlans = {
          ...selectedVisionPlans,
          [uuid()]: [
            {
              ...item,
              issuer_name: 'Broker',
              calculation: calculatedRates,
            },
          ],
        };
      }

      for (const item of lifePlans) {
        let calculatedRates = {};

        if (item?.rate_tier_type === '4tier') {
          if (item?.is_uni_tobacco) {
            calculatedRates = calculateUniLifeRates(groupMembers, item);
          }

          if (!item?.is_uni_tobacco) {
            calculatedRates = calculateLifeRates(groupMembers, item);
          }
        }

        if (item?.rate_tier_type !== '4tier') {
          calculatedRates = calculateLifeConfigurationAgeRates(groupMembers, item);
        }

        selectedLifePlans = {
          ...selectedLifePlans,
          [uuid()]: [
            {
              ...item,
              issuer_name: 'Broker',
              calculation: calculatedRates,
            },
          ],
        };
      }

      for (const item of disabilityPlans) {
        let calculatedRates = {};

        if (item?.rate_tier_type === 'employee') {
          calculatedRates = calculateDisabilityRates(groupMembers, item);
        }

        if (item?.rate_tier_type !== 'employee') {
          calculatedRates = calculateDisabilityAgeRates(groupMembers, item);
        }

        selectedDisabilityPlans = {
          ...selectedDisabilityPlans,
          [uuid()]: [
            {
              ...item,
              issuer_name: 'Broker',
              calculation: calculatedRates,
            },
          ],
        };
      }

      for (const item of accidentPlans) {
        let calculatedRates = {};

        if (item?.rate_tier_type?.includes('tier')) {
          calculatedRates = calculateConfigurationRates(groupMembers, item, {});
        }

        if (!item?.rate_tier_type?.includes('tier')) {
          calculatedRates = calculateConfigurationAgeRates(groupMembers, item);
        }

        selectedAccidentPlans = {
          ...selectedAccidentPlans,
          [uuid()]: [
            {
              ...item,
              issuer_name: 'Broker',
              calculation: calculatedRates,
            },
          ],
        };
      }

      for (const item of hospitalPlans) {
        let calculatedRates = {};

        if (item?.rate_tier_type?.includes('tier')) {
          calculatedRates = calculateConfigurationRates(groupMembers, item, {});
        }

        if (!item?.rate_tier_type?.includes('tier')) {
          calculatedRates = calculateConfigurationAgeRates(groupMembers, item);
        }

        selectedHospitalPlans = {
          ...selectedHospitalPlans,
          [uuid()]: [
            {
              ...item,
              issuer_name: 'Broker',
              calculation: calculatedRates,
            },
          ],
        };
      }

      for (const item of criticalPlans) {
        let calculatedRates = {};

        if (item?.rate_tier_type?.includes('tier')) {
          calculatedRates = calculateConfigurationRates(groupMembers, item, {});
        }

        if (!item?.rate_tier_type?.includes('tier')) {
          calculatedRates = calculateConfigurationAgeRates(groupMembers, item);
        }

        selectedCriticalPlans = {
          ...selectedCriticalPlans,
          [uuid()]: [
            {
              ...item,
              issuer_name: 'Broker',
              calculation: calculatedRates,
            },
          ],
        };
      }

      let payload = {
        medical: selectedMedicalPlans,
        dental: selectedDentalPlans,
        vision: selectedVisionPlans,
        life: selectedLifePlans,
        disability: selectedDisabilityPlans,
        accident: selectedAccidentPlans,
        hospital: selectedHospitalPlans,
        critical: selectedCriticalPlans,
      };

      return payload;
    } catch (err) {
      console.error(err);
    }
  },
);
