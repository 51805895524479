import React, { useEffect } from 'react';

import { BenefitsQuotingImage, ProposalManagementImage } from 'src/assets/pages';
import CheckedPurpleCircle from 'src/assets/app/checkedPurpleCircle.svg';
import classes from './benefitsQuoting.module.scss';

const SectionTitle = (props) => {
  const { title, subTitle } = props;

  return (
    <div className={classes.Section}>
      <div className={classes.SectionSubTitle}>{subTitle}</div>
      <div className={classes.SectionTitle}>{title}</div>
    </div>
  );
};

const SectionItem = (props) => {
  const { title } = props;

  return (
    <span className={classes.SectionItem}>
      <img src={CheckedPurpleCircle} alt="CheckedPurpleCircle" />
      {title}
    </span>
  );
};

export const BenefitsQuoting = () => {
  useEffect(() => {
    document.title = 'Monark | Benefits Quoting';
  }, []);

  return (
    <div className={classes.Layout} id="benefits_quoting">
      <div className={classes.BenefitsQuoting}>
        <div className={classes.BenefitsQuotingWrapper}>
          <div className={classes.BenefitsQuotingSection}>
            <div className={classes.BenefitsQuotingSectionContent}>
              <SectionTitle title="Enterprise Grade Quoting Platform" subTitle="quoting" />
              <p>
                Monark is the new benchmark in employee benefits quoting systems. Everyone can
                access the internet so why are proposals still sent as PDF's or excel files to be
                printed for meetings? (If you really love Excel, you can still export from Monark)
              </p>
              <SectionItem title="Easily upload and update cencus files" />
              <SectionItem title="Map employees to existing or new coverage options" />
              <SectionItem title="Compare coverage options side by side" />
              <SectionItem title="Model employer contributions" />
              <SectionItem title="Instantly share live proposals with clients" />
              <SectionItem title="Update in seconds and your client will already have the most current version" />
              <SectionItem title="Have your clients click to confirm the desired quote and send the employer master information to the carrier instantly to begin implementation" />
            </div>
            <img
              src={BenefitsQuotingImage}
              alt="BenefitsQuotingImage"
              className={classes.BenefitsQuotingSectionImage}
            />
          </div>
          <div className={classes.BenefitsQuotingSection}>
            <img
              src={ProposalManagementImage}
              alt="ProposalManagementImage"
              className={classes.BenefitsQuotingSectionImage}
            />
            <div className={classes.BenefitsQuotingSectionContent}>
              <SectionTitle title="Proposal Management" subTitle="proposal" />
              <p>
                With MonarkHQ you can share live updates to client quotes and proposals. Simply add
                the census information and click which plans you want to quote, map employer
                contribution as % or defined $, fixed or base plan modeling and then enter your
                clients email address to securely and instantly share the proposal. Want to make an
                edit as they are looking at it? No problem, simply update the proposal and hit save,
                as they refresh their page they will see the new options. All your clients,
                proposals and quotes are saved, searchable and can instantly be edited.
              </p>
              <p>
                The best part? When your client decides on a quote, they can select it and instantly
                notify you to get things going!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
