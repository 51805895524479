import { normalizeRateValue } from './lifeRates';
import { calculateHousehold3TierHousehold, calculateHousehold4TierHousehold } from './household';

export const calculateDentalVisionRates = (
  accountMembers,
  selectedOffer,
  householdRoles,
  selectedPlanName,
  coverageType,
) => {
  // collect household roles

  const normalizeCoverageType = () => {
    if (coverageType === 'dental') {
      return 'dental_plan_name';
    }

    if (coverageType === 'vision') {
      return 'vision_plan_name';
    }
  };

  const householdFamilies = accountMembers.reduce((previousValue, currentValue) => {
    if (typeof previousValue[currentValue.family_id] === 'undefined') {
      previousValue[currentValue.family_id] = [];
    }

    previousValue[currentValue.family_id].push(currentValue);

    return previousValue;
  }, {});

  let household3TierTypes = {};
  let household4TierTypes = {};

  if (selectedOffer?.rate_tier_type === '3tier') {
    household3TierTypes = calculateHousehold3TierHousehold(
      householdFamilies,
      selectedOffer,
      normalizeCoverageType(),
    );

    household3TierTypes.total = 0;
    household3TierTypes.totalEmployee = 0;
    household3TierTypes.totalEmployer = 0;
    for (const family_id in household3TierTypes) {
      if (!family_id?.includes('total')) {
        for (const member of household3TierTypes[family_id]) {
          household3TierTypes.total += member?.rate_employee || 0;
          household3TierTypes.total += member?.rate_employer || 0;

          household3TierTypes.totalEmployee += member?.rate_employee || 0;
          household3TierTypes.totalEmployer += member?.rate_employer || 0;
        }
      }
    }

    household3TierTypes.total = normalizeRateValue(household3TierTypes.total);
    household3TierTypes.totalEmployee = normalizeRateValue(household3TierTypes.totalEmployee);
    household3TierTypes.totalEmployer = normalizeRateValue(household3TierTypes.totalEmployer);

    return household3TierTypes;
  }

  if (selectedOffer?.rate_tier_type === '4tier') {
    household4TierTypes = calculateHousehold4TierHousehold(
      householdFamilies,
      selectedOffer,
      normalizeCoverageType(),
    );

    household4TierTypes.total = 0;
    household4TierTypes.totalEmployee = 0;
    household4TierTypes.totalEmployer = 0;
    for (const family_id in household4TierTypes) {
      if (!family_id?.includes('total')) {
        for (const member of household4TierTypes[family_id]) {
          household4TierTypes.total += member?.rate_employee || 0;
          household4TierTypes.total += member?.rate_employer || 0;

          household4TierTypes.totalEmployee += member?.rate_employee || 0;
          household4TierTypes.totalEmployer += member?.rate_employer || 0;
        }
      }
    }

    household4TierTypes.total = normalizeRateValue(household4TierTypes.total);
    household4TierTypes.totalEmployee = normalizeRateValue(household4TierTypes.totalEmployee);
    household4TierTypes.totalEmployer = normalizeRateValue(household4TierTypes.totalEmployer);

    return household4TierTypes;
  }
};
