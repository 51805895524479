import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { updatedStateCodes } from 'src/constants/stateCodes';
import { emailRegex, phoneE164Regex } from 'src/constants/regularExpression';
import { ERROR } from 'src/constants/errorNames';

import { auth } from 'src/app/database';
import {
  registerWithEmailAndPassword,
  getRegisterError,
  getIsLoadingRegister,
  getCityByZipCode,
  getCityStateData,
  setCityStateData,
  getUserTypeSelector,
  getIssuerEmailDomain,
  issuerEmailDomainSelector,
  setIssuerEmailDomain,
} from 'src/store/app';
import { getIssuerDataSelector } from 'src/store/issuer';

import { Button, ErrorBox, PhoneInput, InputV2, SelectV2 } from 'src/components';

import { ROUTE } from 'src/constants/routes';
import { emailDomains } from 'src/constants/emailDomains';

import MonarkLogo from 'src/assets/header/monarkLogo.svg';
import Checked from 'src/assets/app/checked.svg';

import classes from './signup.module.scss';

const options = [
  { option: 'broker', label: 'Broker Login' },
  { option: 'carrier', label: 'Carrier Login' },
];

export const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const isIssuerSignup = [ROUTE.ISSUER_SIGNUP].includes(pathname);
  const issuerId = searchParams.get('issuerId');
  const continueUrl = searchParams.get('continueUrl');
  const invite = searchParams.get('invite');

  const isLoading = useSelector(getIsLoadingRegister);
  const registerError = useSelector(getRegisterError);
  const cityStateData = useSelector(getCityStateData);

  const issuerData = useSelector(getIssuerDataSelector);
  const userType = useSelector(getUserTypeSelector);
  const issuerEmailDomain = useSelector(issuerEmailDomainSelector);

  const isCityStateData = !_.isEmpty(cityStateData);

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [state, setState] = useState(cityStateData?.state ?? '');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [carrierName, setCarrierName] = useState('');

  const [signupType, setSignupType] = useState('broker');

  useEffect(() => {
    if (!isIssuerSignup) {
      setSignupType('broker');
    }

    if (isIssuerSignup) {
      setSignupType('carrier');
    }
  }, [isIssuerSignup]);

  useEffect(() => {
    if (!_.isEmpty(user)) {
      if (userType === 'broker' && user.emailVerified) {
        navigate(invite ? `/broker/accounts?invite=${invite}` : ROUTE.BROKER);
      }

      if (userType === 'rep' && user.emailVerified) {
        navigate(`/issuer/${issuerData?.issuer_id}/rfps`);
      }
    }
  }, [invite, issuerData, navigate, user, userType]);

  useEffect(() => {
    if (!email) {
      dispatch(setIssuerEmailDomain({}));
    }
  }, [dispatch, email]);

  useEffect(() => {
    if (!isIssuerSignup) {
      if (!_.isEmpty(issuerEmailDomain)) {
        navigate(ROUTE.ISSUER_SIGNUP);
        setEmail(email);
        setSignupType('carrier');
      }
    }
  }, [navigate, email, isIssuerSignup, issuerEmailDomain]);

  useEffect(() => {
    if (email.match(emailRegex)) {
      const payload = {
        email_domain: email.split('@')[1],
      };

      const debounceSearch = setTimeout(() => {
        dispatch(getIssuerEmailDomain(payload));
      }, 500);

      return () => clearTimeout(debounceSearch);
    }
  }, [dispatch, isIssuerSignup, email]);

  useEffect(() => {
    if (!_.isEmpty(issuerEmailDomain)) {
      setCarrierName(issuerEmailDomain?.name);
    }
    if (_.isEmpty(issuerEmailDomain)) {
      setCarrierName('');
    }
  }, [issuerEmailDomain]);

  useEffect(() => {
    if (!zipCode) {
      dispatch(setCityStateData({}));
    }
  }, [dispatch, zipCode]);

  useEffect(() => {
    if (isCityStateData) {
      setCity('');
      setState('');
    }
  }, [isCityStateData]);

  useEffect(() => {
    if (isCityStateData) {
      setCity(cityStateData?.primary_city ?? '');
      setState(cityStateData?.state ?? '');
    }
  }, [isCityStateData, cityStateData]);

  useEffect(() => {
    if (zipCode) {
      const debounceSearch = setTimeout(() => {
        dispatch(getCityByZipCode(zipCode));
      }, 500);

      return () => clearTimeout(debounceSearch);
    }
  }, [dispatch, zipCode]);

  const onChangeEmail = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  const onChangePassword = useCallback((event) => {
    setPassword(event.target.value);
  }, []);

  const onChangeName = useCallback((event) => {
    setFullName(event.target.value);
  }, []);

  const onChangeAddress = useCallback((event) => {
    setAddress(event.target.value);
  }, []);

  const onChangeCity = useCallback((event) => {
    setCity(event.target.value);
  }, []);

  const onChangeZipCode = useCallback((event) => {
    setZipCode(event.target.value);
  }, []);

  const onChangePhoneNumber = useCallback((val1, val2) => {
    setPhoneNumber(`${val1} ${val2}`);
  }, []);

  const onChangeCarrierName = useCallback((event) => {
    setCarrierName(event.target.value);
  }, []);

  const onSelectOption = useCallback(
    (value) => {
      if (value === 'broker') {
        setEmail('');
        setPassword('');
        setCarrierName('');
        navigate(ROUTE.SIGNUP);
        dispatch(setIssuerEmailDomain({}));
      }

      if (value === 'carrier') {
        if (issuerId) {
          navigate(`${ROUTE.ISSUER_SIGNUP}?continueUrl=${continueUrl}`);
        }
        if (!issuerId) {
          navigate(ROUTE.ISSUER_SIGNUP);
        }
      }

      setCity('');
      setState('');
      setZipCode('');
      setSignupType(value);
    },
    [dispatch, continueUrl, issuerId, navigate],
  );

  const onSelectState = useCallback((value) => {
    setState(value);
  }, []);

  const onClickSignup = useCallback(() => {
    if (isIssuerSignup) {
      navigate(`${ROUTE.ISSUER_LOGIN}?continueUrl=${continueUrl}`);
    }

    if (!isIssuerSignup) {
      navigate(`${ROUTE.LOGIN}${invite ? `?invite=${invite}` : ''}`);
    }
  }, [navigate, isIssuerSignup, continueUrl, invite]);

  const onSubmitSignup = useCallback(
    (event) => {
      event.preventDefault();

      if (!phoneNumber.match(phoneE164Regex)) {
        return toast(ERROR.INVALID_PHONE_NUMBER, {
          type: 'warning',
        });
      }

      if (!isIssuerSignup) {
        const payload = {
          displayName: fullName,
          email,
          password,
          address_street: address,
          address_state: state,
          address_city: city,
          phone_number: `+${phoneNumber}`,
          address_zip_code: zipCode,
          navigation: navigate,
          continueUrl: `/broker/accounts${invite ? `?invite=${invite}` : ''}`,
          isIssuerSignup,
          invite_id: invite,
        };

        if (isCityStateData) {
          return dispatch(registerWithEmailAndPassword(payload));
        }

        if (!isCityStateData) {
          toast(ERROR.ZIP_CODE_ERROR, { type: 'error' });
        }
      }

      if (isIssuerSignup) {
        const emailDomain = email.split('@')[1];
        const isNewIssuer = _.isEmpty(issuerEmailDomain);

        if (emailDomains.includes(emailDomain)) {
          return toast('Please use your work email', { type: 'warning' });
        }

        const payload = {
          displayName: fullName,
          email: email,
          password,
          phone_number: `+${phoneNumber}`,
          navigation: navigate,
          isNewIssuer,
          isIssuerSignup,
          continueUrl,
          issuerId: issuerEmailDomain?.ids[0] ? issuerEmailDomain?.ids[0] : issuerId,
          carrierName,
        };

        dispatch(registerWithEmailAndPassword(payload));
      }
    },
    [
      navigate,
      dispatch,
      phoneNumber,
      isIssuerSignup,
      fullName,
      email,
      password,
      address,
      state,
      city,
      zipCode,
      continueUrl,
      issuerId,
      invite,
      isCityStateData,
      carrierName,
      issuerEmailDomain,
    ],
  );

  const renderPasswordInput = useCallback(
    () => (
      <InputV2
        label="Password"
        value={password}
        type="password"
        onChange={onChangePassword}
        required
      />
    ),
    [onChangePassword, password],
  );

  const onClickLogo = useCallback(() => {
    navigate(ROUTE.DEFAULT);
  }, [navigate]);

  return (
    <div className={classes.Layout}>
      <div className={classes.LayoutEllipseLeft} />
      <div className={classes.LayoutEllipseRight} />
      <div className={classes.LayoutLightLine} />
      <div className={classes.Signup}>
        <div className={classes.SignupCard}>
          <div className={classes.SignupLogo}>
            <div className={classes.SignupLogoWrapper}>
              <img src={MonarkLogo} alt="signupMonarkLogo" onClick={onClickLogo} />
              {isIssuerSignup && <div className={classes.SignupLogoTitle}>Carrier</div>}
            </div>
          </div>
          <SelectV2
            label="Signup Type"
            options={options}
            selectedOption={signupType}
            onOptionClick={onSelectOption}
          />
          <form onSubmit={onSubmitSignup}>
            {registerError && <ErrorBox message={registerError} />}

            <div className={classes.SignupFormWrapper}>
              <div className={classes.SignupFormSection}>
                <InputV2
                  label={isIssuerSignup ? 'Work Email' : 'Email'}
                  value={email}
                  type="email"
                  onChange={onChangeEmail}
                  required
                />
                {!isIssuerSignup && renderPasswordInput()}

                <InputV2
                  label="Full Name"
                  value={fullName}
                  type="text"
                  onChange={onChangeName}
                  required
                />

                {isCityStateData && (
                  <InputV2 label="City" value={city} type="text" onChange={onChangeCity} required />
                )}
              </div>

              <div className={classes.SignupFormSection}>
                {!isIssuerSignup && (
                  <InputV2
                    label="Address"
                    value={address}
                    type="text"
                    onChange={onChangeAddress}
                    required
                  />
                )}
                {isIssuerSignup && renderPasswordInput()}
                {!isIssuerSignup && (
                  <InputV2
                    label="Zip Code"
                    value={zipCode}
                    type="text"
                    onChange={onChangeZipCode}
                    required
                  />
                )}
                <PhoneInput label="Phone Number" onChange={onChangePhoneNumber} />
                {isCityStateData && (
                  <SelectV2
                    type="search"
                    label="States"
                    options={updatedStateCodes}
                    selectedOption={state}
                    onOptionClick={onSelectState}
                  />
                )}
              </div>
            </div>
            {isIssuerSignup && (
              <InputV2
                label="Carrier Name"
                value={carrierName}
                type="text"
                onChange={onChangeCarrierName}
                disabled={!_.isEmpty(issuerEmailDomain)}
                required
              />
            )}

            <div className={classes.SignupAgreeTerms}>
              <label>
                <div className={classes.SignupAgreeTermsCheckbox}>
                  <input type="checkbox" required />
                  <img src={Checked} alt="Checked" />
                </div>
                I agree to the
                <Link to={ROUTE.TERMS} target="_blank">
                  Monark HQ terms & conditions
                </Link>
              </label>
            </div>
            <Button
              type="primaryPurple"
              size="m"
              title="Continue"
              buttonType="submit"
              isLoading={isLoading}
            />
          </form>
          <span className={classes.SignupCardFooter}>
            Already have an account? <span onClick={onClickSignup}>Sign In</span>
          </span>
        </div>
      </div>
    </div>
  );
};
