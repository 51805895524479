import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import _ from 'lodash';

import { getAccountData, getBrokerAccountData } from 'src/store/broker';
import { groupMembersSelector } from 'src/store/groupMembers';
import { getAccountProposal, getAccountProposalSelector } from 'src/store/proposals';
import {
  getRequestedProposalsStatus,
  requestedProposalsStatusSelector,
} from 'src/store/planConfiguration';
import { getPetQuotes, petInsuranceDataSelector } from 'src/store/pet';

import { ROUTE } from 'src/constants/routes';

import { MenuSubItem } from '../MenuISubtem/MenuISubtem';
import { MenuItem } from '../MenuItem/MenuItem';

import classes from './brokerMenu.module.scss';
import { decisionSupportDataSelector } from 'src/store/decisionSupport';

export const BrokerMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();

  const accountId = params?.id;
  const proposalId = params?.proposalId;

  const accountData = useSelector(getAccountData);
  const proposalData = useSelector(getAccountProposalSelector);
  const proposalMembers = useSelector(groupMembersSelector);
  const requestedProposalsStatus = useSelector(requestedProposalsStatusSelector);
  const petInsuranceData = useSelector(petInsuranceDataSelector);
  const decisionData = useSelector(decisionSupportDataSelector);
  const medicalInstantQuotes = proposalData?.groups?.length ? proposalData?.groups[0]?.quotes : [];

  const isProposalData = !_.isEmpty(proposalData);
  const isGroupMembers = !_.isEmpty(proposalMembers);

  const activeTab = searchParams.get('activeTab');

  const isActiveProposals = pathname?.includes('accounts') && pathname?.includes('proposals');
  const isActiveAccounts = pathname?.includes('accounts') && !pathname?.includes('proposals');
  const isSettings = pathname?.includes('settings');

  const isProposalMode = !_.isEmpty(accountData) && !_.isEmpty(proposalData);

  const updatedPathname = `${pathname}${search ? search : ''}`;

  useEffect(() => {
    if (_.isEmpty(proposalData) && accountId && proposalId) {
      dispatch(getAccountProposal({ accountId, proposalId }));
      dispatch(getRequestedProposalsStatus({ accountId, proposalId }));
      dispatch(getPetQuotes({ accountId, proposalId }));
    }
  }, [dispatch, proposalData, accountId, proposalId]);

  useEffect(() => {
    if (_.isEmpty(accountData) && accountId && proposalId) {
      dispatch(getBrokerAccountData({ paramsId: accountId }));
    }
  }, [dispatch, accountData, accountId, proposalId]);

  const isVisibleProposalMenu = useCallback(() => {
    switch (updatedPathname) {
      case `/broker/accounts/${accountData?.id}/proposals/new`:
      case `/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}`:
      case `/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/census`:
      case `/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/census/new`:
      case `/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/account-info`:
      case `/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/proposal-setup`:
      case `/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes`:
      case `/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=medical`:
      case `/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=dental`:
      case `/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=vision`:
      case `/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=life`:
      case `/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=disability`:
      case `/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=accident`:
      case `/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=hospital`:
      case `/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=critical_illness`:
      case `/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=pet`:
      case `/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=decision_support`:
      case `/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/finalize`:
        return true;
      default:
        return false;
    }
  }, [updatedPathname, accountData, proposalData]);

  const onClickQuotes = useCallback(() => {
    navigate(`/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes`);
  }, [navigate, accountData?.id, proposalData?.id]);

  const onClickRouteTo = useCallback(
    (tab) => {
      return `/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=${tab}`;
    },
    [accountData?.id, proposalData?.id],
  );

  const onClickSettings = useCallback(() => {
    navigate(`${ROUTE.BROKER_SETTIGNS}?activeTab=profile`);
  }, [navigate]);

  return (
    <div className={classes.BrokerMenu}>
      <MenuItem title="Accounts" routeTo={ROUTE.BROKER} isActive={isActiveAccounts} />
      <MenuItem title="Proposals" routeTo={ROUTE.PROPOSALS} isActive={isActiveProposals} />
      <MenuItem title="Contacts" routeTo={ROUTE.CONTACTS} />
      <MenuItem title="Settings" onClick={onClickSettings} isLink={false} />
      {isSettings && (
        <>
          <MenuSubItem
            title="Profile"
            routeTo="?activeTab=profile"
            isActive={activeTab === 'profile'}
          />
          <MenuSubItem
            title="Licenses"
            routeTo="?activeTab=licenses"
            isActive={activeTab === 'licenses'}
          />
        </>
      )}
      {isVisibleProposalMenu() && (
        <>
          <hr className={classes.BrokerMenuLine} />
          <MenuItem
            title="Account Info"
            routeTo={`/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/account-info`}
            isDisabled={!isProposalData}
          />
          {_.isEmpty(proposalData) && (
            <MenuItem
              title="Proposal Setup"
              routeTo={
                isProposalMode
                  ? `/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/proposal-setup`
                  : `/broker/accounts/${accountData?.id}/proposals/new`
              }
            />
          )}
          <MenuItem
            title="Census"
            routeTo={
              isProposalMode && isGroupMembers
                ? `/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/census`
                : `/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/census/new`
            }
            isDisabled={!isProposalData}
            isWarning={isProposalData && !isGroupMembers}
          />
          <MenuItem
            title="Quotes"
            onClick={onClickQuotes}
            isDisabled={!isGroupMembers}
            routeTo={`/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes`}
            isLink={false}
          />
          <MenuSubItem
            title="Medical"
            routeTo={onClickRouteTo('medical')}
            isActive={activeTab === 'medical'}
            isVisibleStatus={
              requestedProposalsStatus?.medical > 0 || medicalInstantQuotes?.length > 0
            }
            isDisabled={!isGroupMembers}
          />
          <MenuSubItem
            title="Dental"
            routeTo={onClickRouteTo('dental')}
            isActive={activeTab === 'dental'}
            isVisibleStatus={requestedProposalsStatus?.dental > 0}
            isDisabled={!isGroupMembers}
          />
          <MenuSubItem
            title="Vision"
            routeTo={onClickRouteTo('vision')}
            isActive={activeTab === 'vision'}
            isVisibleStatus={requestedProposalsStatus?.vision > 0}
            isDisabled={!isGroupMembers}
          />
          <MenuSubItem
            title="Life"
            routeTo={onClickRouteTo('life')}
            isActive={activeTab === 'life'}
            isVisibleStatus={requestedProposalsStatus?.life > 0}
            isDisabled={!isGroupMembers}
          />
          <MenuSubItem
            title="Disability"
            routeTo={onClickRouteTo('disability')}
            isActive={activeTab === 'disability'}
            isVisibleStatus={requestedProposalsStatus?.disability > 0}
            isDisabled={!isGroupMembers}
          />
          <MenuSubItem
            title="Accident"
            routeTo={onClickRouteTo('accident')}
            isActive={activeTab === 'accident'}
            isVisibleStatus={requestedProposalsStatus?.accident > 0}
            isDisabled={!isGroupMembers}
          />
          <MenuSubItem
            title="Hospital"
            routeTo={onClickRouteTo('hospital')}
            isActive={activeTab === 'hospital'}
            isVisibleStatus={requestedProposalsStatus?.hospital > 0}
            isDisabled={!isGroupMembers}
          />
          <MenuSubItem
            title="Critical Illness"
            routeTo={onClickRouteTo('critical_illness')}
            isActive={activeTab === 'critical_illness'}
            isVisibleStatus={requestedProposalsStatus?.critical_illness > 0}
            isDisabled={!isGroupMembers}
          />
          <MenuSubItem
            title="Pet"
            routeTo={onClickRouteTo('pet')}
            isActive={activeTab === 'pet'}
            isVisibleStatus={petInsuranceData?.quotes?.length > 0}
            isDisabled={!isGroupMembers}
          />
          <MenuSubItem
            title="Decision Support"
            routeTo={onClickRouteTo('decision_support')}
            isActive={activeTab === 'decision_support'}
            isVisibleStatus={decisionData?.quotes?.length > 0}
            isDisabled={!isGroupMembers}
          />
          <MenuItem
            title="Final Proposal"
            routeTo={`/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/finalize`}
            isDisabled={!isGroupMembers}
          />
        </>
      )}
    </div>
  );
};
