import {
  booleanOptions,
  compMethodOptions,
  formattedValue,
  lumpGiOptions,
  spouseChildOptions,
} from 'src/constants/coverage';

export const useCriticalIssuerBuild = () => {
  const build = {
    coverage: 'critical_illness',
    title: 'Critical',
    form_options: {},
    card_options: {
      plan: [
        {
          title: 'Lump Sum Benefit',
          key: 'lump_sum_benefits',
          format: (value) => formattedValue(value),
        },
        {
          title: 'GI Amount',
          key: 'gi_amount',
          format: (value) => formattedValue(value),
        },
      ],
    },
    offer_details: [
      {
        key: 'lump_sum_benefits',
        format: (value) => formattedValue(value),
        options: { render_type: 'select', render_data: lumpGiOptions },
      },
      {
        key: 'gi_amount',
        format: (value) => formattedValue(value),
        options: {
          render_type: 'select',
          render_data: lumpGiOptions,
          type: 'other',
          symbol: '$',
          inputType: 'number',
          minValue: 0,
        },
      },
      {
        key: 'include_cancer',
        format: (value) => (value ? 'Yes' : 'No'),
        options: { render_type: 'select', render_data: booleanOptions },
      },
      {
        key: 'wellness_benefit',
        format: (value) => (value ? 'Yes' : 'No'),
        options: { render_type: 'select', render_data: booleanOptions },
      },
      {
        key: 'recurrence_benefit',
        format: (value) => (value ? 'Yes' : 'No'),
        options: { render_type: 'select', render_data: booleanOptions },
      },
      {
        key: 'spouse_coverage',
        format: (value) => `${value}%`,
        options: {
          render_type: 'select',
          render_data: spouseChildOptions,
          type: 'other',
          symbol: '%',
          symbolPosition: 'end',
          inputType: 'number',
          maxValue: 100,
          minValue: 0,
        },
      },
      {
        key: 'child_coverage',
        format: (value) => `${value}%`,
        options: {
          render_type: 'select',
          render_data: spouseChildOptions,
          type: 'other',
          symbol: '%',
          symbolPosition: 'end',
          inputType: 'number',
          maxValue: 100,
          minValue: 0,
        },
      },
      {
        key: 'comp_method',
        options: { render_type: 'select', render_data: compMethodOptions },
      },
    ],
  };

  return { build };
};
