import { currencyFormatter } from '../currency';

export const normalizeRateValue = (value, digits = 3) => {
  return parseFloat(currencyFormatter(value, digits)?.replaceAll('$', '').replace(/,/g, ''));
};

const calculateHouseholdTobaccoFamilies = (householdFamilies, selectedOffer) => {
  let payload = {};

  const calcTobaccoNonTobacco = (member, empTobacco, empNonTobacco, altTobacco, altNonTobacco) => {
    let tobaccoValue, nonTobaccoValue;
    if (!selectedOffer?.death_benefit?.includes('salary')) {
      if (selectedOffer?.contribution_type === 'employer') {
        tobaccoValue = (selectedOffer.death_benefit / selectedOffer.rate_per_unit) * empTobacco;
        nonTobaccoValue =
          (selectedOffer.death_benefit / selectedOffer.rate_per_unit) * empNonTobacco;
      }
      if (selectedOffer?.contribution_type === 'voluntary') {
        tobaccoValue = (member.life_benefit_amount / selectedOffer.rate_per_unit) * altTobacco;
        nonTobaccoValue =
          (member.life_benefit_amount / selectedOffer.rate_per_unit) * altNonTobacco;
      }
    } else {
      if (selectedOffer.death_benefit === '1xsalary') {
        tobaccoValue = (member.salary_amount / selectedOffer.rate_per_unit) * altTobacco;
        nonTobaccoValue = (member.salary_amount / selectedOffer.rate_per_unit) * altNonTobacco;
      }
      if (selectedOffer.death_benefit === '2xsalary') {
        tobaccoValue = ((member.salary_amount * 2) / selectedOffer.rate_per_unit) * altTobacco;
        nonTobaccoValue =
          ((member.salary_amount * 2) / selectedOffer.rate_per_unit) * altNonTobacco;
      }
    }
    return {
      tobacco: normalizeRateValue(tobaccoValue ?? 0),
      nonTobacco: normalizeRateValue(nonTobaccoValue ?? 0),
    };
  };

  for (const family_id in householdFamilies) {
    const household = householdFamilies[family_id];

    const employee = household?.find((item) => item?.relationship === 'employee');
    const spouse = household?.find(
      (item) => item?.relationship === 'spouse' || item?.relationship === 'life_partner',
    );
    const child = household?.find((item) => item?.relationship === 'child');

    const isTobaccoUsers = household?.find((item) => item?.is_tobacco_user);

    let householdPayload = [];

    if (employee && spouse && child) {
      for (const member of household) {
        if (member?.relationship === 'employee') {
          let memberPayload = { ...member, type: 'Family' };
          const { tobacco, nonTobacco } = calcTobaccoNonTobacco(
            member,
            selectedOffer.rate_ee_tobacco,
            selectedOffer.rate_ee_non_tobacco,
            selectedOffer.rate_fam_tobacco,
            selectedOffer.rate_fam_non_tobacco,
          );
          if (isTobaccoUsers) {
            memberPayload.tobacco = tobacco;
            memberPayload.tobaccoType = 'Tobacco';
          } else {
            memberPayload.nonTobacco = nonTobacco;
            memberPayload.tobaccoType = 'Non-Tobacco';
          }
          householdPayload.push(memberPayload);
        } else {
          householdPayload.push(member);
        }
      }
    } else {
      // Non-full family branch: process each member individually.
      for (const member of household) {
        if (member?.relationship === 'employee') {
          let memberPayload = { ...member, type: 'Employee' };
          // For employee in non-full families, both employer and voluntary/salary use EE rates.
          const { tobacco, nonTobacco } = calcTobaccoNonTobacco(
            member,
            selectedOffer.rate_ee_tobacco,
            selectedOffer.rate_ee_non_tobacco,
            selectedOffer.rate_ee_tobacco,
            selectedOffer.rate_ee_non_tobacco,
          );
          if (isTobaccoUsers) {
            memberPayload.tobacco = tobacco;
            memberPayload.tobaccoType = 'Tobacco';
          } else {
            memberPayload.nonTobacco = nonTobacco;
            memberPayload.tobaccoType = 'Non-Tobacco';
          }
          householdPayload.push(memberPayload);
        }
        if (member?.relationship === 'spouse' || member?.relationship === 'life_partner') {
          let memberPayload = { ...member, type: 'Spouse' };
          // For spouse, employer contributions are 0; voluntary/salary uses spouse rates.
          const { tobacco, nonTobacco } = calcTobaccoNonTobacco(
            member,
            0,
            0,
            selectedOffer.rate_sp_tobacco,
            selectedOffer.rate_sp_non_tobacco,
          );
          if (isTobaccoUsers) {
            memberPayload.tobacco = tobacco;
            memberPayload.tobaccoType = 'Tobacco';
          } else {
            memberPayload.nonTobacco = nonTobacco;
            memberPayload.tobaccoType = 'Non-Tobacco';
          }
          householdPayload.push(memberPayload);
        }
        if (member?.relationship === 'child') {
          let memberPayload = { ...member, type: 'Child' };
          // For child, employer contributions are 0; voluntary/salary uses child rates.
          const { tobacco, nonTobacco } = calcTobaccoNonTobacco(
            member,
            0,
            0,
            selectedOffer.rate_ch_tobacco,
            selectedOffer.rate_ch_non_tobacco,
          );
          if (isTobaccoUsers) {
            memberPayload.tobacco = tobacco;
            memberPayload.tobaccoType = 'Tobacco';
          } else {
            memberPayload.nonTobacco = nonTobacco;
            memberPayload.tobaccoType = 'Non-Tobacco';
          }
          householdPayload.push(memberPayload);
        }
      }
    }
    payload = { ...payload, [family_id]: householdPayload };
  }
  return payload;
};

export const calculateLifeRates = (accountMembers, selectedOffer) => {
  let household4TierTypes = {};

  // compute household rate tier type
  const householdFamilies = accountMembers.reduce((previousValue, currentValue) => {
    if (typeof previousValue[currentValue.family_id] === 'undefined') {
      previousValue[currentValue.family_id] = [];
    }

    previousValue[currentValue.family_id].push(currentValue);

    return previousValue;
  }, {});

  household4TierTypes = calculateHouseholdTobaccoFamilies(householdFamilies, selectedOffer);

  household4TierTypes.totalTobacco = 0;
  for (const family_id in household4TierTypes) {
    if (family_id !== 'totalTobacco') {
      for (const member of household4TierTypes[family_id]) {
        household4TierTypes.totalTobacco += member?.tobacco || 0;
        household4TierTypes.totalTobacco += member?.nonTobacco || 0;
      }
    }
  }

  household4TierTypes.totalTobacco = normalizeRateValue(household4TierTypes.totalTobacco);

  return household4TierTypes;
};

const calculateHouseholdFamilies = (householdFamilies, selectedOffer) => {
  let payload = {};

  for (const family_id in householdFamilies) {
    const household = householdFamilies[family_id];

    const employee = household?.find((item) => item?.relationship === 'employee');
    const spouse = household?.find(
      (item) => item?.relationship === 'spouse' || item?.relationship === 'life_partner',
    );
    const child = household?.find((item) => item?.relationship === 'child');

    let householdPayload = [];

    const calcTobaccoValue = (member, baseRate, altRate) => {
      let rateValue;
      if (!selectedOffer?.death_benefit?.includes('salary')) {
        if (selectedOffer?.contribution_type === 'employer') {
          rateValue = (selectedOffer.death_benefit / selectedOffer.rate_per_unit) * baseRate;
        }
        if (selectedOffer?.contribution_type === 'voluntary') {
          rateValue = (member.life_benefit_amount / selectedOffer.rate_per_unit) * altRate;
        }
      } else {
        if (selectedOffer.death_benefit === '1xsalary') {
          rateValue = (member.salary_amount / selectedOffer.rate_per_unit) * altRate;
        }
        if (selectedOffer.death_benefit === '2xsalary') {
          rateValue = ((member.salary_amount * 2) / selectedOffer.rate_per_unit) * altRate;
        }
      }
      return normalizeRateValue(rateValue ?? 0);
    };

    if (employee && spouse && child) {
      for (const member of household) {
        if (member?.relationship === 'employee') {
          const memberPayload = { ...member, type: 'Family' };
          const baseRate = selectedOffer.rate_ee_uni_tobacco;
          const altRate = selectedOffer.rate_fam_uni_tobacco;
          memberPayload.tobacco = calcTobaccoValue(member, baseRate, altRate);
          householdPayload.push(memberPayload);
        } else {
          householdPayload.push(member);
        }
      }
    } else {
      for (const member of household) {
        if (member?.relationship === 'employee') {
          const memberPayload = { ...member, type: 'Employee' };
          const baseRate = selectedOffer.rate_ee_uni_tobacco;
          const altRate = selectedOffer.rate_ee_uni_tobacco;
          memberPayload.tobacco = calcTobaccoValue(member, baseRate, altRate);
          householdPayload.push(memberPayload);
        } else if (member?.relationship === 'spouse' || member?.relationship === 'life_partner') {
          const memberPayload = { ...member, type: 'Spouse' };
          const baseRate = 0;
          const altRate = selectedOffer.rate_sp_uni_tobacco;
          memberPayload.tobacco = calcTobaccoValue(member, baseRate, altRate);
          householdPayload.push(memberPayload);
        } else if (member?.relationship === 'child') {
          const memberPayload = { ...member, type: 'Child' };
          const baseRate = 0;
          const altRate = selectedOffer.rate_ch_uni_tobacco;
          memberPayload.tobacco = calcTobaccoValue(member, baseRate, altRate);
          householdPayload.push(memberPayload);
        }
      }
    }
    payload = { ...payload, [family_id]: householdPayload };
  }
  return payload;
};

export const calculateUniLifeRates = (accountMembers, selectedOffer) => {
  let household4TierTypes = {};

  // compute household rate tier type
  const householdFamilies = accountMembers.reduce((previousValue, currentValue) => {
    if (typeof previousValue[currentValue.family_id] === 'undefined') {
      previousValue[currentValue.family_id] = [];
    }

    previousValue[currentValue.family_id].push(currentValue);

    return previousValue;
  }, {});

  household4TierTypes = calculateHouseholdFamilies(householdFamilies, selectedOffer);

  household4TierTypes.totalTobacco = 0;
  for (const family_id in household4TierTypes) {
    if (family_id !== 'totalTobacco') {
      for (const member of household4TierTypes[family_id]) {
        household4TierTypes.totalTobacco += member?.tobacco || 0;
      }
    }
  }

  household4TierTypes.totalTobacco = normalizeRateValue(household4TierTypes.totalTobacco);

  return household4TierTypes;
};
