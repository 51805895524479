import { createSlice } from '@reduxjs/toolkit';
import { getAllChatRooms, getChatRoomMessages, getChatRooms, startChat } from './chat.actions';

const initialState = {
  chatRooms: [],
  chatRoomMessages: [],

  isLoadingStartChat: false,
  isLoadingChatRooms: false,
  isLoadingChatRoomMessages: false,
  isLoadingAllChatRooms: false,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    clearChatState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(startChat.pending, (state) => {
      state.isLoadingStartChat = true;
    });
    builder.addCase(startChat.fulfilled, (state) => {
      state.isLoadingStartChat = false;
    });
    builder.addCase(startChat.rejected, (state) => {
      state.isLoadingStartChat = false;
    });

    builder.addCase(getChatRooms.pending, (state) => {
      state.isLoadingChatRooms = true;
    });
    builder.addCase(getChatRooms.fulfilled, (state, action) => {
      state.chatRooms = action.payload;
      state.isLoadingChatRooms = false;
    });
    builder.addCase(getChatRooms.rejected, (state) => {
      state.isLoadingChatRooms = false;
    });

    builder.addCase(getChatRoomMessages.pending, (state) => {
      state.isLoadingChatRoomMessages = true;
    });
    builder.addCase(getChatRoomMessages.fulfilled, (state, action) => {
      state.chatRoomMessages = action.payload;
      state.isLoadingChatRoomMessages = false;
    });
    builder.addCase(getChatRoomMessages.rejected, (state) => {
      state.isLoadingChatRoomMessages = false;
    });

    builder.addCase(getAllChatRooms.pending, (state) => {
      state.isLoadingAllChatRooms = true;
    });
    builder.addCase(getAllChatRooms.fulfilled, (state, action) => {
      state.chatRooms = action.payload;
      state.isLoadingAllChatRooms = false;
    });
    builder.addCase(getAllChatRooms.rejected, (state) => {
      state.isLoadingAllChatRooms = false;
    });
  },
});

export const { clearChatState } = chatSlice.actions;

export default chatSlice.reducer;
