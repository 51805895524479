import _ from 'lodash';

const fiveYearAges = (rate) => {
  // TODO NEED OPTIMIZATION FOR OTHER COVERAGES
  return {
    age_0_17: !_.isEmpty(rate) ? rate['age_0'] : '',
    age_18_24: !_.isEmpty(rate) ? rate['age_18'] : '',
    age_25_29: !_.isEmpty(rate) ? rate['age_25'] : '',
    age_30_34: !_.isEmpty(rate) ? rate['age_30'] : '',
    age_35_39: !_.isEmpty(rate) ? rate['age_35'] : '',
    age_40_44: !_.isEmpty(rate) ? rate['age_40'] : '',
    age_45_49: !_.isEmpty(rate) ? rate['age_45'] : '',
    age_50_54: !_.isEmpty(rate) ? rate['age_50'] : '',
    age_55_59: !_.isEmpty(rate) ? rate['age_55'] : '',
    age_60_64: !_.isEmpty(rate) ? rate['age_60'] : '',
    age_65: !_.isEmpty(rate) ? rate['age_65'] : '',
  };
};

const FIVE_YEAR_NAMES = {
  age_0_17: 'Age 0-17',
  age_18_24: 'Age 18-24',
  age_25_29: 'Age 25-29',
  age_30_34: 'Age 30-34',
  age_35_39: 'Age 35-39',
  age_40_44: 'Age 40-44',
  age_45_49: 'Age 45-49',
  age_50_54: 'Age 50-54',
  age_55_59: 'Age 55-59',
  age_60_64: 'Age 60-64',
  age_65: 'Age 65+',
};

const normalizeMedicalFiveYearAgeRates = (fiveYearAgeRates) => {
  let payload = {
    ...fiveYearAgeRates,
  };

  for (let i = 0; i <= 17; i++) {
    payload = {
      ...payload,
      [`age_${i}`]: fiveYearAgeRates['age_0_17'],
    };
  }
  for (let i = 18; i <= 24; i++) {
    payload = {
      ...payload,
      [`age_${i}`]: fiveYearAgeRates['age_18_24'],
    };
  }
  for (let i = 25; i <= 29; i++) {
    payload = {
      ...payload,
      [`age_${i}`]: fiveYearAgeRates['age_25_29'],
    };
  }
  for (let i = 30; i <= 34; i++) {
    payload = {
      ...payload,
      [`age_${i}`]: fiveYearAgeRates['age_30_34'],
    };
  }
  for (let i = 35; i <= 39; i++) {
    payload = {
      ...payload,
      [`age_${i}`]: fiveYearAgeRates['age_35_39'],
    };
  }
  for (let i = 40; i <= 44; i++) {
    payload = {
      ...payload,
      [`age_${i}`]: fiveYearAgeRates['age_40_44'],
    };
  }
  for (let i = 45; i <= 49; i++) {
    payload = {
      ...payload,
      [`age_${i}`]: fiveYearAgeRates['age_45_49'],
    };
  }
  for (let i = 50; i <= 54; i++) {
    payload = {
      ...payload,
      [`age_${i}`]: fiveYearAgeRates['age_50_54'],
    };
  }
  for (let i = 55; i <= 59; i++) {
    payload = {
      ...payload,
      [`age_${i}`]: fiveYearAgeRates['age_55_59'],
    };
  }
  for (let i = 60; i <= 64; i++) {
    payload = {
      ...payload,
      [`age_${i}`]: fiveYearAgeRates['age_60_64'],
    };
  }
  for (let i = 65; i <= 65; i++) {
    payload = {
      ...payload,
      [`age_${i}`]: fiveYearAgeRates['age_65_69'],
    };
  }

  delete payload['age_0_17'];
  delete payload['age_18_24'];
  delete payload['age_25_29'];
  delete payload['age_30_34'];
  delete payload['age_35_39'];
  delete payload['age_40_44'];
  delete payload['age_45_49'];
  delete payload['age_50_54'];
  delete payload['age_55_59'];
  delete payload['age_60_64'];
  delete payload['age_65_69'];
  delete payload['age_70_74'];
  delete payload['age_75_79'];
  // TODO ONLY FOR TEST
  delete payload['age_80'];

  return payload;
};

export { fiveYearAges, normalizeMedicalFiveYearAgeRates, FIVE_YEAR_NAMES };
