import { Page, Text, View } from '@react-pdf/renderer';

import {
  normalizeCompensationAmount,
  normalizeDeathBenefit,
  normalizeDisabilityRateBasis,
  normalizeRateTierType,
} from 'src/constants/requestProposal';
import { currencyFormatter } from 'src/constants/currency';

import { renderTableRow, renderTableValue } from './renderRow';

import { styles } from '../styles';
import {
  LIFE_NON_TOBACCO_ALL_AGES_NAMES,
  LIFE_UNI_ALL_AGES_NAMES,
  lifeNonTobaccoAllAges,
  lifeTobaccoAllAges,
  lifeUniAllAges,
} from 'src/features/life/lifeAgeBandedRates/lifeAllAges';
import {
  LIFE_NON_TOBACCO_FIVE_YEAR_NAMES,
  LIFE_UNI_FIVE_YEAR_NAMES,
  lifeNonTobaccoFiveYearAges,
  lifeTobaccoFiveYearAges,
  lifeUniFiveYearAges,
} from 'src/features/life/lifeAgeBandedRates/lifeFiveYearAges';
import {
  LIFE_NON_TOBACCO_TEN_YEAR_NAMES,
  LIFE_UNI_TEN_YEAR_NAMES,
  lifeNonTobaccoTenYearAges,
  lifeTobaccoTenYearAges,
  lifeUniTenYearAges,
} from 'src/features/life/lifeAgeBandedRates/lifeTenYearAges';
import { renderWorksheetRow } from './render';

export const renderLifePage = (data, fullname, groupMembers) => {
  let counter = 0;

  const renderTierUniRates = (item) => {
    const { rate_ee_uni_tobacco, rate_sp_uni_tobacco, rate_ch_uni_tobacco, rate_fam_uni_tobacco } =
      item;

    return (
      <>
        <Text style={styles.tobaccoItem}>${rate_ee_uni_tobacco || 0} (Employee)</Text>
        <Text style={styles.tobaccoItem}>${rate_sp_uni_tobacco || 0} (Spouse)</Text>
        <Text style={styles.tobaccoItem}>${rate_ch_uni_tobacco || 0} (Child)</Text>
        <Text style={styles.tobaccoItem}>${rate_fam_uni_tobacco || 0} (Family)</Text>
      </>
    );
  };

  const renderUniTobaccoRates = (rates, UNI_NAMES) => {
    return Object.keys(rates).map((item) => (
      <Text style={styles.tobaccoItem}>
        ${rates[item] || '0'} (${UNI_NAMES[item]})
      </Text>
    ));
  };

  const renderTierTobaccoRates = (item) => {
    const {
      rate_ee_tobacco,
      rate_ee_non_tobacco,
      rate_sp_tobacco,
      rate_sp_non_tobacco,
      rate_ch_tobacco,
      rate_ch_non_tobacco,
      rate_fam_tobacco,
      rate_fam_non_tobacco,
    } = item;

    return (
      <>
        <Text style={styles.tobaccoItem}>
          ${rate_ee_tobacco || 0} / $${rate_ee_non_tobacco || 0} (Employee)
        </Text>
        <Text style={styles.tobaccoItem}>
          ${rate_sp_tobacco || 0} / $${rate_sp_non_tobacco || 0} (Spouse)
        </Text>
        <Text style={styles.tobaccoItem}>
          ${rate_ch_tobacco || 0} / $${rate_ch_non_tobacco || 0} (Child)
        </Text>
        <Text style={styles.tobaccoItem}>
          ${rate_fam_tobacco || 0} / $${rate_fam_non_tobacco || 0} (Family)
        </Text>
      </>
    );
  };

  const renderAgeTobaccoRates = (tobaccoRates, nonTobaccoRates, TOBACCO_NAMES) => {
    return (
      <View style={styles.tobaccoWrapper}>
        <View style={styles.tobaccoItemWrapper}>
          {Object.keys(tobaccoRates).map((item) => (
            <Text style={styles.tobaccoItem}>{`$${tobaccoRates[item] || '0'}`}</Text>
          ))}
        </View>
        <View style={styles.tobaccoItemWrapper}>
          {Object.keys(nonTobaccoRates).map((item) => (
            <Text style={styles.tobaccoItem}>{`$${nonTobaccoRates[item] || '0'} (${TOBACCO_NAMES[
              item
            ]?.replace(' Non-Tobacco', '')})`}</Text>
          ))}
        </View>
      </View>
    );
  };

  return Object.keys(data).map((id) => {
    counter++;

    let updatedQuotes = !fullname ? data[id] : [];

    const member = groupMembers?.find((item) => item.relationship === 'employee');

    if (fullname) {
      for (const item of data[id]) {
        let payload = { ...item };

        let total = 0;

        for (const groupMember of item.calculation[member.family_id]) {
          if (groupMember.tobacco) {
            total += groupMember.tobacco;
          }

          if (groupMember.nonTobacco) {
            total += groupMember.nonTobacco;
          }
        }

        payload.calculation = {
          ...item.calculation,
          total,
        };

        updatedQuotes.push(payload);
      }
    }

    const updatedData = updatedQuotes.reduce((acc, value, index, array) => {
      if (index % 2 === 0) {
        acc.push(array.slice(index, index + 2));
      }
      return acc;
    }, []);

    const brokerFields = updatedData?.map((section) =>
      section.find((item) => item.issuer_name === 'broker'),
    )[0];

    return updatedData?.map((section, index) => {
      const longest_custom_fields = section?.reduce((prev, current) => {
        return current?.custom_fields?.length > prev?.custom_fields?.length ? current : prev;
      });

      return (
        <Page size="A4" style={styles.page} key={index}>
          <Text style={styles.title}>
            Life RFP Quotes {counter} {fullname ? `(${fullname})` : ''}
          </Text>
          <View style={styles.table}>
            {renderTableRow('Carrier', section, 'issuer_name')}
            {renderTableRow('Employer Contribution', section, 'contribution_type')}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Death Benefit</Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(
                  item,
                  index,
                  section,
                  normalizeDeathBenefit(item.death_benefit),
                );
              })}
            </View>
            {renderTableRow('Compensation Method', section, 'comp_method')}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Compensation Amount</Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(item, index, section, normalizeCompensationAmount(item));
              })}
            </View>
            {renderTableRow('$ Per Unit', section, 'rate_per_unit', null, '$')}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Rate Basis</Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(
                  item,
                  index,
                  section,
                  normalizeDisabilityRateBasis(item.rate_basis),
                );
              })}
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Currently Offered Benefit</Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(item, index, section, item.is_current_rate ? 'Yes' : 'No');
              })}
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Custom Fields</Text>
              </View>
            </View>
            {longest_custom_fields?.custom_fields?.map((item, index) => (
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, styles.tableTitle]}>
                    {brokerFields.custom_fields[index]?.title ?? ''}
                  </Text>
                </View>
                {section?.map((item) => (
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableTitle]}>
                      {item.custom_fields[index]?.value ?? ''}
                    </Text>
                  </View>
                ))}
              </View>
            ))}

            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Rate Tier Type</Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(
                  item,
                  index,
                  section,
                  normalizeRateTierType(item.rate_tier_type),
                );
              })}
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}></Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(
                  item,
                  index,
                  section,
                  !item.is_uni_tobacco ? 'Tobacco / Non-tobacco' : 'Uni-Tobacco',
                );
              })}
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}></Text>
              </View>
              {section?.map((item, index) => {
                const isUniRates = item?.rate_tier_type === '4tier' && item?.is_uni_tobacco;
                const isUniAllAges = item?.rate_tier_type === 'all_ages' && item?.is_uni_tobacco;
                const isUniFive = item?.rate_tier_type === 'banded_5' && item?.is_uni_tobacco;
                const isUniTen = item?.rate_tier_type === 'banded_10' && item?.is_uni_tobacco;

                const isTobaccoRates = item?.rate_tier_type === '4tier' && !item?.is_uni_tobacco;
                const isTobaccoAllAges =
                  item?.rate_tier_type === 'all_ages' && !item?.is_uni_tobacco;
                const isTobaccoFive = item?.rate_tier_type === 'banded_5' && !item?.is_uni_tobacco;
                const isTobaccoTen = item?.rate_tier_type === 'banded_10' && !item?.is_uni_tobacco;

                const allAgesRates = lifeUniAllAges(item);
                const fiveBandedRates = lifeUniFiveYearAges(item);
                const tenBandedRates = lifeUniTenYearAges(item);

                const tobaccoAllAgeRates = lifeTobaccoAllAges(item);
                const nonTobaccoAllAgeRates = lifeNonTobaccoAllAges(item);

                const tobaccoFiveBandedRates = lifeTobaccoFiveYearAges(item);
                const nonTobaccoFiveBandedRates = lifeNonTobaccoFiveYearAges(item);

                const tobaccoTenBandedRates = lifeTobaccoTenYearAges(item);
                const nonTobaccoTenBandedRates = lifeNonTobaccoTenYearAges(item);

                return (
                  <View style={styles.tableCol}>
                    {isUniRates && renderTierUniRates(item)}
                    {isUniAllAges && renderUniTobaccoRates(allAgesRates, LIFE_UNI_ALL_AGES_NAMES)}
                    {isUniFive && renderUniTobaccoRates(fiveBandedRates, LIFE_UNI_FIVE_YEAR_NAMES)}
                    {isUniTen && renderUniTobaccoRates(tenBandedRates, LIFE_UNI_TEN_YEAR_NAMES)}

                    {isTobaccoRates && renderTierTobaccoRates(item)}
                    {isTobaccoAllAges &&
                      renderAgeTobaccoRates(
                        tobaccoAllAgeRates,
                        nonTobaccoAllAgeRates,
                        LIFE_NON_TOBACCO_ALL_AGES_NAMES,
                      )}
                    {isTobaccoFive &&
                      renderAgeTobaccoRates(
                        tobaccoFiveBandedRates,
                        nonTobaccoFiveBandedRates,
                        LIFE_NON_TOBACCO_FIVE_YEAR_NAMES,
                      )}
                    {isTobaccoTen &&
                      renderAgeTobaccoRates(
                        tobaccoTenBandedRates,
                        nonTobaccoTenBandedRates,
                        LIFE_NON_TOBACCO_TEN_YEAR_NAMES,
                      )}
                  </View>
                );
              })}
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Total</Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(
                  item,
                  index,
                  section,
                  currencyFormatter(item.calculation.total),
                );
              })}
            </View>
            {fullname &&
              groupMembers?.map((groupMember) => {
                return (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text
                        style={[styles.tableCell, styles.tableTitle]}
                      >{`${groupMember.first_name} ${groupMember.last_name}`}</Text>
                    </View>
                    {section?.map((item) => {
                      return (
                        <View style={styles.tableCol} key={item.id}>
                          {renderWorksheetRow(groupMember, item, 'tobacco' || 'nonTobacco')}
                        </View>
                      );
                    })}
                  </View>
                );
              })}
          </View>
        </Page>
      );
    });
  });
};
