import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import _ from 'lodash';

import { Loader, LoaderWrapper, PageHeader } from 'src/components';

import { calculateExecutiveSummary } from 'src/constants/calculation/calculateExecutiveSummary';
import { currencyFormatter } from 'src/constants/currency';

import { groupMembersSelector } from 'src/store/groupMembers';
import { executiveSummarySelector, isLoadingExecutiveSummarySelector } from 'src/store/proposals';

import classes from './executiveSummary.module.scss';

export const ExecutiveSummary = () => {
  const accountMembers = useSelector(groupMembersSelector);
  const executiveSummary = useSelector(executiveSummarySelector);
  const isLoadingExecutiveSummary = useSelector(isLoadingExecutiveSummarySelector);

  const [calculatedExecutiveSummary, setCalculatedExecutiveSummary] = useState({});
  const [isVisibleContent, setIsVisibleContent] = useState(true);

  useEffect(() => {
    if (!_.isEmpty(executiveSummary)) {
      const payload = calculateExecutiveSummary(executiveSummary, accountMembers);

      setCalculatedExecutiveSummary(payload);
    }
  }, [accountMembers, executiveSummary]);

  const onClickArrowButton = useCallback(() => {
    setIsVisibleContent((prev) => !prev);
  }, []);

  return (
    <div className={classes.ExecutiveSummary}>
      <PageHeader
        title="Executive Summary"
        isVisibleContent={isVisibleContent}
        onClickArrowButton={onClickArrowButton}
      />

      {isVisibleContent && (
        <>
          {isLoadingExecutiveSummary ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : (
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Line of Coverage</TableCell>
                    <TableCell>EE Cost Total</TableCell>
                    <TableCell>ER Cost Total</TableCell>
                    <TableCell>Total Cost</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!_.isEmpty(calculatedExecutiveSummary)
                    ? Object.keys(calculatedExecutiveSummary)?.map((coverage) => (
                        <TableRow key={coverage}>
                          <TableCell
                            className={coverage === 'total' ? classes.ExecutiveSummaryTotal : ''}
                          >
                            {coverage?.replace('_', ' ')}
                          </TableCell>
                          <TableCell
                            className={coverage === 'total' ? classes.ExecutiveSummaryTotal : ''}
                          >
                            {currencyFormatter(calculatedExecutiveSummary[coverage]?.totalEmployee)}
                          </TableCell>
                          <TableCell
                            className={coverage === 'total' ? classes.ExecutiveSummaryTotal : ''}
                          >
                            {currencyFormatter(calculatedExecutiveSummary[coverage]?.totalEmployer)}
                          </TableCell>
                          <TableCell
                            className={coverage === 'total' ? classes.ExecutiveSummaryTotal : ''}
                          >
                            {currencyFormatter(calculatedExecutiveSummary[coverage]?.totalRate)}
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </div>
  );
};
