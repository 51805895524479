import moment from 'moment';
import { normalizeRateValue } from './lifeRates';

const percent = (value) => {
  return value / 100;
};

const calculateEmployeeRate = (householdFamilies, selectedOffer) => {
  const yearWeeks = 52;
  const yearMonths = 12;
  let payload = {};

  const computeStdEarningsRate = (salary) => {
    return (salary * selectedOffer.rate_ee) / (selectedOffer.rate_per_unit * yearMonths);
  };

  const computeStdBenefitRate = (salary) => {
    let weeklyBenefit = (salary * percent(selectedOffer.income_pct)) / yearWeeks;
    const weeklyMax = normalizeRateValue(selectedOffer.std_weekly_max);
    weeklyBenefit = Math.min(weeklyBenefit, weeklyMax);
    return (
      ((weeklyBenefit / selectedOffer.rate_per_unit) * selectedOffer.rate_ee * yearWeeks) /
      yearMonths
    );
  };

  const computeLtdEarningsRate = (salary) => {
    let monthlyBenefit = (salary * percent(selectedOffer.income_pct)) / yearMonths;
    const monthlyMax = selectedOffer.ltd_monthly_max;
    monthlyBenefit = Math.min(monthlyBenefit, monthlyMax);
    return (salary / yearMonths / selectedOffer.rate_per_unit) * selectedOffer.rate_ee;
  };

  const computeLtdBenefitRate = (salary) => {
    let monthlyBenefit = (salary * percent(selectedOffer.income_pct)) / yearMonths;
    const monthlyMax = selectedOffer.ltd_monthly_max;
    monthlyBenefit = Math.min(monthlyBenefit, monthlyMax);
    return (monthlyBenefit / selectedOffer.rate_per_unit) * selectedOffer.rate_ee;
  };

  for (const family_id in householdFamilies) {
    const household = householdFamilies[family_id];
    let householdPayload = [];
    for (const member of household) {
      if (member?.relationship === 'employee') {
        let memberPayload = { ...member, type: 'Employee' };
        const salary = member.salary_amount || 0;
        let monthlyCost = 0;

        if (selectedOffer?.plan_type === 'std') {
          if (selectedOffer?.rate_basis === 'earnings') {
            monthlyCost = computeStdEarningsRate(salary);
          } else if (selectedOffer?.rate_basis === 'benefit') {
            monthlyCost = computeStdBenefitRate(salary);
          }
        } else if (selectedOffer?.plan_type === 'ltd') {
          if (selectedOffer?.rate_basis === 'earnings') {
            monthlyCost = computeLtdEarningsRate(salary);
          } else if (selectedOffer?.rate_basis === 'benefit') {
            monthlyCost = computeLtdBenefitRate(salary);
          }
        }

        memberPayload.rate = normalizeRateValue(monthlyCost, 2);
        householdPayload.push(memberPayload);
      } else {
        householdPayload.push(member);
      }
    }
    payload[family_id] = householdPayload;
  }
  return payload;
};

const calculateEmployeeAgeRates = (householdFamilies, selectedOffer) => {
  const yearWeeks = 52;
  const yearMonths = 12;

  const computeStdEarningsAgeRate = (salary, memberRate) => {
    return (
      ((salary / yearWeeks / selectedOffer.rate_per_unit) * memberRate * yearWeeks) / yearMonths
    );
  };

  const computeStdBenefitAgeRate = (salary, memberRate) => {
    const weeklyMax = normalizeRateValue(selectedOffer.std_weekly_max);
    let weeklyBenefit = (salary * percent(selectedOffer.income_pct)) / yearWeeks;
    weeklyBenefit = Math.min(weeklyBenefit, weeklyMax);
    return ((weeklyBenefit / selectedOffer.rate_per_unit) * memberRate * yearWeeks) / yearMonths;
  };

  const computeLtdEarningsAgeRate = (salary, memberRate) => {
    const monthlyMax = selectedOffer.ltd_monthly_max;
    let monthlyBenefit = (salary * percent(selectedOffer.income_pct)) / yearMonths;
    monthlyBenefit = Math.min(monthlyBenefit, monthlyMax);
    return (salary / yearMonths / selectedOffer.rate_per_unit) * memberRate;
  };

  const computeLtdBenefitAgeRate = (salary, memberRate) => {
    const monthlyMax = selectedOffer.ltd_monthly_max;
    let monthlyBenefit = (salary * percent(selectedOffer.income_pct)) / yearMonths;
    monthlyBenefit = Math.min(monthlyBenefit, monthlyMax);
    return (monthlyBenefit / selectedOffer.rate_per_unit) * memberRate;
  };

  let payload = {};

  for (const family_id in householdFamilies) {
    const household = householdFamilies[family_id];
    let householdPayload = [];

    for (const member of household) {
      const memberAge = moment().diff(moment(member.date_of_birth), 'years');
      const memberRate = selectedOffer[`age_${memberAge}`] ?? 0;

      if (member?.relationship === 'employee') {
        let memberPayload = { ...member, type: 'Employee' };
        const salary = member.salary_amount || 0;
        let monthlyCost = 0;

        if (selectedOffer?.plan_type === 'std') {
          if (selectedOffer?.rate_basis === 'earnings') {
            monthlyCost = computeStdEarningsAgeRate(salary, memberRate);
          } else if (selectedOffer?.rate_basis === 'benefit') {
            monthlyCost = computeStdBenefitAgeRate(salary, memberRate);
          }
        } else if (selectedOffer?.plan_type === 'ltd') {
          if (selectedOffer?.rate_basis === 'earnings') {
            monthlyCost = computeLtdEarningsAgeRate(salary, memberRate);
          } else if (selectedOffer?.rate_basis === 'benefit') {
            monthlyCost = computeLtdBenefitAgeRate(salary, memberRate);
          }
        }

        memberPayload.rate = normalizeRateValue(monthlyCost, 2);
        householdPayload.push(memberPayload);
      } else {
        householdPayload.push(member);
      }
    }
    payload[family_id] = householdPayload;
  }
  return payload;
};

export const calculateDisabilityRates = (accountMembers, selectedOffer) => {
  let householdTierTypes = {};

  // compute household rate tier type
  const householdFamilies = accountMembers.reduce((previousValue, currentValue) => {
    if (typeof previousValue[currentValue.family_id] === 'undefined') {
      previousValue[currentValue.family_id] = [];
    }

    previousValue[currentValue.family_id].push(currentValue);

    return previousValue;
  }, {});

  householdTierTypes = calculateEmployeeRate(householdFamilies, selectedOffer);

  householdTierTypes.total = 0;
  for (const family_id in householdTierTypes) {
    if (family_id !== 'total') {
      for (const member of householdTierTypes[family_id]) {
        householdTierTypes.total += member?.rate || 0;
      }
    }
  }

  householdTierTypes.total = normalizeRateValue(householdTierTypes.total, 2);

  return householdTierTypes;
};

export const calculateDisabilityAgeRates = (accountMembers, selectedOffer) => {
  let householdTierTypes = {};

  // compute household rate tier type
  const householdFamilies = accountMembers.reduce((previousValue, currentValue) => {
    if (typeof previousValue[currentValue.family_id] === 'undefined') {
      previousValue[currentValue.family_id] = [];
    }

    previousValue[currentValue.family_id].push(currentValue);

    return previousValue;
  }, {});

  householdTierTypes = calculateEmployeeAgeRates(householdFamilies, selectedOffer);

  householdTierTypes.total = 0;
  for (const family_id in householdTierTypes) {
    if (family_id !== 'total') {
      for (const member of householdTierTypes[family_id]) {
        householdTierTypes.total += member?.rate || 0;
      }
    }
  }

  householdTierTypes.total = normalizeRateValue(householdTierTypes.total, 2);

  return householdTierTypes;
};
