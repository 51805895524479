import React, { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';

import { getAccountProposalSelector } from 'src/store/proposals';

import {
  renderCoverageAllAges,
  renderCoverageFiveYearAges,
  renderCoverageTenYearAges,
} from '../../../components/renderCoverageRates/renderCoverageRates';
import { renderTierRates } from '../../medical/components/SelectedMedicalOffersTable/renderMedicalRates';
import { coverageAllAges } from 'src/features/proposal/coverageAgeBandedRates/coverageAllAges';
import { coverageFiveYearAges } from 'src/features/proposal/coverageAgeBandedRates/coverageFiveYearAges';
import { coverageTenYearAges } from 'src/features/proposal/coverageAgeBandedRates/coverageTenYearAges';

import { normalizeCompensationAmount, normalizeRateTierType } from 'src/constants/requestProposal';
import { COVERAGE_NAMES } from 'src/features/coverageBuilder/cb_constants';

import { BuilderCASelect, BuilderInput, BuilderSelect } from 'src/features/coverageBuilder';
import { PlanConfigAgeRates } from 'src/features/coverageBuilder/PlanConfig/PlanConfigAgeRates/PlanConfigAgeRates';
import { Button } from 'src/components';
import {
  LIFE_NON_TOBACCO_ALL_AGES_NAMES,
  LIFE_UNI_ALL_AGES_NAMES,
  lifeNonTobaccoAllAges,
  lifeTobaccoAllAges,
  lifeUniAllAges,
} from 'src/features/life/lifeAgeBandedRates/lifeAllAges';
import {
  LIFE_NON_TOBACCO_FIVE_YEAR_NAMES,
  LIFE_UNI_FIVE_YEAR_NAMES,
  lifeNonTobaccoFiveYearAges,
  lifeTobaccoFiveYearAges,
  lifeUniFiveYearAges,
} from 'src/features/life/lifeAgeBandedRates/lifeFiveYearAges';
import {
  LIFE_NON_TOBACCO_TEN_YEAR_NAMES,
  LIFE_UNI_TEN_YEAR_NAMES,
  lifeNonTobaccoTenYearAges,
  lifeTobaccoTenYearAges,
  lifeUniTenYearAges,
} from 'src/features/life/lifeAgeBandedRates/lifeTenYearAges';
import {
  renderTierTobaccoRates,
  renderTierUniRates,
  renderTobaccoRates,
  renderUniTobaccoRates,
} from 'src/components/renderLifeRates/renderLifeRates';
import { DropZone } from '../../../components/DropZone/DropZone';

import classes from 'src/sass/styles/OfferTable/offerTable.module.scss';
import issuerClasses from './issuerOfferForm.module.scss';
import { getIssuerDataSelector } from 'src/store/issuer';
import {
  createIssuerCoverageOffer,
  deleteIssuerCoverageOffer,
  updateIssuerCoverageOffer,
} from 'src/store/issuerCoverageBuilder';
import { toast } from 'react-toastify';
import { brokerAgencySelector } from 'src/store/agency';

const spanStyles = {
  height: '38px',
  width: '100%',
  display: 'flex',
  borderBottom: '1px solid #dbdbdb',
};

const divStyles = {
  width: '100%',
  padding: '15px 10px',
};

export const IssuerOfferForm = memo((props) => {
  const { build, offers, planId, fetchOffers } = props;

  const { offer_details, prepare_payload, form_options, coverage } = build;

  const userType = build?.user_type;

  const { contribution } = form_options;

  const [addedOffers, setAddedOffers] = useState([]);
  const [selectedConfig, setSelectedConfig] = useState({});

  const dispatch = useDispatch();

  const accountProposalData = useSelector(getAccountProposalSelector);
  const issuerData = useSelector(getIssuerDataSelector);

  const params = useParams();

  const accountId = params?.id ? params?.id : accountProposalData?.account_id;
  const proposalId = params?.proposalId ? params?.proposalId : accountProposalData?.id;

  const brokerOffer = offers[0];

  const offerDetails =
    typeof offer_details === 'function' ? offer_details(offers, selectedConfig) : offer_details;

  const prepareAgeRates = (rates) => {
    Object.keys(rates).forEach((key) => {
      if (!key.includes('age')) {
        delete rates[key];
      }
    });

    delete rates.is_age_attained;

    return rates;
  };

  const onClickEditConfig = useCallback(
    (offer) => () => {
      setSelectedConfig(offer);
    },
    [],
  );

  const onClickAdd = useCallback(() => {
    const offer = {
      ...brokerOffer,
      id: uuid(),
      issuer_name: !userType ? issuerData?.issuer_name : offers[1]?.issuer_name,
      config_id: brokerOffer?.id,
      issuer_id: issuerData?.issuer_id,
      user_id: !userType ? issuerData?.id : offers[1]?.user_id,
    };

    setAddedOffers((prev) => [...prev, offer]);
    setSelectedConfig(offer);
  }, [brokerOffer, issuerData, offers, userType]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const overallOffers = [...offers, ...addedOffers];

  const currentOffer = overallOffers?.find((offer) => offer?.issuer_name === 'broker');

  const age_banded_coverages = [
    'medical',
    'life', // (tobacco/non-tobacco)
    'disability',
    'accident',
    'hospital',
    'critical_illness',
  ];

  const updated_longest_custom_fields = useCallback(() => {
    // Ensure we only add selectedConfig if its ID is not already in overallOffers
    const allOffers = !_.isEmpty(selectedConfig)
      ? [...overallOffers.filter((offer) => offer.id !== selectedConfig.id), selectedConfig]
      : overallOffers;

    // Find the offer (including `selectedConfig`) with the longest custom_fields array
    return allOffers?.reduce(
      (prev, current) => {
        return current?.custom_fields?.length > prev?.custom_fields?.length ? current : prev;
      },
      { custom_fields: [] },
    );
  }, [overallOffers, selectedConfig]);

  const longest_custom_fields = updated_longest_custom_fields();

  const onChange = useCallback((event, key) => {
    setSelectedConfig((prev) => ({ ...prev, [key]: event.target.value }));
  }, []);

  const onChangeInput = useCallback((event, target) => {
    setSelectedConfig((prev) => ({
      ...prev,
      [target]: event.target.value,
    }));
  }, []);

  const renderInput = useCallback(
    (props) => {
      const { key, isVisible = true, ...rest } = props;

      return isVisible ? (
        <BuilderInput
          key={key}
          label={COVERAGE_NAMES[key]}
          value={selectedConfig?.[key]}
          onChange={(event) => onChange(event, key)}
          {...rest}
        />
      ) : null;
    },
    [onChange, selectedConfig],
  );

  const renderSelect = useCallback(
    (props) => {
      const { key, render_data, isVisible = true, ...rest } = props;

      return isVisible ? (
        <BuilderSelect
          key={key}
          label={COVERAGE_NAMES[key]}
          value={selectedConfig?.[key]}
          options={render_data}
          onChange={(event) => onChange(event, key)}
          {...rest}
        />
      ) : null;
    },
    [onChange, selectedConfig],
  );

  const renderRateInput = useCallback(
    (target) => {
      return (
        <TextField
          size="small"
          onChange={(event) => onChangeInput(event, target)}
          value={selectedConfig[target]}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          type="number"
          required
        />
      );
    },
    [onChangeInput, selectedConfig],
  );

  const brokerAgency = useSelector(brokerAgencySelector);

  const onAddExtraRow = useCallback(() => {
    if (!_.isEmpty(brokerAgency) && brokerAgency.status !== 'active') {
      if (selectedConfig?.custom_fields?.length >= 3) {
        toast('You can add only 3 rows', { type: 'warning' });
        return;
      }
    }

    if (_.isEmpty(brokerAgency)) {
      if (selectedConfig?.custom_fields?.length >= 3) {
        toast('You can add only 3 rows', { type: 'warning' });
        return;
      }
    }

    const payload = {
      id: uuid(),
      title: '',
      value: '',
    };

    setSelectedConfig((prev) => ({ ...prev, custom_fields: [...prev.custom_fields, payload] }));
  }, [brokerAgency, selectedConfig]);

  const onRejectCustomField = useCallback(
    (id) => () => {
      const payload = _.reject(selectedConfig?.custom_fields, (row) => row.id === id);

      setSelectedConfig((prev) => ({ ...prev, custom_fields: payload }));
    },
    [selectedConfig],
  );

  const onChangeToggle = useCallback((event, key) => {
    setSelectedConfig((prev) => ({
      ...prev,
      [key]: ['is_current_rate', 'is_uni_tobacco'].includes(key)
        ? JSON.parse(event.target.value)
        : event.target.value,
    }));
  }, []);

  const onChangeSelect = useCallback((event, target) => {
    if (target === 'rate_tier_type') {
      if (event.target.value === '3tier') {
        setSelectedConfig((prev) => ({
          ...prev,
          rate_ee: null,
        }));
      }

      if (event.target.value === '4tier') {
        setSelectedConfig((prev) => ({
          ...prev,
          rate_ee_ch: null,
          rate_ee_sp: null,
        }));
      }
    }

    setSelectedConfig((prev) => ({
      ...prev,
      [target]: event.target.value,
    }));
  }, []);

  const renderRateTierType = () => {
    return (
      <>
        <div className={classes.PlanConfigFormTable}>
          {(selectedConfig?.rate_tier_type.includes('tier') ||
            selectedConfig?.rate_tier_type.includes('employee')) && (
            <TableContainer sx={{ maxWidth: '500px' }}>
              <Table
                size="small"
                sx={{ borderCollapse: 'separate !important', borderSpacing: '7px !important' }}
              >
                <TableBody>
                  <>
                    <TableRow className={issuerClasses.clear}>
                      <TableCell>EE</TableCell>
                      {coverage !== 'life' && (
                        <TableCell align="center">{renderRateInput('rate_ee')}</TableCell>
                      )}
                      {coverage === 'life' && (
                        <>
                          <TableCell align="center">
                            {selectedConfig.is_uni_tobacco &&
                              renderRateInput('rate_ee_uni_tobacco')}
                            {!selectedConfig.is_uni_tobacco && renderRateInput('rate_ee_tobacco')}
                          </TableCell>
                          {!selectedConfig.is_uni_tobacco && (
                            <TableCell align="center">
                              {renderRateInput('rate_ee_non_tobacco')}
                            </TableCell>
                          )}
                        </>
                      )}
                    </TableRow>
                    {selectedConfig?.rate_tier_type === '3tier' && (
                      <TableRow className={issuerClasses.clear}>
                        <TableCell>EE + 1</TableCell>
                        <TableCell align="center">{renderRateInput('rate_ee_1')}</TableCell>
                      </TableRow>
                    )}
                    {selectedConfig?.rate_tier_type === '4tier' && (
                      <>
                        <TableRow className={issuerClasses.clear}>
                          <TableCell>{coverage !== 'life' ? 'EE + SP' : 'SP'}</TableCell>
                          {coverage !== 'life' && (
                            <TableCell align="center">{renderRateInput('rate_ee_sp')}</TableCell>
                          )}
                          {coverage === 'life' && (
                            <>
                              <TableCell align="center">
                                {selectedConfig.is_uni_tobacco &&
                                  renderRateInput('rate_sp_uni_tobacco')}
                                {!selectedConfig.is_uni_tobacco &&
                                  renderRateInput('rate_sp_tobacco')}
                              </TableCell>
                              {!selectedConfig.is_uni_tobacco && (
                                <TableCell align="center">
                                  {renderRateInput('rate_sp_non_tobacco')}
                                </TableCell>
                              )}
                            </>
                          )}
                        </TableRow>
                        <TableRow className={issuerClasses.clear}>
                          <TableCell>{coverage !== 'life' ? 'EE + CH' : 'CH'}</TableCell>
                          {coverage !== 'life' && (
                            <TableCell align="center">{renderRateInput('rate_ee_ch')}</TableCell>
                          )}
                          {coverage === 'life' && (
                            <>
                              <TableCell align="center">
                                {selectedConfig.is_uni_tobacco &&
                                  renderRateInput('rate_ch_uni_tobacco')}
                                {!selectedConfig.is_uni_tobacco &&
                                  renderRateInput('rate_ch_tobacco')}
                              </TableCell>
                              {!selectedConfig.is_uni_tobacco && (
                                <TableCell align="center">
                                  {renderRateInput('rate_ch_non_tobacco')}
                                </TableCell>
                              )}
                            </>
                          )}
                        </TableRow>
                      </>
                    )}
                    <TableRow className={issuerClasses.clear}>
                      {!['life', 'disability'].includes(coverage) && (
                        <>
                          <TableCell>FAM</TableCell>
                          <TableCell align="center">{renderRateInput('rate_fam')}</TableCell>
                        </>
                      )}
                      {coverage === 'life' && (
                        <>
                          <TableCell>FAM</TableCell>

                          <TableCell align="center">
                            {selectedConfig.is_uni_tobacco &&
                              renderRateInput('rate_fam_uni_tobacco')}
                            {!selectedConfig.is_uni_tobacco && renderRateInput('rate_fam_tobacco')}
                          </TableCell>
                          {!selectedConfig.is_uni_tobacco && (
                            <TableCell align="center">
                              {renderRateInput('rate_fam_non_tobacco')}
                            </TableCell>
                          )}
                        </>
                      )}
                    </TableRow>
                  </>
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <div style={{ display: 'flex' }}>
            <PlanConfigAgeRates
              build={{
                coverage,
                form_options: { form_data: selectedConfig, setFormData: setSelectedConfig },
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const renderRates = useCallback(() => {
    return (
      <tr className={classes.AgeRatesWrapper} style={{ background: 'white' }}>
        <th></th>
        {overallOffers?.map((offer) => {
          const isEditMode = offer?.id === selectedConfig?.id;

          const rates = _.cloneDeep(offer);

          const ageRates = prepareAgeRates(rates);

          const allAges = coverageAllAges(ageRates);
          const fiveAges = coverageFiveYearAges(ageRates);
          const tenAges = coverageTenYearAges(ageRates);
          const lifeAllAgesRates = lifeUniAllAges(offer);
          const lifeFiveBandedRates = lifeUniFiveYearAges(offer);
          const lifeTenBandedRates = lifeUniTenYearAges(offer);
          const lifeTobaccoAllAgeRates = lifeTobaccoAllAges(offer);
          const lifeNonTobaccoAllAgeRates = lifeNonTobaccoAllAges(offer);
          const lifeTobaccoFiveBandedRates = lifeTobaccoFiveYearAges(offer);
          const lifeNonTobaccoFiveBandedRates = lifeNonTobaccoFiveYearAges(offer);
          const lifeTobaccoTenBandedRates = lifeTobaccoTenYearAges(offer);
          const lifeNonTobaccoTenBandedRates = lifeNonTobaccoTenYearAges(offer);

          const isBrokerUniRates = offer?.rate_tier_type === '4tier' && offer?.is_uni_tobacco;
          const isBrokerUniAllAges = offer?.rate_tier_type === 'all_ages' && offer?.is_uni_tobacco;
          const isBrokerUniFive = offer?.rate_tier_type === 'banded_5' && offer?.is_uni_tobacco;
          const isBrokerUniTen = offer?.rate_tier_type === 'banded_10' && offer?.is_uni_tobacco;

          const isBrokerTierTobacco = offer?.rate_tier_type === '4tier' && !offer?.is_uni_tobacco;
          const isBrokerTobaccoAllAges =
            offer?.rate_tier_type === 'all_ages' && !offer?.is_uni_tobacco;
          const isBrokerTobaccoFive =
            offer?.rate_tier_type === 'banded_5' && !offer?.is_uni_tobacco;
          const isBrokerTobaccoTen =
            offer?.rate_tier_type === 'banded_10' && !offer?.is_uni_tobacco;

          return !isEditMode ? (
            <td style={{ padding: '0 !important' }} className={issuerClasses.clear} key={offer.id}>
              {coverage !== 'life' && (
                <>
                  {(offer?.rate_tier_type === '3tier' || offer?.rate_tier_type === '4tier') &&
                    renderTierRates(offer)}
                  {offer?.rate_tier_type === 'employee' ? (
                    <span key={offer.id} style={spanStyles}>
                      <div style={divStyles}>{offer.rate_ee ? `$${offer.rate_ee}` : '$0'}</div>
                    </span>
                  ) : (
                    ''
                  )}
                  {offer?.rate_tier_type === 'all_ages' && renderCoverageAllAges(allAges)}
                  {offer?.rate_tier_type === 'banded_5' && renderCoverageFiveYearAges(fiveAges)}
                  {offer?.rate_tier_type === 'banded_10' && renderCoverageTenYearAges(tenAges)}
                </>
              )}
              {coverage === 'life' && (
                <>
                  {isBrokerUniRates && renderTierUniRates(offer)}
                  {isBrokerUniAllAges &&
                    renderUniTobaccoRates(lifeAllAgesRates, LIFE_UNI_ALL_AGES_NAMES)}
                  {isBrokerUniFive &&
                    renderUniTobaccoRates(lifeFiveBandedRates, LIFE_UNI_FIVE_YEAR_NAMES)}
                  {isBrokerUniTen &&
                    renderUniTobaccoRates(lifeTenBandedRates, LIFE_UNI_TEN_YEAR_NAMES)}
                  {isBrokerTierTobacco && renderTierTobaccoRates(offer)}
                  {isBrokerTobaccoAllAges &&
                    renderTobaccoRates(
                      lifeTobaccoAllAgeRates,
                      lifeNonTobaccoAllAgeRates,
                      LIFE_NON_TOBACCO_ALL_AGES_NAMES,
                    )}
                  {isBrokerTobaccoFive &&
                    renderTobaccoRates(
                      lifeTobaccoFiveBandedRates,
                      lifeNonTobaccoFiveBandedRates,
                      LIFE_NON_TOBACCO_FIVE_YEAR_NAMES,
                    )}
                  {isBrokerTobaccoTen &&
                    renderTobaccoRates(
                      lifeTobaccoTenBandedRates,
                      lifeNonTobaccoTenBandedRates,
                      LIFE_NON_TOBACCO_TEN_YEAR_NAMES,
                    )}
                </>
              )}
            </td>
          ) : (
            renderRateTierType()
          );
        })}
      </tr>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverage, offers, selectedConfig]);

  const onChangeExtraRow = useCallback(
    (id) => (event) => {
      const row = selectedConfig?.custom_fields?.find((item) => item.id === id);
      row['value'] = event.target.value;

      if (row.value.length > 250) {
        row['value'] = row['value'].slice(0, 250);
        return;
      }

      const payload = selectedConfig?.custom_fields?.reduce((unique, o) => {
        if (!unique.some((obj) => obj.id === o.id && obj['value'] === o['value'])) {
          unique.push(o);
        }
        return unique;
      }, []);

      setSelectedConfig((prev) => ({ ...prev, custom_fields: payload }));
    },
    [selectedConfig],
  );

  const onSubmitForm = useCallback(
    async (event) => {
      event.preventDefault();

      const isAddedOffer = addedOffers?.find((item) => item.id === selectedConfig?.id);

      const options = {
        accountId,
        proposalId,
        coverage,
        configId: selectedConfig?.config_id,
      };

      const preparedConfig = prepare_payload ? prepare_payload(selectedConfig) : selectedConfig;

      if (isAddedOffer) {
        await dispatch(
          createIssuerCoverageOffer({
            options,
            payload: { ...preparedConfig, status: 'completed' },
          }),
        );
      }

      if (!isAddedOffer) {
        await dispatch(
          updateIssuerCoverageOffer({
            options,
            payload: { ...preparedConfig, status: 'completed' },
          }),
        );
      }

      if (userType === 'broker') {
        fetchOffers();
      }
    },
    [
      dispatch,
      prepare_payload,
      addedOffers,
      accountId,
      proposalId,
      coverage,
      selectedConfig,
      userType,
      fetchOffers,
    ],
  );

  const onRejectOffer = useCallback(async () => {
    const offer = offers[0];

    const selectedConfig = offers?.find((item) => item.config_id);

    const options = {
      accountId,
      proposalId,
      coverage,
      configId: selectedConfig?.config_id,
    };

    if (!_.isEmpty(offer)) {
      await dispatch(
        updateIssuerCoverageOffer({ options, payload: { ...offer, status: 'declined' } }),
      );
    }
  }, [dispatch, offers, accountId, proposalId, coverage]);

  const onClickDelete = useCallback(
    (item) => () => {
      const payload = {
        accountId,
        proposalId,
        configId: item.config_id,
        offerId: item.id,
        coverage,
      };

      dispatch(deleteIssuerCoverageOffer(payload));
    },
    [dispatch, accountId, coverage, proposalId],
  );

  const onClickCancel = useCallback(() => {
    const selectedFromOverall = addedOffers?.find((item) => item?.id === selectedConfig?.id);

    if (!selectedFromOverall) {
      setSelectedConfig({});
    }

    if (selectedFromOverall) {
      const updatedOverallOffers = _.reject(addedOffers, (item) => item.id === selectedConfig?.id);

      setAddedOffers(updatedOverallOffers);
      setSelectedConfig({});
    }
  }, [selectedConfig, addedOffers]);

  const isRejectedOffer = offers?.length === 2;

  return (
    <div className={classes.Offer}>
      <div className={classes.OfferTableContent}>
        <div className={issuerClasses.AddOffer}>
          <Button
            title="Add Offer"
            type="primary"
            onClick={onClickAdd}
            isDisabled={!_.isEmpty(selectedConfig)}
          />
        </div>
        <div className={classes.OfferTable}>
          <form onSubmit={onSubmitForm}>
            <table>
              <thead>
                <tr>
                  <th></th>
                  {overallOffers?.map((offer) => (
                    <th key={offer.id}>
                      {offer.issuer_name === 'broker' ? 'Broker Request' : 'Carrier Reply'}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {contribution && (
                  <>
                    <tr>
                      <th>Contribution EE</th>
                      {overallOffers?.map((offer) => (
                        <td key={offer.id}>
                          {offer?.contribution_type === 'dollar' && '$'}
                          {offer?.contribution_ee}
                          {offer?.contribution_type === 'percent' && '%'}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th>Contribution DEP</th>
                      {overallOffers?.map((offer) => (
                        <td key={offer.id}>
                          {offer?.contribution_type === 'dollar' && '$'}
                          {offer?.contribution_dep}
                          {offer?.contribution_type === 'percent' && '%'}
                        </td>
                      ))}
                    </tr>
                  </>
                )}
                {offerDetails.map((item) => (
                  <>
                    <tr key={item.key}>
                      <th>{COVERAGE_NAMES[item.key]}</th>
                      {overallOffers?.map((offer) => {
                        const isEditMode = offer.id === selectedConfig?.id;

                        const isSelect = item?.options?.render_type === 'select';
                        const isInput = item?.options?.render_type === 'input';

                        return (
                          <td key={offer.id}>
                            {!isEditMode && (
                              <>{item.format ? item.format(offer[item.key]) : offer[item.key]}</>
                            )}

                            {isEditMode && (
                              <>
                                {isSelect && renderSelect({ key: item.key, ...item?.options })}
                                {isInput && renderInput({ key: item.key, ...item?.options })}
                              </>
                            )}
                          </td>
                        );
                      })}
                    </tr>

                    {item?.render &&
                      item.render(offers[0][item.key])?.map((renderItem) => (
                        <tr key={item.key}>
                          <th>{COVERAGE_NAMES[renderItem.key]}</th>
                          {overallOffers?.map((offer) => {
                            const isEditMode = offer.id === selectedConfig?.id;

                            const isSelect = renderItem?.options?.render_type === 'select';
                            const isInput = renderItem?.options?.render_type === 'input';

                            return (
                              <td key={offer.id}>
                                {!isEditMode && (
                                  <>
                                    {renderItem.format
                                      ? renderItem.format(offer[renderItem.key])
                                      : offer[renderItem.key]}
                                  </>
                                )}
                                {isEditMode && (
                                  <>
                                    {isSelect &&
                                      renderSelect({ key: renderItem.key, ...renderItem?.options })}
                                    {isInput &&
                                      renderInput({ key: renderItem.key, ...renderItem?.options })}
                                  </>
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                  </>
                ))}
                <tr>
                  <th>Compensation Amount</th>
                  {overallOffers?.map((offer) => {
                    const isEditMode = offer.id === selectedConfig?.id;

                    return (
                      <td key={offer.id}>
                        {!isEditMode && normalizeCompensationAmount(offer)}
                        {isEditMode && (
                          <BuilderCASelect
                            size="small"
                            label="Compensation Amount"
                            formData={selectedConfig}
                            setFormData={setSelectedConfig}
                          />
                        )}
                      </td>
                    );
                  })}
                </tr>

                <tr className={classes.OfferTableEmptyField}></tr>
                <tr>
                  <th className={classes.CustomFields}>Custom Fields</th>
                  {overallOffers?.map((offer) => {
                    const isEditMode = selectedConfig.id === offer.id;

                    return (
                      <th className={classes.CustomFields} key={offer.id}>
                        {isEditMode ? (
                          <div className={classes.ExtraRowButton}>
                            <Button
                              title="Add Extra Row"
                              type="secondary"
                              onClick={onAddExtraRow}
                            />
                          </div>
                        ) : null}
                      </th>
                    );
                  })}
                </tr>

                {longest_custom_fields?.custom_fields?.map((item, index) => (
                  <tr>
                    <>
                      <th>{currentOffer.custom_fields[index]?.title ?? ''}</th>
                      {overallOffers?.map((offer) => {
                        const isEditMode = offer.id === selectedConfig.id;

                        const isVisibleField = selectedConfig?.custom_fields?.[index];

                        return !isEditMode ? (
                          <th key={offer.id + item.id}>
                            {offer.custom_fields[index]?.value ?? ''}
                          </th>
                        ) : (
                          <th key={offer.id + item.id}>
                            {isVisibleField ? (
                              <div className={issuerClasses.ExtraRow}>
                                <BuilderInput
                                  value={selectedConfig?.custom_fields?.[index]?.value}
                                  onChange={onChangeExtraRow(
                                    selectedConfig?.custom_fields?.[index]?.id,
                                  )}
                                />

                                <i
                                  className={`fa fa-trash-o`}
                                  onClick={onRejectCustomField(
                                    selectedConfig?.custom_fields?.[index]?.id,
                                  )}
                                />
                              </div>
                            ) : null}
                          </th>
                        );
                      })}
                    </>
                  </tr>
                ))}
                <tr className={classes.OfferTableEmptyField} />
                <tr>
                  <th>Rate Tier Type</th>
                  {overallOffers?.map((offer) => {
                    const isEditMode = offer.id === selectedConfig?.id;

                    return (
                      <th key={offer.id}>
                        {!isEditMode ? (
                          normalizeRateTierType(offer.rate_tier_type)
                        ) : (
                          <div className={classes.PlanConfigFormOptions}>
                            <FormControl fullWidth>
                              <InputLabel size="small" id="select-rtt">
                                Rate Tier Type
                              </InputLabel>

                              <Select
                                size="small"
                                label="Rate Tier Type"
                                labelId="select-rtt"
                                onChange={(event) => onChangeSelect(event, 'rate_tier_type')}
                                onClose={() => {
                                  setTimeout(() => {
                                    document.activeElement.blur();
                                  }, 0);
                                }}
                                value={selectedConfig?.rate_tier_type}
                                sx={{ width: '100%', maxWidth: 200, textAlign: 'left' }}
                              >
                                {!['life', 'disability'].includes(coverage) && (
                                  <MenuItem value="3tier">3 Tier</MenuItem>
                                )}
                                {coverage !== 'disability' && (
                                  <MenuItem value="4tier">4 Tier</MenuItem>
                                )}
                                {coverage === 'disability' && (
                                  <MenuItem value="employee">Employee</MenuItem>
                                )}
                                {age_banded_coverages.includes(coverage) && (
                                  <MenuItem value="all_ages">All Ages</MenuItem>
                                )}
                                {age_banded_coverages.includes(coverage) && (
                                  <MenuItem value="banded_5">5 Year Banded</MenuItem>
                                )}
                                {age_banded_coverages.includes(coverage) && (
                                  <MenuItem value="banded_10">10 Year Banded</MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>

                {coverage === 'life' && (
                  <tr>
                    <th></th>
                    {overallOffers?.map((offer) => {
                      const isEditMode = selectedConfig?.id === offer.id;

                      return (
                        <th key={offer.id}>
                          {!isEditMode && (
                            <>{offer?.is_uni_tobacco ? 'Uni-Tobacco' : 'Tobacco / Non-Tobacco'}</>
                          )}

                          {isEditMode && (
                            <div className={classes.PlanConfigContribution}>
                              <ToggleButtonGroup
                                color="primary"
                                value={selectedConfig.is_uni_tobacco}
                                exclusive
                                onChange={(event) => onChangeToggle(event, 'is_uni_tobacco')}
                                aria-label="Platform"
                                size="small"
                              >
                                <ToggleButton value={true}>Uni-Tobacco</ToggleButton>
                                <ToggleButton value={false}>Tobacco/Non-tobacco</ToggleButton>
                              </ToggleButtonGroup>
                            </div>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                )}

                {renderRates()}

                <tr>
                  <th></th>
                  {overallOffers?.map((offer) => {
                    const isEditMode = offer.id === selectedConfig?.id;

                    return (
                      <th key={offer.id}>
                        {offer?.issuer_name === 'broker' ? (
                          <></>
                        ) : (
                          <div className={issuerClasses.ButtonsWrapper}>
                            {!isEditMode && _.isEmpty(selectedConfig) && (
                              <>
                                <Button
                                  title="Edit"
                                  type="primary"
                                  onClick={onClickEditConfig(offer)}
                                />
                                {!isRejectedOffer &&
                                  offer.status !== 'declined' &&
                                  offers.length > 1 && (
                                    <Button
                                      title="Delete"
                                      type="danger"
                                      onClick={onClickDelete(offer)}
                                    />
                                  )}
                                {isRejectedOffer && offer.status !== 'declined' && (
                                  <div className={issuerClasses.DeclineButton}>
                                    <Button
                                      title="Decline"
                                      type="secondary"
                                      onClick={onRejectOffer}
                                    />
                                  </div>
                                )}
                              </>
                            )}
                            {isEditMode && (
                              <>
                                <Button title="Save" type="primary" buttonType="submit" />

                                <Button title="Cancel" type="secondary" onClick={onClickCancel} />
                              </>
                            )}
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>

                <tr>
                  <th></th>
                  {overallOffers?.map((offer) => {
                    const isEditMode = offer?.id === selectedConfig?.id;

                    return (
                      <th key={offer.id}>
                        {offer?.issuer_name === 'broker' ? (
                          <DropZone
                            label="Current Benefits Summary"
                            maxFiles={100}
                            path={`/proposals/${proposalId}/uploads/broker/plan-configs/${planId}/`}
                            isDisabled={true}
                          />
                        ) : (
                          <DropZone
                            label="Proposed Benefits Summary"
                            maxFiles={100}
                            path={`/proposals/${proposalId}/uploads/carrier/plan-configs/${planId}/offers/${offer?.id}/`}
                            isDisabled={!isEditMode}
                          />
                        )}
                      </th>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </div>
  );
});
