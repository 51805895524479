import _ from 'lodash';

const tenYearAges = (rate) => {
  return {
    age_0_17: !_.isEmpty(rate) ? rate['age_0'] : '',
    age_18_29: !_.isEmpty(rate) ? rate['age_18'] : '',
    age_30_39: !_.isEmpty(rate) ? rate['age_30'] : '',
    age_40_49: !_.isEmpty(rate) ? rate['age_40'] : '',
    age_50_59: !_.isEmpty(rate) ? rate['age_50'] : '',
    age_60_65: !_.isEmpty(rate) ? rate['age_60'] : '',
  };
};

const TEN_YEAR_NAMES = {
  age_0_17: 'Age 0-17',
  age_18_29: 'Age 18-29',
  age_30_39: 'Age 30-39',
  age_40_49: 'Age 40-49',
  age_50_59: 'Age 50-59',
  age_60_65: 'Age 60-65+',
};

const normalizeMedicalTenYearAgeRates = (tenYearAgeRates) => {
  let payload = {
    ...tenYearAgeRates,
  };

  for (let i = 0; i <= 17; i++) {
    payload = {
      ...payload,
      [`age_${i}`]: tenYearAgeRates['age_0_17'],
    };
  }
  for (let i = 18; i <= 29; i++) {
    payload = {
      ...payload,
      [`age_${i}`]: tenYearAgeRates['age_18_29'],
    };
  }
  for (let i = 30; i <= 39; i++) {
    payload = {
      ...payload,
      [`age_${i}`]: tenYearAgeRates['age_30_39'],
    };
  }
  for (let i = 40; i <= 49; i++) {
    payload = {
      ...payload,
      [`age_${i}`]: tenYearAgeRates['age_40_49'],
    };
  }
  for (let i = 50; i <= 59; i++) {
    payload = {
      ...payload,
      [`age_${i}`]: tenYearAgeRates['age_50_59'],
    };
  }
  for (let i = 60; i <= 65; i++) {
    payload = {
      ...payload,
      [`age_${i}`]: tenYearAgeRates['age_60_65'],
    };
  }

  delete payload['age_0_17'];
  delete payload['age_18_29'];
  delete payload['age_30_39'];
  delete payload['age_40_49'];
  delete payload['age_50_59'];
  delete payload['age_60_65'];
  delete payload['age_70_79'];
  // TODO ONLY FOR TEST
  delete payload['age_80'];

  return payload;
};

export { tenYearAges, normalizeMedicalTenYearAgeRates, TEN_YEAR_NAMES };
