import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Document, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import _ from 'lodash';

import {
  fullProposalDataSelector,
  getAccountData,
  getBrokerAccountData,
  getRfpQuotes,
  isLoadingFullProposalDataSelector,
} from 'src/store/broker';

import { Button, Loader, LoaderWrapper } from 'src/components';

import { renderMedicalRfpsPage } from './render/renderMedicalRfpsPage';
import { renderDentalPage } from './render/renderDentalPage';
import { renderVisionPage } from './render/renderVisionPage';
import { renderLifePage } from './render/renderLifePage';
import { renderDisabilityPage } from './render/renderDisabilityPage';
import { renderAccidentPage } from './render/renderAccidentPage';
import { renderHospitalPage } from './render/renderHospitalPage';
import { renderCriticalPage } from './render/renderCriticalPage';
import { getAccountProposal, getAccountProposalSelector } from 'src/store/proposals';
import { useSearchParams } from 'react-router-dom';
import { renderPetPage } from './render/renderPetPage';
import { getPetQuotes, petInsuranceDataSelector } from 'src/store/pet';

const PDFDocument = (proposalData, accountData, accountProposalData) => {
  const medicalData = proposalData.medical;
  const dentalData = proposalData.dental;
  const visionData = proposalData.vision;
  const lifeData = proposalData.life;
  const disabilityData = proposalData.disability;
  const accidentData = proposalData.accident;
  const hospitalData = proposalData.hospital;
  const criticalData = proposalData.critical;

  const groupMembers = !_.isEmpty(accountProposalData?.groups)
    ? accountProposalData?.groups[0].members
    : [];

  const petQuotes = !_.isEmpty(proposalData?.pet) ? proposalData?.pet?.groups[0]?.quotes : [];

  return (
    <Document>
      {groupMembers?.map((member) => {
        const filteredMembers = groupMembers?.filter((item) => item.family_id === member.family_id);

        const memberFullname = `${member?.first_name} ${member?.last_name}`;

        return (
          <>
            {member?.relationship === 'employee'
              ? renderMedicalRfpsPage(medicalData, memberFullname, filteredMembers)
              : null}
            {member?.relationship === 'employee'
              ? renderDentalPage(dentalData, memberFullname, filteredMembers)
              : null}
            {member?.relationship === 'employee'
              ? renderVisionPage(visionData, memberFullname, filteredMembers)
              : null}
            {member?.relationship === 'employee'
              ? renderLifePage(lifeData, memberFullname, filteredMembers)
              : null}
            {member?.relationship === 'employee'
              ? renderDisabilityPage(disabilityData, memberFullname, filteredMembers)
              : null}
            {member?.relationship === 'employee'
              ? renderAccidentPage(accidentData, memberFullname, filteredMembers)
              : null}
            {member?.relationship === 'employee'
              ? renderHospitalPage(hospitalData, memberFullname, filteredMembers)
              : null}
            {member?.relationship === 'employee'
              ? renderCriticalPage(criticalData, memberFullname, filteredMembers)
              : null}
            {member?.relationship === 'employee' ? renderPetPage(petQuotes, memberFullname) : null}
          </>
        );
      })}
    </Document>
  );
};

export const ExportMemberWorksheetPDF = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { id: accountId, proposalId } = useParams();

  const type = searchParams.get('type');

  const proposalData = useSelector(fullProposalDataSelector);
  const isLoadingProposalData = useSelector(isLoadingFullProposalDataSelector);
  const accountData = useSelector(getAccountData);
  const accountProposalData = useSelector(getAccountProposalSelector);
  const petData = useSelector(petInsuranceDataSelector);

  useEffect(() => {
    const payload = {
      accountId,
      proposalId,
      type: type ? type : 'all',
    };

    const asyncDispatch = async () => {
      await dispatch(getBrokerAccountData({ paramsId: accountId }));
      await dispatch(getAccountProposal(payload));
      await dispatch(getRfpQuotes(payload));
      await dispatch(getPetQuotes({ accountId, proposalId }));
    };

    asyncDispatch();
  }, [dispatch, accountId, proposalId, type]);

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      {!_.isEmpty(proposalData) && !_.isEmpty(accountData) && !_.isEmpty(accountProposalData) && (
        <div style={{ width: '80%' }}>
          <PDFViewer style={{ width: '100%', height: '99%' }}>
            {PDFDocument({ ...proposalData, pet: petData }, accountData, accountProposalData)}
          </PDFViewer>
        </div>
      )}
      <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {isLoadingProposalData ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          !_.isEmpty(proposalData) && (
            <PDFDownloadLink
              document={PDFDocument(proposalData, accountData, accountProposalData)}
              fileName={`${accountData?.company_name}-${
                accountProposalData?.name
              }-employee worksheet${type === 'sold' ? '(sold)' : ''}.pdf`}
            >
              <Button type="primaryPurple" title="Download Document" />
            </PDFDownloadLink>
          )
        )}
      </div>
    </div>
  );
};
