import moment from 'moment';
import { normalizeRateValue } from './lifeRates';

export const calculateHousehold3TierHousehold = (
  householdFamilies,
  selectedOffer,
  memberPlanName,
) => {
  const selectedPlanName = selectedOffer?.selected_plan_name;
  const isSelective = Boolean(memberPlanName && selectedPlanName);
  const payload = {};

  const computeEmployeeRates = () => {
    const ee_contribution = selectedOffer.contribution_ee;
    const ee_rate = selectedOffer.rate_ee;
    let rate_employee, rate_employer;
    if (selectedOffer.contribution_type === 'percent') {
      rate_employee = normalizeRateValue(ee_rate - (ee_rate / 100) * ee_contribution);
      rate_employer = normalizeRateValue((ee_rate / 100) * ee_contribution);
    } else if (selectedOffer.contribution_type === 'dollar') {
      if (ee_contribution > ee_rate) {
        rate_employee = 0;
        rate_employer = normalizeRateValue(ee_rate);
      } else {
        const employeePay = ee_rate - ee_contribution;
        const employerPay = ee_rate - employeePay;
        rate_employee = normalizeRateValue(employeePay);
        rate_employer = normalizeRateValue(employerPay);
      }
    }
    return { rate_employee, rate_employer, rate_total: normalizeRateValue(ee_rate) };
  };

  const computeDependentRates = (dependentCount, baseRate) => {
    const dep_contribution = selectedOffer.contribution_dep;
    const dep_rate = (baseRate - selectedOffer.rate_ee) / dependentCount;
    let rate_employee, rate_employer;
    if (selectedOffer.contribution_type === 'percent') {
      rate_employee = normalizeRateValue(dep_rate - (dep_rate / 100) * dep_contribution);
      rate_employer = normalizeRateValue((dep_rate / 100) * dep_contribution);
    } else if (selectedOffer.contribution_type === 'dollar') {
      if (dep_contribution > dep_rate) {
        rate_employee = 0;
        rate_employer = normalizeRateValue(dep_rate);
      } else {
        const dep_dollar_rate =
          (baseRate - selectedOffer.rate_ee - dep_contribution) / dependentCount;
        const employeePay = dep_dollar_rate;
        const employerPay = dep_contribution / dependentCount;
        rate_employee = normalizeRateValue(employeePay);
        rate_employer = normalizeRateValue(employerPay);
      }
    }
    return { rate_employee, rate_employer, rate_total: normalizeRateValue(dep_rate) };
  };

  for (const family_id in householdFamilies) {
    const household = householdFamilies[family_id];
    const employee = household?.find((item) => item?.relationship === 'employee');
    const spouse = household?.find(
      (item) => item?.relationship === 'spouse' || item?.relationship === 'life_partner',
    );
    const child = household?.find((item) => item?.relationship === 'child');
    let householdPayload = [];

    // Full Family: employee, spouse, and child
    if (employee && spouse && child) {
      const dependentCount = household.filter((item) => item?.relationship !== 'employee').length;
      for (const member of household) {
        if (isSelective) {
          if (member[memberPlanName] === selectedPlanName) {
            if (member.relationship === 'employee') {
              householdPayload.push({
                ...member,
                type: `FAM (${selectedPlanName})`,
                ...computeEmployeeRates(),
              });
            } else {
              householdPayload.push({
                ...member,
                ...computeDependentRates(dependentCount, selectedOffer.rate_fam),
              });
            }
          } else {
            householdPayload.push({
              ...member,
              rate_employee: 0,
              rate_employer: 0,
              rate_total: 0,
            });
          }
        } else {
          if (member.relationship === 'employee') {
            householdPayload.push({
              ...member,
              type: 'FAM',
              ...computeEmployeeRates(),
            });
          } else {
            householdPayload.push({
              ...member,
              ...computeDependentRates(dependentCount, selectedOffer.rate_fam),
            });
          }
        }
      }
      payload[family_id] = householdPayload;
    }
    // Employee-only households
    else if (employee && !spouse && !child) {
      for (const member of household) {
        if (isSelective) {
          if (member[memberPlanName] === selectedPlanName) {
            if (member.relationship === 'employee') {
              householdPayload.push({
                ...member,
                type: `EE (${selectedPlanName})`,
                ...computeEmployeeRates(),
              });
            } else {
              householdPayload.push(member);
            }
          } else {
            householdPayload.push({
              ...member,
              rate_employee: 0,
              rate_employer: 0,
              rate_total: 0,
            });
          }
        } else {
          if (member.relationship === 'employee') {
            householdPayload.push({
              ...member,
              type: 'EE',
              ...computeEmployeeRates(),
            });
          }
        }
      }
      payload[family_id] = householdPayload;
    }
    // Employee plus one dependent (spouse OR child)
    else if (employee && (spouse || child)) {
      const dependentCount = household.filter((item) => item?.relationship !== 'employee').length;
      for (const member of household) {
        if (isSelective) {
          if (member[memberPlanName] === selectedPlanName) {
            if (member.relationship === 'employee') {
              householdPayload.push({
                ...member,
                type: `EE + 1 (${selectedPlanName})`,
                ...computeEmployeeRates(),
              });
            } else {
              householdPayload.push({
                ...member,
                ...computeDependentRates(dependentCount, selectedOffer.rate_ee_1),
              });
            }
          } else {
            householdPayload.push({
              ...member,
              rate_employee: 0,
              rate_employer: 0,
              rate_total: 0,
            });
          }
        } else {
          if (member.relationship === 'employee') {
            householdPayload.push({
              ...member,
              type: 'EE + 1',
              ...computeEmployeeRates(),
            });
          } else {
            householdPayload.push({
              ...member,
              ...computeDependentRates(dependentCount, selectedOffer.rate_ee_1),
            });
          }
        }
      }
      payload[family_id] = householdPayload;
    }
  }

  return payload;
};

//

export const calculateHousehold4TierHousehold = (
  householdFamilies,
  selectedOffer,
  memberPlanName,
) => {
  const selectedPlanName = selectedOffer?.selected_plan_name;
  const isSelective = Boolean(memberPlanName && selectedPlanName);
  const payload = {};

  const computeEmployeeRates = () => {
    const ee_contribution = selectedOffer.contribution_ee;
    const ee_rate = selectedOffer.rate_ee;
    let rate_employee, rate_employer;
    if (selectedOffer.contribution_type === 'percent') {
      rate_employee = normalizeRateValue(ee_rate - (ee_rate / 100) * ee_contribution);
      rate_employer = normalizeRateValue((ee_rate / 100) * ee_contribution);
    } else if (selectedOffer.contribution_type === 'dollar') {
      if (ee_contribution > ee_rate) {
        rate_employee = 0;
        rate_employer = normalizeRateValue(ee_rate);
      } else {
        const employeePay = ee_rate - ee_contribution;
        const employerPay = ee_rate - employeePay;
        rate_employee = normalizeRateValue(employeePay);
        rate_employer = normalizeRateValue(employerPay);
      }
    }
    return { rate_employee, rate_employer, rate_total: normalizeRateValue(ee_rate) };
  };

  const computeDependentRates = (dependentCount, baseRate) => {
    const dep_contribution = selectedOffer.contribution_dep;
    const dep_rate = (baseRate - selectedOffer.rate_ee) / dependentCount;
    let rate_employee, rate_employer;
    if (selectedOffer.contribution_type === 'percent') {
      rate_employee = normalizeRateValue(dep_rate - (dep_rate / 100) * dep_contribution);
      rate_employer = normalizeRateValue((dep_rate / 100) * dep_contribution);
    } else if (selectedOffer.contribution_type === 'dollar') {
      if (dep_contribution > dep_rate) {
        rate_employee = 0;
        rate_employer = normalizeRateValue(dep_rate);
      } else {
        const dep_dollar_rate =
          (baseRate - selectedOffer.rate_ee - dep_contribution) / dependentCount;
        const employeePay = dep_dollar_rate;
        const employerPay = dep_contribution / dependentCount;
        rate_employee = normalizeRateValue(employeePay);
        rate_employer = normalizeRateValue(employerPay);
      }
    }
    return { rate_employee, rate_employer, rate_total: normalizeRateValue(dep_rate) };
  };

  for (const family_id in householdFamilies) {
    const household = householdFamilies[family_id];
    const employee = household?.find((item) => item?.relationship === 'employee');
    const spouse = household?.find(
      (item) => item?.relationship === 'spouse' || item?.relationship === 'life_partner',
    );
    const child = household?.find((item) => item?.relationship === 'child');
    let householdPayload = [];

    // Full Family: employee, spouse, and child exist.
    if (employee && spouse && child) {
      const dependentCount = household.filter((item) => item?.relationship !== 'employee').length;
      for (const member of household) {
        if (isSelective) {
          if (member[memberPlanName] === selectedPlanName) {
            if (member.relationship === 'employee') {
              householdPayload.push({
                ...member,
                type: `FAM (${selectedPlanName})`,
                ...computeEmployeeRates(),
              });
            } else {
              householdPayload.push({
                ...member,
                ...computeDependentRates(dependentCount, selectedOffer.rate_fam),
              });
            }
          } else {
            householdPayload.push({
              ...member,
              rate_employee: 0,
              rate_employer: 0,
              rate_total: 0,
            });
          }
        } else {
          if (member.relationship === 'employee') {
            householdPayload.push({
              ...member,
              type: 'FAM',
              ...computeEmployeeRates(),
            });
          } else {
            householdPayload.push({
              ...member,
              ...computeDependentRates(dependentCount, selectedOffer.rate_fam),
            });
          }
        }
      }
      payload[family_id] = householdPayload;
    }
    // Employee-only households
    else if (employee && !spouse && !child) {
      for (const member of household) {
        if (isSelective) {
          if (member[memberPlanName] === selectedPlanName) {
            if (member.relationship === 'employee') {
              householdPayload.push({
                ...member,
                type: `EE (${selectedPlanName})`,
                ...computeEmployeeRates(),
              });
            } else {
              householdPayload.push({
                ...member,
                rate_employee: 0,
                rate_employer: 0,
                rate_total: 0,
              });
            }
          } else {
            householdPayload.push({
              ...member,
              rate_employee: 0,
              rate_employer: 0,
              rate_total: 0,
            });
          }
        } else {
          if (member.relationship === 'employee') {
            householdPayload.push({
              ...member,
              type: 'EE',
              ...computeEmployeeRates(),
            });
          }
        }
      }
      payload[family_id] = householdPayload;
    }
    // Employee and Spouse (no child)
    else if (employee && spouse && !child) {
      const dependentCount = household.filter((item) => item?.relationship !== 'employee').length;
      for (const member of household) {
        if (isSelective) {
          if (member[memberPlanName] === selectedPlanName) {
            if (member.relationship === 'employee') {
              householdPayload.push({
                ...member,
                type: `EE + SP (${selectedPlanName})`,
                ...computeEmployeeRates(),
              });
            } else {
              householdPayload.push({
                ...member,
                ...computeDependentRates(dependentCount, selectedOffer.rate_ee_sp),
              });
            }
          } else {
            householdPayload.push({
              ...member,
              rate_employee: 0,
              rate_employer: 0,
              rate_total: 0,
            });
          }
        } else {
          if (member.relationship === 'employee') {
            householdPayload.push({
              ...member,
              type: 'EE + SP',
              ...computeEmployeeRates(),
            });
          } else {
            householdPayload.push({
              ...member,
              ...computeDependentRates(dependentCount, selectedOffer.rate_ee_sp),
            });
          }
        }
      }
      payload[family_id] = householdPayload;
    }
    // Employee and Child (no spouse)
    else if (employee && !spouse && child) {
      const dependentCount = household.filter((item) => item?.relationship !== 'employee').length;
      for (const member of household) {
        if (isSelective) {
          if (member[memberPlanName] === selectedPlanName) {
            if (member.relationship === 'employee') {
              householdPayload.push({
                ...member,
                type: `EE + CH (${selectedPlanName})`,
                ...computeEmployeeRates(),
              });
            } else {
              householdPayload.push({
                ...member,
                ...computeDependentRates(dependentCount, selectedOffer.rate_ee_ch),
              });
            }
          } else {
            householdPayload.push({
              ...member,
              rate_employee: 0,
              rate_employer: 0,
              rate_total: 0,
            });
          }
        } else {
          if (member.relationship === 'employee') {
            householdPayload.push({
              ...member,
              type: 'EE + CH',
              ...computeEmployeeRates(),
            });
          } else {
            householdPayload.push({
              ...member,
              ...computeDependentRates(dependentCount, selectedOffer.rate_ee_ch),
            });
          }
        }
      }
      payload[family_id] = householdPayload;
    }
  }
  return payload;
};

export const calculateMedicalAgeRatesHousehold = (householdFamilies, selectedOffer) => {
  const selectedPlanName = selectedOffer?.selected_plan_name;
  const isSelective = Boolean(selectedPlanName);
  const payload = {};

  const getAgeAndRate = (member) => {
    const originalAge = moment().diff(moment(member.date_of_birth), 'years');
    let ageForRate = originalAge;
    if (ageForRate > 65) ageForRate = 65;
    const baseRate = selectedOffer[`age_${ageForRate}`] ?? 0;
    return { originalAge, baseRate };
  };

  const computeEmployeeRates = (baseRate) => {
    const ee_contribution = selectedOffer.contribution_ee;
    let rate_employee, rate_employer;
    if (selectedOffer.contribution_type === 'percent') {
      rate_employee = normalizeRateValue(baseRate - (baseRate / 100) * ee_contribution);
      rate_employer = normalizeRateValue((baseRate / 100) * ee_contribution);
    } else if (selectedOffer.contribution_type === 'dollar') {
      if (ee_contribution > baseRate) {
        rate_employee = 0;
        rate_employer = normalizeRateValue(baseRate);
      } else {
        const employeePay = baseRate - ee_contribution;
        const employerPay = baseRate - employeePay;
        rate_employee = normalizeRateValue(employeePay);
        rate_employer = normalizeRateValue(employerPay);
      }
    }
    return { rate_employee, rate_employer, rate_total: normalizeRateValue(baseRate) };
  };

  const computeDependentRates = (baseRate) => {
    const dep_contribution = selectedOffer.contribution_dep;
    let rate_employee, rate_employer;
    if (selectedOffer.contribution_type === 'percent') {
      rate_employee = normalizeRateValue(baseRate - (baseRate / 100) * dep_contribution);
      rate_employer = normalizeRateValue((baseRate / 100) * dep_contribution);
    } else if (selectedOffer.contribution_type === 'dollar') {
      if (dep_contribution > baseRate) {
        rate_employee = 0;
        rate_employer = normalizeRateValue(baseRate);
      } else {
        const employeePay = baseRate - dep_contribution;
        const employerPay = baseRate - employeePay;
        rate_employee = normalizeRateValue(employeePay);
        rate_employer = normalizeRateValue(employerPay);
      }
    }
    return { rate_employee, rate_employer, rate_total: normalizeRateValue(baseRate) };
  };

  for (const family_id in householdFamilies) {
    const household = householdFamilies[family_id];
    const employee = household?.find((item) => item?.relationship === 'employee');
    const spouse = household?.find(
      (item) => item?.relationship === 'spouse' || item?.relationship === 'life_partner',
    );
    const child = household?.find((item) => item?.relationship === 'child');
    let householdPayload = [];

    if (employee && spouse && child) {
      // Full family
      for (const member of household) {
        if (isSelective && member['current_medical_plan_name'] !== selectedPlanName) {
          householdPayload.push({ ...member, rate_employee: 0, rate_employer: 0, rate_total: 0 });
          continue;
        }
        const { originalAge, baseRate } = getAgeAndRate(member);
        const typeLabel = isSelective
          ? `${originalAge} Y/O (${selectedPlanName})`
          : `${originalAge} Y/O`;
        if (member.relationship === 'employee') {
          householdPayload.push({
            ...member,
            type: typeLabel,
            ...computeEmployeeRates(baseRate),
          });
        } else {
          householdPayload.push({
            ...member,
            type: typeLabel,
            ...computeDependentRates(baseRate),
          });
        }
      }
      payload[family_id] = householdPayload;
    } else if (employee && !spouse && !child) {
      // Employee only
      for (const member of household) {
        if (isSelective && member['current_medical_plan_name'] !== selectedPlanName) {
          householdPayload.push({ ...member, rate_employee: 0, rate_employer: 0, rate_total: 0 });
          continue;
        }
        if (member.relationship === 'employee') {
          const { originalAge, baseRate } = getAgeAndRate(member);
          const typeLabel = isSelective
            ? `${originalAge} Y/O (${selectedPlanName})`
            : `${originalAge} Y/O`;
          householdPayload.push({
            ...member,
            type: typeLabel,
            ...computeEmployeeRates(baseRate),
          });
        } else {
          householdPayload.push({ ...member, rate_employee: 0, rate_employer: 0, rate_total: 0 });
        }
      }
      payload[family_id] = householdPayload;
    } else if (employee && spouse && !child) {
      // Employee + Spouse
      for (const member of household) {
        if (isSelective && member['current_medical_plan_name'] !== selectedPlanName) {
          householdPayload.push({ ...member, rate_employee: 0, rate_employer: 0, rate_total: 0 });
          continue;
        }
        const { originalAge, baseRate } = getAgeAndRate(member);
        const typeLabel = isSelective
          ? `${originalAge} Y/O (${selectedPlanName})`
          : `${originalAge} Y/O`;
        if (member.relationship === 'employee') {
          householdPayload.push({
            ...member,
            type: typeLabel,
            ...computeEmployeeRates(baseRate),
          });
        } else {
          householdPayload.push({
            ...member,
            type: typeLabel,
            ...computeDependentRates(baseRate),
          });
        }
      }
      payload[family_id] = householdPayload;
    } else if (employee && !spouse && child) {
      // Employee + Child
      for (const member of household) {
        if (isSelective && member['current_medical_plan_name'] !== selectedPlanName) {
          householdPayload.push({ ...member, rate_employee: 0, rate_employer: 0, rate_total: 0 });
          continue;
        }
        const { originalAge, baseRate } = getAgeAndRate(member);
        const typeLabel = isSelective
          ? `${originalAge} Y/O (${selectedPlanName})`
          : `${originalAge} Y/O`;
        if (member.relationship === 'employee') {
          householdPayload.push({
            ...member,
            type: typeLabel,
            ...computeEmployeeRates(baseRate),
          });
        } else {
          householdPayload.push({
            ...member,
            type: typeLabel,
            ...computeDependentRates(baseRate),
          });
        }
      }
      payload[family_id] = householdPayload;
    }
  }

  return payload;
};
