import Base from './base';

class Agency extends Base {
  createBrokerAgency = (payload) => {
    return this.apiClient.post('orgs', payload);
  };

  getBrokerAgency = (id) => {
    return this.apiClient.get(`orgs/${id}`);
  };

  getBrokerAgencies = () => {
    return this.apiClient.get('orgs');
  };

  updateBrokerAgency = (id, payload) => {
    return this.apiClient.put(`orgs/${id}`, payload);
  };

  deleteBrokerAgency = (id) => {
    return this.apiClient.delete(`orgs/${id}`);
  };

  getAgencyMembers = (id) => {
    return this.apiClient.get(`orgs/${id}/members`);
  };

  deleteAgencyMember = (teamId, memberId) => {
    return this.apiClient.delete(`orgs/${teamId}/members/${memberId}`);
  };

  getPendingInvites = (id) => {
    return this.apiClient.get(`orgs/${id}/invites`);
  };

  deletePendingInvite = (id, memberId) => {
    return this.apiClient.delete(`orgs/${id}/invites/${memberId}`);
  };

  getAgencyUserRole = (id) => {
    return this.apiClient.get(`orgs/${id}/role`);
  };

  inviteTeamMember = (id, payload) => {
    return this.apiClient.post(`orgs/${id}/invite`, payload);
  };

  acceptOrganizationInvite = (payload) => {
    return this.apiClient.post('orgs/accept-invite', payload);
  };

  getAgencyAccounts = (payload) => {
    return this.apiClient.get('accounts', payload);
  };

  getAgencyAccountsProposalsList = (id, searchValue = '', org_id) => {
    return this.apiClient.get(
      `accounts/${id}/proposals?limit=20&offset=0&q=${searchValue}&f[org_id]=${org_id}`,
    );
  };

  getAgencyAccountProposals = (id, payload) => {
    return this.apiClient.get(`accounts/${id}/proposals`, payload);
  };

  getAgencyProposalsList = (payload) => {
    return this.apiClient.get('proposals', payload);
  };

  updateUserRole = (teamId, memberId, payload) => {
    return this.apiClient.put(`orgs/${teamId}/members/${memberId}`, payload);
  };

  createCheckoutSession = (payload) => {
    return this.apiClient.post('billing/create-checkout-session', payload);
  };

  createCustomerPortalSession = (payload) => {
    return this.apiClient.post('billing/create-customer-portal-session', payload);
  };

  getAgencyReporting = (id, payload) => {
    return this.apiClient.get(`orgs/${id}/reporting`, payload);
  };

  updateSubscriptionQuantity = (payload) => {
    return this.apiClient.post('billing/update-subscription-quantity', payload);
  };
}

export default Agency;
