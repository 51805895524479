import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    fontSize: 12,
  },
  title: { textAlign: 'center', padding: 10, margin: '5px 10px', border: '1px solid grey' },
  sectionWrapper: {
    flexGrow: 1,
    flexDirection: 'row',
  },
  section: {
    margin: 10,
    flexGrow: 1,
  },
  section2: {
    width: '100%',
    flexGrow: 1,
    margin: 4,
    border: '1px solid grey',
  },
  rowTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  rowValue: {
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'capitalize',
    flex: 1,
    width: '100%',
  },
  rowWrapper: {
    width: '100%',
    flex: '1 1 auto',
    flexDirection: 'row',
    borderBottom: '1px solid grey',
    padding: '2 5',
  },
  divider: {
    padding: 20,
  },

  // tobacco
  tobaccoWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  tobaccoItemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
  },
  tobaccoItem: {
    padding: '5px',
  },

  // table
  table: {
    display: 'table',
    width: '100%',
    flexGrow: '1',
    padding: 10,
  },
  tableRow: {
    width: '100%',
    margin: 'auto',
    flexDirection: 'row',
    flexGrow: '1',
    flex: '1 1 auto',
    border: '1px solid grey',
  },
  tableCol: {
    width: '100%',
    padding: 5,
    borderRight: '1px solid grey',
  },
  lastTableCol: {
    borderRight: 'unset',
  },
  tableTitle: {
    fontSize: 12,
  },
  tableCell: {
    fontSize: 10,
  },
});
