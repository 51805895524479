import Base from './base';

class Admin extends Base {
  getAdminBrokers = (payload) => {
    return this.apiClient.get('brokers', payload);
  };

  getAdminReps = (payload) => {
    return this.apiClient.get('reps', payload);
  };

  createCustomToken = (payload) => {
    return this.apiClient.post('users/custom-token', payload);
  };
}

export default Admin;
