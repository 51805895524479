import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import classes from './inputV2.module.scss';

export const InputV2 = (props) => {
  const { label } = props;

  return (
    <div className={classes.InputV2Wrapper}>
      <div className={classes.InputV2Label}>{label}</div>
      <input {..._.omit(props, ['label'])} className={classes.InputV2} />
    </div>
  );
};

InputV2.propTypes = {
  type: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  minRange: PropTypes.number,
  maxRange: PropTypes.number,
};
